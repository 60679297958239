import React, { createContext, useState } from "react";

import axios from "../hooks/axios";

import { useDispatch, useSelector } from "../store";
import { Logout } from "../store/reducers/auth";
import { expireToken } from "../helpers";
import snackbar from "../config/snackbar";

const APIContext = createContext<any | null>(null);

export const APIProvider = ({ children }: { children: React.ReactElement }) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((store: any) => store.auth);
  const { token, userId, expiry } = state;
  const [loading, setLoading] = useState<boolean>(false);

  const handleLogout = () => {
    const data: any = { status: false };
    dispatch(Logout(data));
    window.location.href = "/sign-in";
    // navigate("/sign-in");
    //snackbar("Token was Expired", "error");
  };

  const register = async (registerData: any) => {
    const data = await axios.post("/issuance/api/users/create", registerData);
    return data;
  };

  const login = async (loginData: any) => {
    const data = await axios.post(
      "/issuance/api_third_party/auth/login",
      loginData
    );
    return data;
  };

  const getVerify = async (userId: string) => {
    if (expireToken(expiry)) {
      handleLogout();
      return;
    }
    const data = await axios.get(`/user/isVerified/${userId}`);
    return data;
  };
  const uploadPhoto = async (user_id: number) => {
    if (expireToken(expiry)) {
      handleLogout();
      return;
    }
    const data = await axios.post(`/user/upload/${user_id}`);
    return data;
  };

  const getAllAssets = async () => {
    if (expireToken(expiry)) {
      handleLogout();
      return;
    }
    const data = await axios.get("/assets/all");
    return data;
  };
  const sendEmailVerification = async (email: string) => {
    try {
      const { data } = await axios.get(`/email/send/verify/${email}`);
      // snackbar(data.message);
    } catch (error) {
      // snackbar("Error sending email verification", "error");
      return;
    }
  };

  const createInvestor = async (investorData: any) => {
    if (expireToken(expiry)) {
      handleLogout();
      return;
    }
    const { data } = await axios.post(
      `/issuance/api_third_party/investors/ind?token=${token}`,
      investorData
    );
    return data;
  };

  const createInvestment = async (investmentData: any) => {
    if (expireToken(expiry)) {
      handleLogout();
      return;
    }
    const data = await axios.post(
      `/issuance/api_third_party/investments/create?token=${token}`,
      investmentData
    );
    return data;
  };

  const getImage = async () => {
    if (expireToken(expiry)) {
      handleLogout();
      return;
    }

    const data = await axios.get(`/user/image/${userId}`);
    return data;
  };

  const uploadImage = async (param: any) => {
    if (expireToken(expiry)) {
      handleLogout();
      return;
    }

    const data = await axios.post(`/user/upload/${userId}`, param);
    return data;
  };

  const getProfileAssets = async () => {
    if (expireToken(expiry)) {
      handleLogout();
      return;
    }
    const data = await axios.get(
      `/issuance/api_third_party/investors?token=${token}`
    );
    return data;
  };

  const getAllInvestments = async () => {
    if (expireToken(expiry)) {
      handleLogout();
      return;
    }
    const data = await axios.get(
      `/issuance/api_third_party/investments/all?token=${token}`
    );
    return data;
  };

  const getInvestor = async (id: number) => {
    if (expireToken(expiry)) {
      handleLogout();
      return;
    }
    const data = await axios.get(`/user/${id}`);
    return data;
  };

  const updateInvestor = async (param: any) => {
    if (expireToken(expiry)) {
      handleLogout();
      return;
    }
    const data = await axios.patch(
      `/issuance/api_third_party/investors/updateIndInvestor`,
      param
    );
    return data;
  };

  const sendResetMail = async (mail: string) => {
    const data = await axios.get(`/email/password/${mail}`);
    return data;
  };

  const resetPassword = async (param: any) => {
    const data = await axios.get(
      `/email/reset/password?token=${param.token}&password=${param.password}`
    );
    return data;
  };

  const updatePwd = async (param: any) => {
    if (expireToken(expiry)) {
      handleLogout();
      return;
    }
    const data = await axios.put(`/issuance/user/password`, param);
    return data;
  };

  const logout = () => {
    dispatch(Logout({} as any));
  };

  return (
    <APIContext.Provider
      value={{
        register,
        logout,
        getAllAssets,
        login,
        setLoading,
        loading,
        createInvestor,
        getVerify,
        uploadPhoto,
        createInvestment,
        sendEmailVerification,
        getProfileAssets,
        getAllInvestments,
        updateInvestor,
        getInvestor,
        updatePwd,
        getImage,
        uploadImage,
        sendResetMail,
        resetPassword,
      }}
    >
      {children}
    </APIContext.Provider>
  );
};

export default APIContext;
