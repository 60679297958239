import { Box, Container, Typography } from "@mui/material";
import hero from "../assets/img/privacypolicy_hero.png";
import { useEffect } from "react";

function Disclosure() {
    useEffect(() => {
     window.scrollTo({ top : 0 , behavior : 'smooth'})
    },[])
  return (
    <Box>
    {/*------------------ Hero section -------------------------*/}
    <Box
       pt={{ xs: '41px' , md: "145px" }}
       pb={{ xs: '71px' , md: "145px" }}
      sx={{
        backgroundImage: `url(${hero})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        borderBottomLeftRadius: "32px",
        borderBottomRightRadius: "32px",
        marginTop: {xs : "65px" , sm: '100px'},
      }}
    >
      <Typography
        sx={{
          fontSize: "12px",
          color: "#4F575E",
          fontWeight: 600,
          fontFamily: "Poppins, sans-serif",
          textAlign: "center",
          lineHeight: "15px",
          backgroundColor: "#fff",
          borderRadius: "36px",
          padding: "4px 12px",
          maxWidth: "180px",
          margin: {xs: "0px auto 21px" , md: "0px auto 36px"},
        }}
      >
        DISCLOSURE DETAILS
      </Typography>
      <Typography
        sx={{
          fontSize: {xs: "24px" , md: '48px'},
          color: "#fff",
          fontWeight: 600,
          fontFamily: "Poppins, sans-serif",
          textAlign: "center",
          lineHeight: {xs: '30px', md: "56px"},
        }}
      >
        Disclosure
      </Typography>
      <Typography
        sx={{
          fontSize:{xs: '12px' , md: "16px"},
          color: "#CBCFD2",
          fontWeight: 400,
          fontFamily: "Poppins, sans-serif",
          textAlign: "center",
          lineHeight: {xs: "18px" , md: '24px'},
          paddingTop: {xs: "14px" , md: '24px'},
          maxWidth: { xs : '320px', md:"580px"},
          margin: "auto",
        }}
      >
        At McQueen Labs, we are committed to protecting the privacy and security of your personal information.
      </Typography>
    </Box>
    <Container maxWidth="xl">
    <Typography
          sx={{
            fontSize: 12,
            color: "#F7F9FD",
            fontWeight: 600,
            lineHeight: "15px",
            fontFamily: "Poppins, sans-serif",
            backgroundColor: "#33383D",
            borderRadius: "36px",
            padding: "4px 0px",
            maxWidth: '140px !important',
            marginBottom: {xs: "20px" , md: '36px'},
            marginTop: "36px",
            // letterSpacing: "1px",
            textAlign: "center",
          }}
        >
          DISCLOSURE
        </Typography>
        <Box pb={{xs: "20px" , md: '48px'}} pt={"1px"}>
          <Typography
            sx={{
              fontSize: {xs: 14 , md: 21},
              color: "#242424",
              fontWeight: 400,
              lineHeight: {xs: '18px', md: "38px"},
              fontFamily: "Poppins, sans-serif",
            }}
          >
            McQueen Labs ("McQueen Labs," "we," "us," or "our") uses cookies and similar technologies on our SEC approved illiquid assets investing platform for cars and artwork ("Platform"). This Cookie Policy explains how we use cookies, the types of cookies we use, and your choices regarding cookies.
          </Typography>
          </Box>
          <Box pb={"48px"} pt={"1px"}>

          <Typography
            sx={{
              fontSize: {xs: 12 , md: 18},
              color: "#464646",
              fontWeight: 400,
              lineHeight: {xs: '18px' , md: '38px'},
              fontFamily: "Poppins, sans-serif",
            }}
          >
            1. What are Cookies: Cookies are small text files that are stored on your device (computer, smartphone, tablet) when you visit a website or use an online service. Cookies contain information that is transferred to your device and are used to recognize your device, track your preferences, and improve your online experience.
          </Typography>
          <Typography
            sx={{
              fontSize: {xs: 12 , md: 18},
              color: "#464646",
              fontWeight: 400,
              lineHeight: {xs: '18px' , md: '38px'},
              fontFamily: "Poppins, sans-serif",
            }}
          >
            2. How We Use Cookies: (A) Essential Cookies: These cookies are necessary for the operation of our Platform. They enable core functions such as user authentication, account management, and security. (B) Performance Cookies: These cookies collect information about how you use our Platform, such as which pages you visit, the features you use, and any errors you encounter. We use this information to analyze usage patterns, optimize our Platform, and improve performance. (C) Functionality Cookies: These cookies allow our Platform to remember choices you make (such as language or region) and provide enhanced features and personalized content based on your preferences. (D) Targeting Cookies: These cookies are used to deliver advertisements that are relevant to your interests. They may be used by third-party advertising networks to track your browsing activity across different websites and tailor advertising to your interests.
          </Typography>
          <Typography
            sx={{
              fontSize: {xs: 12 , md: 18},
              color: "#464646",
              fontWeight: 400,
              lineHeight: {xs: '18px' , md: '38px'},
              fontFamily: "Poppins, sans-serif",
            }}
          >
            3. Your Choices: (A) Cookie Settings: You can control and manage cookies through your browser settings. Most web browsers allow you to block or delete cookies, as well as set preferences for accepting or rejecting cookies. (B) Opt-Out of Targeting Cookies: You can opt-out of targeting cookies by adjusting your advertising preferences through the settings provided by third-party advertising networks or by visiting the Network Advertising Initiative's opt-out page (http://optout.networkadvertising.org/).
          </Typography>
          <Typography
            sx={{
              fontSize: {xs: 12 , md: 18},
              color: "#464646",
              fontWeight: 400,
              lineHeight: {xs: '18px' , md: '38px'},
              fontFamily: "Poppins, sans-serif",
            }}
          >
            4. Third-Party Cookies: Some cookies on our Platform may be placed by third-party service providers, such as analytics providers and advertising networks. These third parties may use cookies to collect information about your online activities across different websites and services.
          </Typography>
          
        </Box>
        </Container>
    </Box>
  );
}

export default Disclosure;