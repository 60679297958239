import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from "react-router-dom";
import LexusLFA from "../assets/img/lexus-lfa.svg";
import Lexus from "../assets/img/car-new.png";
const DetailsComponent = () => {
  const navigate = useNavigate();
  return (
    <Box className="verify" py={10}>
      <Container maxWidth="xl">
        <Grid container spacing={5}>
          <Grid item md={7} sm={12} xs={12}>
            <Stack spacing={2}>
              <Box component="img" sx={{ width: "100%" }} src={Lexus}></Box>
            </Stack>
          </Grid>

          <Grid item md={5} sm={12} xs={12}>
            <Stack sx={{ height: "100%" }}>
              <Stack p={3} sx={{ backgroundColor: "#232323" }} spacing={1.5}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "20px 0",
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{ color: "white", fontWeight: "bold" }}
                  >
                    Lexus LFA
                  </Typography>
                  <Box
                    sx={{
                      marginLeft: "auto",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={LexusLFA}
                      alt="Logo"
                      style={{ width: 70, height: 70 }}
                    />
                  </Box>
                </Box>

                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{ color: "#29FFF5" }}>
                    Initial Offering Price
                  </Typography>
                  <Typography sx={{ color: "white", fontWeight: 600 }}>
                    $300,000 USD
                  </Typography>
                </Stack>
                <Divider sx={{ borderColor: "#3d3e3e" }} />

                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{ color: "#29FFF5" }}>Share Price</Typography>
                  <Typography sx={{ color: "white" }}>$100.00 USD</Typography>
                </Stack>

                <Divider sx={{ borderColor: "#3d3e3e" }} />

                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{ color: "#29FFF5" }}>
                    Minimum investment
                  </Typography>
                  <Typography sx={{ color: "white", fontWeight: 600 }}>
                    $200.00 USD
                  </Typography>
                </Stack>
                <Stack pt={5}>
                  <Button
                    fullWidth
                    size="large"
                    sx={{
                      background: "#29FFF5",
                      color: "black",
                      fontWeight: 600,
                    }}
                    variant="contained"
                    onClick={() => navigate("/invest")}
                  >
                    Buy Shares
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
      
          <Grid item md={7} sm={12} xs={12}>
            <Stack spacing={2}>
              <Typography
                sx={{ fontWeight: 600, color: "#F765B8" }}
                variant="h4"
              >
                Asset Details
              </Typography>
              <Divider sx={{ borderColor: "#3d3e3e" }} />

              <Typography sx={{ color: "white" }}>
                The Lexus LFA, a masterpiece of automotive engineering and
                design, stands as a pinnacle in the world of collector cars.
                This iconic supercar, limited to just 500 units worldwide, is
                not just a testament to Lexus' commitment to excellence, but
                also a symbol of the rarefied world of luxury automobiles.
                <br />
                <br />
                Crafted with meticulous attention to detail, the LFA was
                introduced in 2010 after nearly a decade of development. Its
                4.8-liter V10 engine, a result of Lexus' collaboration with
                Yamaha, produces an astonishing 552 horsepower, propelling the
                car from 0 to 60 mph in just 3.6 seconds. The engine's unique
                sound, often compared to a Formula 1 car, is a symphony to the
                ears of automotive enthusiasts.
                <br />
                <br />
                But what sets the LFA apart is not just its performance, but its
                construction. The car's frame is made entirely of carbon
                fiber-reinforced polymer (CFRP), making it incredibly
                lightweight yet exceptionally strong. This was a pioneering use
                of CFRP in the automotive industry, showcasing Lexus'
                innovation. <br />
                <br />
                The LFA's rarity is another factor that makes it a prized
                possession for collectors. With only 500 ever made, each LFA is
                virtually unique, especially considering the level of
                customization offered to each original buyer. Colors, interiors,
                and even the type of leather were tailored to individual tastes,
                making every LFA a personal statement of its owner. <br />
                <br />
                Furthermore, the LFA's value has appreciated over time.
                Originally sold at around $375,000, its value has increased
                significantly, with some models being sold for well over the
                original price. This appreciation is a testament to the LFA's
                desirability and its status as a modern classic.
              <br/>  <br/>  
              </Typography>
            </Stack>
            
            <Stack spacing={2}> 
           
                <Accordion sx={{ background: "#232323", color: "white" }}>
                  <AccordionSummary
                    expandIcon={<AddIcon sx={{ color: "white" }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>More details</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Detailed content goes here.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion sx={{ background: "#232323", color: "white" }}>
                  <AccordionSummary
                    expandIcon={<AddIcon sx={{ color: "white" }} />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography>User Agreements</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      Agreement content goes here.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Stack>
          </Grid>

          <Grid item md={5} sm={12} xs={12}>
            <Stack spacing={2}>
              <Typography
                sx={{ fontWeight: 600, color: "#F765B8" }}
                variant="h4"
              >
                &nbsp;
              </Typography>
              <Divider sx={{ borderColor: "#3d3e3e" }} />
              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ color: "#29FFF5" }}>Description</Typography>
                <Stack spacing={2}>
                  <Typography sx={{ color: "white" }}>
                    <strong>Year</strong>: 2012
                  </Typography>
                  <Typography sx={{ color: "white" }}>
                    <strong>Mileage</strong> : 9,921mi
                  </Typography>
                  <Typography sx={{ color: "white" }}>
                    <strong>VIN</strong> : ZA9DU01B80LA12508
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default DetailsComponent;
