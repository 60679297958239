import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";


const VerifyEmailComponent = () => {
  const [openPopup, setOpenPopup] = useState(false);

  const handleContinueClick = () => {
    // Assuming the email verification logic happens here.
    // For the sake of this example, I'm just toggling the dialog.
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  return (
    <Box
      className="verify-email"
      sx={{
        height: "65vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 2,
      }}
    >
      <Container maxWidth="sm">
        <Stack spacing={3}>
          <Typography variant="h3" sx={{ fontWeight: 600, color: "white" }}>
            Verify Email
          </Typography>
          <Typography variant="h6" sx={{ color: "rgba(41, 255, 245, 1)" }}>
            Please enter your email
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              <Stack spacing={0.5}>
                <Typography variant="caption">email</Typography>
                <TextField fullWidth variant="outlined" />
                
              </Stack>
            </Grid>
          </Grid>
          <Stack spacing={3}>
            <Button
              fullWidth
              onClick={handleContinueClick}
              sx={{
                background: "rgba(41, 255, 245, 1)",
                color: "rgba(11, 19, 37, 1)",
                fontWeight: 600,
                borderRadius: 0,
                height: 50,
              }}
              variant="contained"
            >
              Verify Email
            </Button>
          </Stack>
        </Stack>
      </Container>
      <Dialog open={openPopup} onClose={handleClosePopup} maxWidth="xs">
        <DialogTitle sx={{ textAlign: "center" }}>Verification Successful</DialogTitle>
        <DialogContent sx={{ textAlign: "center" }}>
          Your email has been successfully verified.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopup} fullWidth sx={{ justifyContent: "center" }}>Continue</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default VerifyEmailComponent;
