import { Box } from "@mui/material";
import OrderComponent from "../components/OrderComponent";

const Order = () => {
  return (
    <Box>
      <OrderComponent />
    </Box>
  );
};

export default Order;
