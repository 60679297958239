import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import imaageN from "../../../assets/img/Home/ImaageN.svg";

const CarCard = () => {
  return (
    <Card
      sx={{
        padding: "9px",
        borderRadius: "20px",
        backgroundColor: "#fff",
      }}
    >
      <CardActionArea>
        <CardMedia component="img" image={imaageN} alt="car" />
        <CardContent sx={{ padding: "0px" }}>
          <Typography
            gutterBottom
            component="div"
            sx={{
              fontSize: 10,
              color: "#6B767F",
              fontWeight: 400,
              lineHeight: "9px",
              fontFamily: "Poppins, sans-serif",
              paddingTop: "12px",
            }}
          >
            Automotive | Collectable Car | Super Car
          </Typography>
          <Typography
            sx={{
              fontSize: 16,
              color: "#242424",
              fontWeight: 600,
              lineHeight: "16px",
              fontFamily: "Poppins, sans-serif",
              paddingTop: "8px",
              paddingBottom: "12px",
              borderBottom: "1px solid #CBCFD2",
            }}
          >
            Mandolin and Guitar by Pablo Picasso
          </Typography>
          <Box
  sx={{
    backgroundColor: "#fff", 
    borderRadius: "9px",
    padding: "17px 9px 9px",
    marginTop: "12px",
    border: "1px solid #000"
  }}
>
  <Typography
    sx={{
      fontSize: 14,
      color: "#000",
      fontWeight: 600,
      lineHeight: "14px",
      fontFamily: "Poppins, sans-serif",
    }}
  >
    $5.68
  </Typography>
  <Typography
    sx={{
      fontSize: 10,
      color: "#000", 
      fontWeight: 600,
      fontFamily: "Inter, sans-serif",
    }}
  >
    Price Per Share
  </Typography>
</Box>
          <Grid container alignItems="start" columnSpacing={"5px"} pt={"6px"}>
            <Grid item xs={6}>
              <div
                style={{
                  border: "1px solid #242424",
                  borderRadius: "7px",
                  padding: "12px 9px 7px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "#242424",
                    fontWeight: 600,
                    lineHeight: "14px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  $300,000{" "}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 10,
                    color: "#242424",
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  }}
                >
                  Initial Price Offering
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div
                style={{
                  border: "1px solid #242424",
                  borderRadius: "7px",
                  padding: "12px 9px 7px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "#242424",
                    fontWeight: 600,
                    lineHeight: "14px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Oil with sand{" "}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 10,
                    color: "#242424",
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  }}
                >
                  Materials Used
                </Typography>
              </div>
            </Grid>
            <Grid item xs={3} pt={"6px"}>
              <div
                style={{
                  border: "1px solid #242424",
                  borderRadius: "7px",
                  padding: "12px 9px 7px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "#242424",
                    fontWeight: 600,
                    lineHeight: "14px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  1924
                </Typography>
                <Typography
                  sx={{
                    fontSize: 7,
                    color: "#242424",
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  }}
                >
                  Created
                </Typography>
              </div>
            </Grid>
            <Grid item xs={3} pt={"6px"}>
              <div
                style={{
                  border: "1px solid #242424",
                  borderRadius: "7px",
                  padding: "12px 9px 7px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "#242424",
                    fontWeight: 600,
                    lineHeight: "14px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  140 x 200cm
                </Typography>
                <Typography
                  sx={{
                    fontSize: 7,
                    color: "#242424",
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  }}
                >
                  Size
                </Typography>
              </div>
            </Grid>
            <Grid item xs={3} pt={"6px"}>
              <div
                style={{
                  border: "1px solid #242424",
                  borderRadius: "7px",
                  padding: "12px 9px 7px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "#242424",
                    fontWeight: 600,
                    lineHeight: "14px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Canvas
                </Typography>
                <Typography
                  sx={{
                    fontSize: 7,
                    color: "#242424",
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  }}
                >
                  Medium
                </Typography>
              </div>
            </Grid>
            <Grid item xs={3} pt={"6px"}>
              <div
                style={{
                  border: "1px solid #242424",
                  borderRadius: "7px",
                  padding: "12px 9px 7px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "#242424",
                    fontWeight: 600,
                    lineHeight: "14px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  1 of 1
                </Typography>
                <Typography
                  sx={{
                    fontSize: 7,
                    color: "#242424",
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  }}
                >
                  Worldwide Rarity
                </Typography>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="start"
            columnSpacing={"8px"}
            pt={"20px"}
            pb={"8px"}
          >
            <Grid item xs={6}>
              <Button
                style={{
                  backgroundColor: "#A7AEB4",
                  borderRadius: "6px",
                  width: "100%",
                  padding: "10px 0px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "#fff",
                    fontWeight: 400,
                    lineHeight: "14px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Coming Soon
                </Typography>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                style={{
                  border: "1px solid #242424",
                  borderRadius: "6px",
                  width: "100%",
                  padding: "9px 0px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "#242424",
                    fontWeight: 400,
                    lineHeight: "14px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Learn More
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CarCard;
