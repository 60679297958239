import { useNavigate } from "react-router-dom";
import { Box, Container, Grid, Typography } from "@mui/material";

import LexusImg from "../../assets/img/Home/process_img.png";

const ProcessComponent = () => {
  const navigate = useNavigate();

  return (
    <Box pt={{xs: '0px'  , md: '50px'}} pb={{xs: '56px'  , md: '100px'}}>
      <Container maxWidth="xl">
        <Grid container alignItems="start" pt={5}>
          <Grid item xs={12} lg={6}>
            <Box component={'img'} src={LexusImg} sx={{ width : {xs: '100%' , lg: 'auto' }}} alt="img"></Box>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ marginTop : {xs: '50px' , md: '0px'}}}>
            <Typography
              sx={{
                fontSize: 12,
                color: "#F7F9FD",
                fontWeight: 600,
                lineHeight: "15px",
                fontFamily: "Poppins, sans-serif",
                backgroundColor: "#33383D",
                borderRadius: "36px",
                padding: "4px 0px",
                maxWidth: 146,
                marginBottom: { xs: "20px" , md: '36px'},
                // letterSpacing: "1px",
                textAlign: "center",
              }}
            >
              FAST, SIMPLE, EASY
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: 24 , md:30},
                color: "#242424",
                fontWeight: 600,
                lineHeight: { xs: "30px" , md:"38px"} ,
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Unlock Luxury with Ease: A seamless four step process to
              fractionalized asset investments for the masses
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: 14 , md: 16},
                color: "#4F575E",
                fontWeight: 400,
                lineHeight: { xs: '18px' , md: '24px'},
                fontFamily: "Poppins, sans-serif",
                marginTop: { xs: '12px !important' , md : "24px !important"},
                marginBottom: 0,
              }}
            >
              Embark on a journey of luxury accessibility with our streamlined
              four-step process to Fractionalized Asset Investing. From
              selecting coveted assets to seamless transactions, we empower you
              to effortlessly participate in the world of high-end investments.
              Join us in democratizing luxury ownership, one step at a time.
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="start" columnSpacing={{ xs: '22px' , md: '26px'}} pt={{ xs: '23px' , md: '67px'}}>
          <Grid item xs={6} lg={3}>
            <Typography
              sx={{
                fontSize: {xs:  14 , md: 20},
                color: "#fff",
                fontWeight: 600,
                lineHeight: "30px",
                fontFamily: "Poppins, sans-serif",
                backgroundColor : '#242424',
                width : {xs:  33 , md: 48},
                height : {xs:  33 , md: 48},
                borderRadius : '50%',
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'center',
                marginBottom : {xs: '20px' , md: '24px'}
              }}
            >
              1
            </Typography>
            <Typography
              sx={{
                fontSize: {xs:  15 , md: 20},
                color: "#242424",
                fontWeight: "700",
                lineHeight: {xs:  "25px" , md: "30px"},
                fontFamily: "Poppins, sans-serif",
                paddingBottom: "8px",
              }}
            >
              Create an Account
            </Typography>
            <Typography
              sx={{
                fontSize: {xs:  12 , md: 14},
                color: "#6B767F",
                fontWeight: 400,
                lineHeight: {xs: "16px" , md: '20px'} ,
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Create your account to get access and browse the McQueen Lifestyle
              investment opportunities and offerings.
            </Typography>
          </Grid>
          <Grid item xs={6} lg={3}>
          <Typography
              sx={{
                fontSize: {xs:  14 , md: 20},
                color: "#fff",
                fontWeight: 600,
                lineHeight: "30px",
                fontFamily: "Poppins, sans-serif",
                backgroundColor : '#242424',
                width : {xs:  33 , md: 48},
                height : {xs:  33 , md: 48},
                borderRadius : '50%',
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'center',
                marginBottom : {xs: '20px' , md: '24px'}
              }}
            >
              2
            </Typography>
            <Typography
              sx={{
                fontSize: {xs:  15 , md: 20},
                color: "#242424",
                fontWeight: "700",
                lineHeight: {xs:  "25px" , md: "30px"},
                fontFamily: "Poppins, sans-serif",
                paddingBottom: "8px",
              }}
            >
              Browse Marketplace
            </Typography>
            <Typography
               sx={{
                fontSize: {xs:  12 , md: 14},
                color: "#6B767F",
                fontWeight: 400,
                lineHeight: {xs: "16px" , md: '20px'} ,
                fontFamily: "Poppins, sans-serif",
              }}
            >
              After signup you’ll have access to all assets available on our
              platform. Buy, Build, and Creating your furture.
            </Typography>
          </Grid>
          <Grid item xs={6} lg={3} sx={{ marginTop : {xs: '32px' , lg: '0px'}}}>
          <Typography
              sx={{
                fontSize: {xs:  14 , md: 20},
                color: "#fff",
                fontWeight: 600,
                lineHeight: "30px",
                fontFamily: "Poppins, sans-serif",
                backgroundColor : '#242424',
                width : {xs:  33 , md: 48},
                height : {xs:  33 , md: 48},
                borderRadius : '50%',
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'center',
                marginBottom : {xs: '20px' , md: '24px'}
              }}
            >
              3
            </Typography>
            <Typography
              sx={{
                fontSize: {xs:  15 , md: 20},
                color: "#242424",
                fontWeight: "700",
                lineHeight: {xs:  "25px" , md: "30px"},
                fontFamily: "Poppins, sans-serif",
                paddingBottom: "8px",
              }}
            >
              Buy Exclusive Assets
            </Typography>
            <Typography
              sx={{
                fontSize: {xs:  12 , md: 14},
                color: "#6B767F",
                fontWeight: 400,
                lineHeight: {xs: "16px" , md: '20px'} ,
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Each share is initially valued at $20 and the minimum investment
              is $500 (25 shares) per asset
            </Typography>
          </Grid>
          <Grid item xs={6} lg={3} sx={{ marginTop : {xs: '32px' , lg: '0px'}}}>
          <Typography
              sx={{
                fontSize: {xs:  14 , md: 20},
                color: "#fff",
                fontWeight: 600,
                lineHeight: "30px",
                fontFamily: "Poppins, sans-serif",
                backgroundColor : '#242424',
                width : {xs:  33 , md: 48},
                height : {xs:  33 , md: 48},
                borderRadius : '50%',
                display : 'flex',
                alignItems : 'center',
                justifyContent : 'center',
                marginBottom : {xs: '20px' , md: '24px'}
              }}
            >
              4
            </Typography>
            <Typography
              sx={{
                fontSize: {xs:  15 , md: 20},
                color: "#242424",
                fontWeight: "700",
                lineHeight: {xs:  "25px" , md: "30px"},
                fontFamily: "Poppins, sans-serif",
                paddingBottom: "8px",
              }}
            >
              Liquidate Investment
            </Typography>
            <Typography
              sx={{
                fontSize: {xs:  12 , md: 14},
                color: "#6B767F",
                fontWeight: 400,
                lineHeight: {xs: "16px" , md: '20px'} ,
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Upon reaching their investment term, assets will be sold either
              through auction or private transaction.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ProcessComponent;
