import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  token: "",
  userId: "",
  investorId: "",
  username: "",
  isVerified: false,
  status: false,
  isLoggedIn: false,
  expiry: "",
};

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    Login(state, action) {
      const { user, status, session } = action.payload!;
      state.user = user;
      state.token = session.token;
      state.userId = session.user_id;
      state.username = session.username;
      state.isVerified = session.investor_id ? true : false;
      state.investorId = session.investor_id;
      state.expiry = session.expiry;
      state.status = status;
      state.isLoggedIn = status;
    },

    UpdateInfo(state, action) {
      const { user, status } = action.payload!;
      state.user = user;
      state.status = status;
      state.isLoggedIn = status;
    },

    UpdateInvester(state, action) {
      const { investor_id } = action.payload!;
      state.investorId = investor_id;
      state.isVerified = investor_id ? true : false;
    },

    UpdateUserInfo(state, action) {
      const { user } = action.payload!;
      state.user = user;
    },

    UpdateUserName(state, action) {
      const { username } = action.payload!;
      state.username = username;
    },

    Logout(state) {
      state.user = {};
      state.status = false;
      state.isLoggedIn = false;
    },
  },
});

export default auth.reducer;

export const {
  Login,
  Logout,
  UpdateInfo,
  UpdateInvester,
  UpdateUserInfo,
  UpdateUserName,
} = auth.actions;
