import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import ResetPasswordComponent from "../components/ResetPasswordComponent";
import SignInImg from "../assets/img/sign-in.png";
import { useParams } from "react-router-dom";
import ResetComponent from "../components/ResetComponent";
import { useState } from "react";
import passwordimg from '../assets/img/passwordright.png'
const ResetPassword = () => {
  const { id } = useParams();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        marginTop: { xs: "28px", sm: "70px", md: "190px" },
        marginBottom: { xs: "28px", sm: "70px", md: "102px" },
      }}
    >
      <Container maxWidth="xl">
        <Grid container>
          <Grid
            item
            md={6}
            xs={12}
            sm={12}
            pt={"63px"}
            pb={{ md: "63px" }}
            alignSelf={"center"}
          >
            {id === "step1" && <ResetComponent />}
            {id === "step2" && (
              <ResetPasswordComponent handleClickOpen={handleClickOpen} />
            )}
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sm={12}
            display={"flex"}
            py={"63px"}
            sx={{
              borderLeft: { md: "1px solid #CBCFD2" },
              display: { xs: "none", md: "flex" },
            }}
          >
            <Box
              component="img"
              src={SignInImg}
              sx={{ margin: "auto" }}
              alt="img"
            ></Box>
          </Grid>
        </Grid>
      </Container>

      {/* password reset success modal */}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"md"}
        sx={{
          "& .MuiPaper-root": {
            background: "#fff !important",
            borderRadius: "12px !important",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
      >
        <DialogContent sx={{ textAlign: "center" }}>
          <img src={passwordimg} alt="img"></img>
          <DialogContentText
            sx={{
              paddingTop: "12px",
              paddingBottom: "8px",
              color: "#14121a",
              fontSize: 18,
              fontWeight: 700,
              lineHeight: "32px",
              fontFamily: "Inter, sans-serif",
            }}
            id="alert-dialog-description"
          >
            Password Reset Success{" "}
          </DialogContentText>
          <DialogContentText
            sx={{
              color: "#14121a",
              fontSize: 12,
              fontWeight: 600,
              fontFamily: "Inter, sans-serif",
            }}
            id="alert-dialog-description"
          >
            Your password has successfully been reset. 
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ResetPassword;
