import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import CarImg from "../assets/img/Lexus.svg";
import CheckIcon from "../assets/img/icons/check.svg";
import ArrowLeftIcon from "../assets/img/icons/arrow-left.svg";
import ArrowRightIcon from "../assets/img/icons/arrow-right.svg";

const OrderComponent = () => {
  return (
    <Box className="verify">
      <Container>
        <Stack pt={5}>
          <Typography sx={{ fontWeight: 600, color: "white" }} variant="h4">
            Order summary
          </Typography>
          <Typography sx={{ color: "white" }}>
            Your order details are listed below.
          </Typography>
        </Stack>

        <Box p={3} sx={{ border: "1px solid white" }} my={5}>
          <Stack direction="row" spacing={5}>
            <Box component="img" src={CarImg} sx={{ height: "200px" }}></Box>
            <Stack sx={{ width: "100%" }}>
              <Typography variant="h5" sx={{ color: "white" }}>
                <strong>Lexus LFA -</strong> 2012 model
              </Typography>
              <Stack pt={1}>
                <Divider sx={{ borderColor: "#3d3e3e" }} />
              </Stack>
              <Stack spacing={1} flexGrow={1} justifyContent="end">
                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{ color: "white" }}>
                    1000 Shares of Lexus LFA
                  </Typography>
                  <Typography sx={{ color: "white", fontWeight: 600 }}>
                    $5,000.00 USD
                  </Typography>
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{ color: "white" }}>
                    Processing Fee
                  </Typography>
                  <Typography sx={{ color: "white" }}>WAIVED</Typography>
                </Stack>

                <Divider sx={{ borderColor: "#3d3e3e" }} />

                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{ color: "white" }}>
                    Investment total
                  </Typography>
                  <Typography sx={{ color: "white", fontWeight: 600 }}>
                    $5,000.00 USD
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Box>
        <Stack pb={20}>
          <Grid container spacing={5}>
            <Grid item md={12} xs={12} sm={12}>
              <Stack spacing={1}>
                <Typography sx={{ fontWeight: 600, color: "white" }}>
                  How Much Would You Like to Invest?
                </Typography>
                <Stack direction="row" spacing={5} alignItems="center">
                  <TextField
                    className="input"
                    placeholder="$ 5,000.00"
                    fullWidth
                  ></TextField>
                  <Box component="img" src={ArrowRightIcon}></Box>
                  <TextField
                    className="input"
                    fullWidth
                    placeholder="50 Shares"
                  ></TextField>
                </Stack>
              </Stack>
            </Grid>

            <Grid item md={12} xs={12} sm={12}>
              <Stack spacing={1}>
                <Typography sx={{ fontWeight: 600, color: "white" }}>
                  Funding Method
                </Typography>
                <Select placeholder="Select" className="input">
                  <MenuItem value="1">United States</MenuItem>
                </Select>
              </Stack>
            </Grid>

            <Grid item md={6} xs={12} sm={6}>
              <Button
                fullWidth
                size="large"
                startIcon={<Box component="img" src={ArrowLeftIcon}></Box>}
                sx={{
                  background: "#2D2F30",
                  color: "white",
                  fontWeight: 600,
                }}
              >
                Previous
              </Button>
            </Grid>
            <Grid item md={6} xs={12} sm={6}>
              <Button
                fullWidth
                size="large"
                endIcon={<Box component="img" src={CheckIcon}></Box>}
                sx={{
                  background: "#29FFF5",
                  color: "black",
                  fontWeight: 600,
                }}
              >
                Done
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Box>
  );
};

export default OrderComponent;
