import { useNavigate } from "react-router-dom";
import { Box, Container, Grid, Typography } from "@mui/material";

import com_1 from "../../assets/img/Home/com_1.svg";
import com_2 from "../../assets/img/Home/com_2.svg";
import com_3 from "../../assets/img/Home/com_3.svg";
import com_4 from "../../assets/img/Home/com_4.svg";
import slider_arrow from "../../assets/img/Home/slider_arrow.png";
import info from "../../assets/img/Home/info.svg";
import Carousel from "react-material-ui-carousel";
import { Rotate90DegreesCcw } from "@mui/icons-material";

const CommunityComponent = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <Container maxWidth="xl">
        <Typography
          sx={{
            fontSize: 12,
            color: "#F7F9FD",
            fontWeight: 600,
            lineHeight: "15px",
            fontFamily: "Poppins, sans-serif",
            backgroundColor: "#33383D",
            borderRadius: "36px",
            padding: "4px 0px",
            maxWidth: 200,
            marginBottom: {xs: '20px' , md:"36px"},
            // letterSpacing: "1px",
            textAlign: "center",
          }}
        >
          OUR TAILORED SANCTUARY
        </Typography>
        <Box pb={"48px"}>
          <Typography
            sx={{
              fontSize: {xs: 24 , md: 30},
              color: "#242424",
              fontWeight: 600,
              lineHeight: {xs: '30px' , md:"38px"},
              fontFamily: "Poppins, sans-serif",
            }}
          >
            We created a digital oasis where the safeguarding oversight of the SEC, creates a haven of trust, security, and the exquisite essence of luxury.
          </Typography>
        </Box>

        <Grid container  direction="row" sx={{display : {xs: 'none' , md: 'flex'}}}  alignItems="stretch"  spacing={"18px"} pb={"30px"}>
          <Grid item xs={12} sm={6} lg={3}>
            <Box
              sx={{
                border: "1px solid #242424",
                borderRadius: "8px",
                padding: "28px",
                paddingLeft: "28px",
                paddingRight: "39px",
                minHeight : '156px' 
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={com_1} alt="com"></img>
                <Typography
                  sx={{
                    fontSize: 17,
                    color: "#242424",
                    fontWeight: 600,
                    lineHeight: "26px",
                    fontFamily: "Poppins, sans-serif",
                    paddingLeft: "10px",
                  }}
                >
                  Super Community
                </Typography>
              </Box>

              <Typography
                sx={{
                  fontSize: 12,
                  color: "#6B767F",
                  fontWeight: 400,
                  lineHeight: "17px",
                  fontFamily: "Poppins, sans-serif",
                  paddingTop : '14px'
                }}
              >
              Curating a like-minded community  of collectors, enthusiasts, and dreamers.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Box
              sx={{
                border: "1px solid #242424",
                borderRadius: "8px",
                padding: "28px",
                paddingLeft: "28px",
                paddingRight: "39px",
                minHeight : '156px' 
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={com_2} alt="com"></img>
                <Typography
                  sx={{
                    fontSize: 17,
                    color: "#242424",
                    fontWeight: 600,
                    lineHeight: "26px",
                    fontFamily: "Poppins, sans-serif",
                    paddingLeft: "10px",
                  }}
                >
                  SEC Compliance
                </Typography>
              </Box>

              <Typography
                sx={{
                  fontSize: 12,
                  color: "#6B767F",
                  fontWeight: 400,
                  lineHeight: "17px",
                  fontFamily: "Poppins, sans-serif",
                  paddingTop : '14px'
                }}
              >
               We are an SEC compliant business that offers factionalized investment for consumers.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Box
              sx={{
                border: "1px solid #242424",
                borderRadius: "8px",
                padding: "28px",
                paddingLeft: "28px",
                paddingRight: "39px",
                minHeight : '156px' 
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={com_3} alt="com"></img>
                <Typography
                  sx={{
                    fontSize: 17,
                    color: "#242424",
                    fontWeight: 600,
                    lineHeight: "26px",
                    fontFamily: "Poppins, sans-serif",
                    paddingLeft: "10px",
                  }}
                >
                  Exclusive Access
                </Typography>
              </Box>

              <Typography
                sx={{
                  fontSize: 12,
                  color: "#6B767F",
                  fontWeight: 400,
                  lineHeight: "17px",
                  fontFamily: "Poppins, sans-serif",
                  paddingTop : '14px'
                }}
              >
              Tailored marketplace, ensuring that every item listed is of exceptional quality and value.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Box
              sx={{
                border: "1px solid #242424",
                borderRadius: "8px",
                padding: "28px",
                paddingLeft: "28px",
                paddingRight: "39px",
                minHeight : '156px' 
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={com_4} alt="com"></img>
                <Typography
                  sx={{
                    fontSize: 17,
                    color: "#242424",
                    fontWeight: 600,
                    lineHeight: "26px",
                    fontFamily: "Poppins, sans-serif",
                    paddingLeft: "10px",
                  }}
                >
                  Innovation Focused
                </Typography>
              </Box>

              <Typography
                sx={{
                  fontSize: 12,
                  color: "#6B767F",
                  fontWeight: 400,
                  lineHeight: "17px",
                  fontFamily: "Poppins, sans-serif",
                  paddingTop : '14px'
                }}
              >
              We embrace cutting-edge blockchain technology to enhance consumer the buying experience
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* mobile slider */}

        <Carousel  NextIcon={<img src={slider_arrow}></img>} PrevIcon={<img src={slider_arrow} style={{transform : 'rotate(180deg)'}}></img>} sx={{ marginBottom : '50px' , display : {xs: 'block' , md: 'none'}}}>
          <Box
              sx={{
                border: "1px solid #242424",
                borderRadius: "8px",
                padding: "28px",
                paddingLeft: "28px",
                paddingRight: "39px",
                minHeight : '156px' 
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={com_1} alt="com"></img>
                <Typography
                  sx={{
                    fontSize: 17,
                    color: "#242424",
                    fontWeight: 600,
                    lineHeight: "26px",
                    fontFamily: "Poppins, sans-serif",
                    paddingLeft: "10px",
                  }}
                >
                  Super Community
                </Typography>
              </Box>

              <Typography
                sx={{
                  fontSize: 12,
                  color: "#6B767F",
                  fontWeight: 400,
                  lineHeight: "17px",
                  fontFamily: "Poppins, sans-serif",
                  paddingTop : '14px'
                }}
              >
              Curating a like-minded community  of collectors, enthusiasts, and dreamers.
              </Typography>
          </Box>
          <Box
              sx={{
                border: "1px solid #242424",
                borderRadius: "8px",
                padding: "28px",
                paddingLeft: "28px",
                paddingRight: "39px",
                minHeight : '156px' 
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={com_2} alt="com"></img>
                <Typography
                  sx={{
                    fontSize: 17,
                    color: "#242424",
                    fontWeight: 600,
                    lineHeight: "26px",
                    fontFamily: "Poppins, sans-serif",
                    paddingLeft: "10px",
                  }}
                >
                  SEC Compliance
                </Typography>
              </Box>

              <Typography
                sx={{
                  fontSize: 12,
                  color: "#6B767F",
                  fontWeight: 400,
                  lineHeight: "17px",
                  fontFamily: "Poppins, sans-serif",
                  paddingTop : '14px'
                }}
              >
               We are an SEC compliant business that offers factionalized investment for consumers.
              </Typography>
          </Box>
          <Box
              sx={{
                border: "1px solid #242424",
                borderRadius: "8px",
                padding: "28px",
                paddingLeft: "28px",
                paddingRight: "39px",
                minHeight : '156px' 
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={com_3} alt="com"></img>
                <Typography
                  sx={{
                    fontSize: 17,
                    color: "#242424",
                    fontWeight: 600,
                    lineHeight: "26px",
                    fontFamily: "Poppins, sans-serif",
                    paddingLeft: "10px",
                  }}
                >
                  Exclusive Access
                </Typography>
              </Box>

              <Typography
                sx={{
                  fontSize: 12,
                  color: "#6B767F",
                  fontWeight: 400,
                  lineHeight: "17px",
                  fontFamily: "Poppins, sans-serif",
                  paddingTop : '14px'
                }}
              >
              Tailored marketplace, ensuring that every item listed is of exceptional quality and value.
              </Typography>
          </Box>
          <Box
              sx={{
                border: "1px solid #242424",
                borderRadius: "8px",
                padding: "28px",
                paddingLeft: "28px",
                paddingRight: "39px",
                minHeight : '156px' 
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img src={com_4} alt="com"></img>
                <Typography
                  sx={{
                    fontSize: 17,
                    color: "#242424",
                    fontWeight: 600,
                    lineHeight: "26px",
                    fontFamily: "Poppins, sans-serif",
                    paddingLeft: "10px",
                  }}
                >
                  Innovation Focused
                </Typography>
              </Box>

              <Typography
                sx={{
                  fontSize: 12,
                  color: "#6B767F",
                  fontWeight: 400,
                  lineHeight: "17px",
                  fontFamily: "Poppins, sans-serif",
                  paddingTop : '14px'
                }}
              >
              We embrace cutting-edge blockchain technology to enhance consumer the buying experience
              </Typography>
          </Box>
        </Carousel>
        <Box pb={'100px'} sx={{ display : {xs: 'none' , md: 'block'}}}>
            <Typography 
            onClick={() => navigate("/how-it-works")}
                sx={{
                  fontSize: 18,
                  color: "#000000",
                  fontWeight: 400,
                  lineHeight: "27px",
                  fontFamily: "Poppins, sans-serif",
                  display : 'flex',
                  alignItems : 'center',
                  justifyContent : 'end',
                  marginTop : 0,
                  marginBottom : 0,
                  cursor : 'pointer'
                }}
                className="work_section"
              >
                How it works
                <span style={{display : 'flex' , paddingLeft : '8px'}}><img src={info} alt="info"></img></span>
            </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default CommunityComponent;
