import React from 'react'
import { Box, Button, Container, Grid, Input, Stack, TextField, Typography } from "@mui/material";
import card_list_arrow from "../assets/img/cardlist/cardlist_arrow.svg"
import card_list_search from "../assets/img/cardlist/cardlist_search.svg"
import card_list_filter from "../assets/img/cardlist/cardlist_filter.svg"
import card_list_grid from "../assets/img/cardlist/cardlist_grid.svg"
import { Margin } from '@mui/icons-material';
import CarCard from '../components/Home/Product/Carcard';
import CarCardF from '../components/Home/Product/CarcardF';
import CarCardM from '../components/Home/Product/CarcardM';
import CarCardN from '../components/Home/Product/CarcardN';
import CarCardS from '../components/Home/Product/CarcardS';
import CarCardY from '../components/Home/Product/CarcardY';
import category_arrow from "../assets/img/Home/category_arrow.png";
import search_icon from "../assets/img/Home/search_icon.png";

const CardList = () => {
    const style = {
        "& label.Mui-focused": {
          color: "#626264 !important",
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "none !important",
        },
        "& .MuiInputLabel-root": {
          color: "#626264 !important",
        },
        "& .MuiOutlinedInput-input": {
          color: "#626264 !important",
          backgroundColor: "#f7f7f7 !important",
          maxHeight: "26px !important",
          padding: "7px 0px 7px 30px !important",
          borderRadius: "6px !important",
          fontSize: "12px !important",
          width: "210px !important",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderRadius: "6px !important",
            color: "#626264 !important",
            fontSize: "12px !important",
            padding: "0px !important",
          },
          "&:hover fieldset": {
            borderColor: "none !important",
          },
          "&.Mui-focused fieldset": {
            borderColor: "none !important",
          },
        },
      };
    return (
        <Box sx={{marginTop : {xs: '28px', sm :'70px' , md: '110px'}}}>
            <Container maxWidth="xl">
                <Box sx={{ color: "#242424", fontWeight: 600, fontSize: {xs: '17px' ,md:"31px"}, lineHeight: {xs: '23px' ,md:"56px"}, fontFamily: "Poppins, sans-serif !important", paddingTop: "72px", }}>
                  Welcome the future of luxury asset investments, discover, invest, and grow.
                </Box>
                <Box sx={{marginTop:{xs: '37px',md:"75px"}}}>

                {/* mobile search bar */}
                <Box
          sx={{
            display: { xs: "none", lg: "none" },
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "15px",
          }}
        >
          <TextField
            sx={style}
            placeholder="Search by Asset or Category"
          ></TextField>
          <Box component={'img'} src={search_icon} alt="search" sx={{position : 'absolute' , left : {xs: '25px' , md : '35px'}}}></Box>
          <Typography
            sx={{
              fontSize: 12,
              color: "#182135",
              fontWeight: 400,
              lineHeight: "15px",
              fontFamily: "Poppins, sans-serif",
              display: "flex",
            }}
          >
            Category
            <img src={category_arrow} alt="category"></img>
          </Typography>
                </Box>

                    <Box sx={{display: {xs: 'none' , lg:"none"},justifyContent:"space-between",marginBottom:"43px"}}>
                       <Box sx={{display: "flex", alignItems:"center",padding:"12px 33px",cursor:"pointer"}}>
                           <Typography sx={{ color: "#191B23", fontWeight: 400, fontSize: "20px", }}>
                             Class
                           </Typography>
                           <img src={card_list_arrow} style={{marginLeft: "10px"}} />
                       </Box> 
                       <Box component="form" autoComplete="off" sx={{display: "flex", alignItems:"center",padding:"9px 32px",border:"1px solid #6B767F", borderRadius:"10px", minWidth: "800px"}}>
                            <img src={card_list_search} style={{marginRight:"10px"}} />
                            <Input fullWidth disableUnderline placeholder="Search by collection, class, brand, and more..." sx={{ color: "#A7AEB4", fontWeight: 400, fontSize:"20px", }} />
                            {/* Search by collection, class, brand, and more... */}
                           
                       </Box> 
                       <Box sx={{display: "flex", alignItems:"center",padding:"12px 33px",cursor:"pointer"}}>
                           <img src={card_list_filter} />
                           <Typography sx={{ color: "#191B23", fontWeight: 400, fontSize: "20px", marginLeft: "10px"}}>
                             Filters
                           </Typography>
                       </Box> 
                       <Box sx={{display: "flex", alignItems:"center",padding:"16px",cursor:"pointer"}}>
                           <img src={card_list_grid} />
                       </Box> 
                    </Box>
                    <Grid container columnSpacing={"20px"} rowSpacing={"43px"} sx={{marginBottom:"58px"}}>
                        <Grid item xs={12} sm={6} lg={4}>
                            <CarCardY />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <CarCardS />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <CarCard />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <CarCardN />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <CarCardM />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={4}>
                            <CarCardF />
                        </Grid>
                    </Grid>
                </Box>

            </Container>

        </Box>
    )
}

export default CardList
