import { combineReducers } from "redux";

import authReducer from "./auth";
import snackbarReducer from "./snackbar";
import investorReducer from "./auctions";

const reducer = combineReducers({
  auth: authReducer,
  snackbar: snackbarReducer,
  auctions: investorReducer,
});

export default reducer;
