import { useEffect } from "react";
import { FormEvent, useContext, useState } from "react";

import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Link,
  MenuItem,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";

import { BASE_URL, States, createData } from "../config";
import APIContext from "../context/ApiContext";

import React from "react";
import profileimg from "../assets/img/profile.png";
import coverL from "../assets/img/cover-latest1.svg";
import plus from "../assets/img/plus.svg";

import pinkcircle from "../assets/img/pinkcircle.svg";
import TableComponent from "./Profile/TableComponent";
import Dropdown from "../assets/img/Dropdown.png";
import { dispatch, useSelector } from "../store";
import {
  UpdateInvester,
  UpdateUserInfo,
  UpdateUserName,
} from "../store/reducers/auth";
import snackbar from "../config/snackbar";
import { MuiTelInput } from "mui-tel-input";

import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const ProfileComponent = () => {
  const { user, userId, username, investorId, isVerified, token }: any =
    useSelector((state) => state.auth);

  const navigate = useNavigate();
  const [rows, setRows] = useState<any>([]);
  const [items, setItems] = useState<any>();
  const [value, setValue] = useState("Investments");
  const [verified, setVerified] = useState<boolean>(false);
  // const [phone, setPhone] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const [profile, setProfile] = useState(`${BASE_URL}user/image/${userId}`);
  const [pagelink, setpagelink] = useState("asset");
  const [status, setStatus] = useState<boolean>(false);
  const [type, setType] = useState<string>("person");

  const [investor, setInvestor] = useState({
    first_name: "",
    last_name: "",
    email: user.email,
    citizenship: "United States",
    date_of_birth: dayjs(new Date()),
    phone_number: "",
    street_address_1: "",
    street_address_2: "",
    city: "Select",
    postal_code: "",
    state: "AR",
    region: "",
    country: "US",
    tax_number: "",
  });

  // const [investor, setInvestor] = useState<InvestorProfileType>();

  const {
    getInvestor,
    getProfileAssets,
    getAllInvestments,
    sendEmailVerification,
    loading,
    setLoading,
    createInvestor,
    updateInvestor,
    uploadImage,
    getImage,
  } = useContext(APIContext);

  const states = [
    "Select",
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleClick = () => {
    setOpen(true);
    if (!isVerified && user && user.email) {
      sendEmailVerification(user.email);
    }
    //  navigate('/verifyEmail');
  };

  const uploadProfile = async (profileImg: any) => {
    setLoading(true);
    setProfile(URL.createObjectURL(profileImg));
    const formData = new FormData();
    formData.append("file", profileImg);
    const { data: res } = await uploadImage(formData);
    setLoading(false);
    if (res) {
      // const { data: avatar } = await getImage();
      // setProfile(`${BASE_URL}user/image/${userId}`);
    }
  };

  const handleCreateInvestor = async (e: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (!status) {
      setStatus(!status);
      return;
    }
    setLoading(true);

    try {
      const data = await createInvestor({
        type,
        entity: investor,
      });

      if (data.investor_id) {
        dispatch(UpdateInvester({ investor_id: data.investor_id }));
        //snackbar("Successfully Verified.");
        navigate("/market");
      } else {
        navigate("/market");
        snackbar(data.response, "error");
      }
    } catch (error: any) {
      navigate("/market");
      snackbar(error.message, "error");
    }
    setLoading(false);
  };

  const handleUpdateInvestor = async (e: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    // if (!status) {
    //   setStatus(!status);
    //   return;
    // }
    setLoading(true);

    try {
      const updatedInvestor = {
        ...investor,
        date_of_birth: formatDate(investor.date_of_birth.toString()),
        tax_id_number: investor.tax_number,
        phone: investor.phone_number,
      };
      const { data } = await updateInvestor({
        updateBodyIndividualInvestorDto: updatedInvestor,
        id: investorId,
        token: token,
        wallet_address: "",
      });
      snackbar(data, "res");

      if (data.success) {
        // dispatch(UpdateInvester({ investor_id: data.investor_id }));
        snackbar("Successfully Updated.");
        dispatch(
          UpdateUserName({
            username: `${investor.first_name} ${investor.last_name}`,
          })
        );
      } else {
        snackbar(data.response, "error");
      }
    } catch (error: any) {
      snackbar(error.message, "error");
    }
    setLoading(false);
  };

  const formatDate = (date: string) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const style = {
    "& label.Mui-focused": {
      color: "#232632 !important",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#232632 !important",
    },
    "& .MuiInputLabel-root": {
      color: "#232632 !important",
    },
    "& .MuiOutlinedInput-input": {
      color: "#232632 !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#232632 !important",
        border: "2px solid #232632 !important",
        borderRadius: "10px !important",
      },
      "&:hover fieldset": {
        borderColor: "#232632 !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#232632 !important",
      },
    },
  };

  const [form1, setform1] = useState(true);

  const load = async () => {
    setLoading(true);
    setVerified(isVerified);

    try {
      const data = await getImage();
    } catch (error) {
      setProfile(profileimg);
    }
    if (isVerified && investorId) {
      const { data: invest } = await getInvestor(investorId);

      console.log(invest, "invest");

      setInvestor({
        ...invest,
        date_of_birth: dayjs(new Date(invest.date_of_birth)),
      });
      const { data } = await getProfileAssets();
      const {
        data: { invesments },
      } = await getAllInvestments();

      const rowsData = [];
      for (let i = 0; i < invesments.length; i++) {
        const item: any = invesments[i];
        rowsData.push(
          createData(
            new Date(item.created_at).toLocaleString(),
            item.invesment_id,
            item.offering.name,
            item.price,
            item.quantity,
            "Wire Transfer",

            item.status,
            (Number(item.price) * Number(item.quantity)).toString(),
            item.status !== "started"
          )
        );
      }
      setRows(rowsData);
      setItems(data.investmentTotals);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!isVerified) {
      navigate("/verify");
    }

    if (userId) {
      load();
    }
    // eslint-disable-next-line
  }, [userId, isVerified, investorId]);

  return (
    <Box
      sx={{
        padding: { xs: "0px 0px 51px", md: "0px 0px 68px" },
        marginTop: { xs: "60px", md: "80px" },
      }}
    >
      <Box
        component={"img"}
        src={coverL}
        sx={{ width: "100%", height: { xs: "80px", sm: "100%" } }}
        alt="cover"
      ></Box>
      <Container maxWidth="xl">
        <Grid
          container
          paddingBottom={{ md: "30px" }}
          sx={{ borderBottom: { md: "1px solid #A7AEB4" } }}
        >
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              display: "flex",
              borderBottom: { xs: "2px solid #A7AEB4", md: "none" },
              paddingBottom: { xs: "24px", md: "0px" },
            }}
          >
            <Box sx={{ position: "relative" }}>
              {profile && (
                <Box
                  component={"img"}
                  src={profile}
                  sx={{
                    marginTop: { xs: "-50px", md: "-110px" },
                    width: { xs: "80px", md: "201px" },
                    height: { xs: "80px", md: "201px" },
                    border: "5px solid white",
                    borderRadius: "5px",
                  }}
                  alt="profile"
                ></Box>
              )}

              {pagelink === "profile" && (
                <Button
                  component="label"
                  sx={{
                    fontSize: { xs: "9px", md: "12px" },
                    color: "#000000",
                    fontWeight: 400,
                    backgroundColor: "#A7AEB4",
                    borderRadius: "6px",
                    lineHeight: { xs: "10px", md: "16px" },
                    fontFamily: "Poppins, sans-serif",
                    display: "flex",
                    opacity: "0.9",
                    position: "absolute",
                    bottom: { xs: "19px", md: "30px" },
                    left: { xs: "3%", md: "13%" },
                    padding: { xs: "5px 4px", md: "9px 30px" },
                    alignItems: "center",
                  }}
                  className="photobutton"
                >
                  {loading ? "Uploading..." : "Change Photo"}

                  <VisuallyHiddenInput
                    type="file"
                    accept="image/*"
                    onChange={(e: any) => uploadProfile(e.target.files[0])}
                  />
                </Button>
              )}
            </Box>
            <Box sx={{ padding: { xs: "0px 10px 0px", md: "30px 32px 0px" } }}>
              <Typography
                sx={{
                  fontSize: { xs: 24, md: 48 },
                  color: "#04091E",
                  fontWeight: 600,
                  lineHeight: { xs: "24px", md: "48px" },
                  fontFamily: "Poppins, sans-serif",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {isVerified && investor
                  ? `${investor?.first_name} ${investor?.last_name}`
                  : username}

                <Box
                  component={"img"}
                  src={pinkcircle}
                  sx={{
                    marginLeft: { xs: "8px", md: "20px" },
                    width: { xs: "16px", md: "auto" },
                  }}
                  alt="circle"
                ></Box>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            alignSelf={"center"}
            sx={{ marginTop: { xs: "18px", md: "0px" } }}
          >
            <Box
              sx={{
                border: "1px solid #6B767F",
                borderRadius: "8px",
                padding: "3px",
                maxWidth: "210px",
                marginX: { xs: "auto", md: "0px" },
                marginLeft: { md: "auto" },
                minHeight: "35px",
              }}
            >
              <Link
                sx={{
                  color: pagelink === "asset" ? "#14121a" : "#a7a6a9",
                  fontSize: 11,
                  fontWeight: 400,
                  lineHeight: "16px",
                  fontFamily: "Poppins, sans-serif",
                  textDecoration: "none",
                  padding: "6px 22px",
                  backgroundColor: pagelink === "asset" ? "#A7AEB4" : "#fff",
                  borderRadius: "6px",
                  cursor: "pointer",
                }}
                onClick={() => setpagelink("asset")}
              >
                My Assets
              </Link>
              <Link
                sx={{
                  color: pagelink === "profile" ? "#14121a" : "#a7a6a9",
                  fontSize: 11,
                  fontWeight: 400,
                  lineHeight: "16px",
                  fontFamily: "Poppins, sans-serif",
                  textDecoration: "none",
                  padding: "6px 22px",
                  cursor: "pointer",
                  backgroundColor: pagelink === "profile" ? "#A7AEB4" : "#fff",
                  borderRadius: "6px",
                }}
                onClick={() => setpagelink("profile")}
              >
                Edit Profile
              </Link>
            </Box>
          </Grid>
        </Grid>
        {pagelink === "asset" && <TableComponent data={rows} />}
        {pagelink === "profile" && (
          <>
            {form1 ? (
              <Box
                sx={{
                  padding: { xs: "10px", md: "24px" },
                  maxWidth: "892px",
                  backgroundColor: "#F7F9FD",
                  borderRadius: "8px",
                  margin: "48px auto 0px",
                }}
                className="tablesection"
              >
                <form onSubmit={handleUpdateInvestor}>
                  <Stack>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        color: "#04091E",
                        fontFamily: "Poppins, sans-serif",
                        fontSize: 18,
                        paddingBottom: "20px",
                      }}
                    >
                      Investor Details
                    </Typography>
                  </Stack>

                  <Stack sx={{ marginTop: "12px" }}>
                    <Grid container columnSpacing={"8px"} rowSpacing={"16px"}>
                      <Grid item xs={12} sx={{ position: "relative" }}>
                        <Stack>
                          <TextField
                            id="outlined-basic"
                            label="Investor Type"
                            variant="outlined"
                            required
                            className="input"
                            defaultValue="person"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                            sx={style}
                            fullWidth
                            inputProps={{
                              style: { color: "#191B23" },
                            }}
                            autoComplete="off"
                            select
                          >
                            <MenuItem value="person">an Individual</MenuItem>
                            <MenuItem value="company">Company</MenuItem>
                            <MenuItem value="trust">Trust</MenuItem>
                          </TextField>
                        </Stack>
                        <Box
                          src={Dropdown}
                          component={"img"}
                          sx={{
                            position: "absolute",
                            right: "20px",
                            top: { xs: "40px", md: "40px" },
                          }}
                        ></Box>
                      </Grid>

                      <Grid item xs={6}>
                        <Stack spacing={1}>
                          <TextField
                            id="outlined-basic"
                            label="First Name"
                            variant="outlined"
                            required
                            className="input"
                            placeholder="First Name"
                            value={investor.first_name}
                            onChange={(e) =>
                              setInvestor({
                                ...investor,
                                first_name: e.target.value,
                              })
                            }
                            sx={style}
                            fullWidth
                            inputProps={{
                              style: { color: "#191B23" },
                            }}
                            autoComplete="off"
                          />
                        </Stack>
                      </Grid>

                      <Grid item xs={6}>
                        <Stack spacing={1}>
                          <TextField
                            id="outlined-basic"
                            label="Last Name"
                            variant="outlined"
                            required
                            className="input"
                            placeholder="Last Name"
                            value={investor.last_name}
                            onChange={(e) =>
                              setInvestor({
                                ...investor,
                                last_name: e.target.value,
                              })
                            }
                            sx={style}
                            fullWidth
                            inputProps={{
                              style: { color: "#191B23" },
                            }}
                            autoComplete="off"
                          />
                        </Stack>
                      </Grid>

                      <Grid item xs={6}>
                        <Stack spacing={1}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              className="date"
                              label="Date of Birth"
                              value={investor.date_of_birth}
                              onChange={(e: any) => {
                                setInvestor({
                                  ...investor,
                                  date_of_birth: dayjs(e.toString()),
                                });
                              }}
                            />
                          </LocalizationProvider>

                          {/* <TextField
                            id="outlined-basic"
                            label="Date of Birth"
                            variant="outlined"
                            required
                            className="input"
                            placeholder="MM/DD/YYYY"
                            value={formatDate(investor.date_of_birth)}
                            onChange={(e) => {
                              let inputValue = e.target.value;
                              inputValue = inputValue.replace(/[^\d]/g, "");
                              if (inputValue.length <= 8) {
                                inputValue = inputValue.replace(
                                  /(\d{2})(\d{2})(\d{4})/,
                                  "$3-$1-$2"
                                );
                                setInvestor({
                                  ...investor,
                                  date_of_birth: inputValue,
                                });
                              }
                            }}
                            sx={style}
                            fullWidth
                            inputProps={{
                              style: { color: "#191B23" },
                            }}
                            autoComplete="off"
                          /> */}
                        </Stack>
                      </Grid>

                      <Grid item xs={6} sx={{ position: "relative" }}>
                        <Stack spacing={1}>
                          <TextField
                            id="outlined-basic"
                            label="Citizenship"
                            variant="outlined"
                            required
                            className="input"
                            defaultValue="United States"
                            value={investor.citizenship}
                            onChange={(e) =>
                              setInvestor({
                                ...investor,
                                citizenship: e.target.value,
                              })
                            }
                            sx={style}
                            fullWidth
                            inputProps={{
                              style: { color: "#191B23" },
                            }}
                            autoComplete="off"
                            select
                          >
                            <MenuItem value="United States">
                              United States
                            </MenuItem>
                          </TextField>
                        </Stack>
                        <Box
                          src={Dropdown}
                          component={"img"}
                          sx={{
                            position: "absolute",
                            right: "20px",
                            top: { xs: "40px", md: "40px" },
                          }}
                        ></Box>
                      </Grid>

                      <Grid item xs={6} sx={{ position: "relative" }}>
                        <Stack spacing={1}>
                          <TextField
                            id="outlined-basic"
                            label="Country"
                            variant="outlined"
                            required
                            className="input"
                            defaultValue="United States"
                            value={investor.country}
                            onChange={(e) =>
                              setInvestor({
                                ...investor,
                                country: e.target.value,
                              })
                            }
                            sx={style}
                            fullWidth
                            inputProps={{
                              style: { color: "#191B23" },
                            }}
                            autoComplete="off"
                            select
                          >
                            <MenuItem value="US">United States</MenuItem>
                          </TextField>
                        </Stack>
                        <Box
                          src={Dropdown}
                          component={"img"}
                          sx={{
                            position: "absolute",
                            right: "20px",
                            top: { xs: "40px", md: "40px" },
                          }}
                        ></Box>
                      </Grid>

                      <Grid item xs={6} sx={{ position: "relative" }}>
                        <Stack spacing={1}>
                          <TextField
                            id="outlined-basic"
                            label="State"
                            variant="outlined"
                            required
                            className="input"
                            value={investor.state}
                            onChange={(e) =>
                              setInvestor({
                                ...investor,
                                state: e.target.value,
                              })
                            }
                            sx={style}
                            fullWidth
                            inputProps={{
                              style: { color: "#191B23" },
                            }}
                            autoComplete="off"
                            select
                          >
                            {States?.map((state, index) => (
                              <MenuItem key={index} value={state.symbol}>
                                {state.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Stack>
                        <Box
                          src={Dropdown}
                          component={"img"}
                          sx={{
                            position: "absolute",
                            right: "20px",
                            top: { xs: "40px", md: "40px" },
                          }}
                        ></Box>
                      </Grid>
                    </Grid>
                  </Stack>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      color: "#04091E",
                      fontFamily: "Poppins, sans-serif",
                      fontSize: 12,
                      paddingBottom: "20px",
                      marginTop: "24px",
                    }}
                  >
                    Billing Address
                  </Typography>
                  <Stack>
                    <Grid container columnSpacing={"8px"} rowSpacing={"16px"}>
                      <Grid item xs={12}>
                        <Stack spacing={1}>
                          <TextField
                            id="outlined-basic"
                            label="Full Address"
                            variant="outlined"
                            required
                            className="input"
                            placeholder="Enter your address"
                            value={investor.street_address_1}
                            onChange={(e) =>
                              setInvestor({
                                ...investor,
                                street_address_1: e.target.value,
                              })
                            }
                            sx={style}
                            fullWidth
                            inputProps={{
                              style: { color: "#191B23" },
                            }}
                            autoComplete="off"
                          />
                        </Stack>
                      </Grid>

                      <Grid item xs={6}>
                        <Stack spacing={1}>
                          <TextField
                            id="outlined-basic"
                            label="Zip / Postal Code"
                            variant="outlined"
                            required
                            className="input"
                            placeholder="12345"
                            value={investor.postal_code}
                            onChange={(e) =>
                              setInvestor({
                                ...investor,
                                postal_code: e.target.value,
                              })
                            }
                            sx={style}
                            fullWidth
                            inputProps={{
                              style: { color: "#191B23" },
                            }}
                            autoComplete="off"
                          />
                        </Stack>
                      </Grid>

                      <Grid item xs={6}>
                        <Stack spacing={1}>
                          <MuiTelInput
                            value={investor.phone_number}
                            id="outlined-basic"
                            label="Phone number"
                            variant="outlined"
                            onChange={(e) =>
                              setInvestor({ ...investor, phone_number: e })
                            }
                            defaultCountry="US"
                            sx={style}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack spacing={1}>
                          <TextField
                            id="outlined-basic"
                            label="Email address"
                            variant="outlined"
                            sx={style}
                            required
                            type="email"
                            className="input"
                            // placeholder="joe.smith@email.com"
                            value={investor.email}
                            onChange={(e) =>
                              setInvestor({
                                ...investor,
                                email: e.target.value,
                              })
                            }
                          ></TextField>
                        </Stack>
                      </Grid>
                      <Grid item xs={6}>
                        <Stack spacing={1}>
                          <TextField
                            id="outlined-basic"
                            label="Social Security Number (SSN)"
                            variant="outlined"
                            sx={style}
                            required
                            className="input"
                            placeholder="XXX - XX - XXXX"
                            value={investor.tax_number}
                            // onChange={(e) =>
                            //   setInvestor({
                            //     ...investor,
                            //     tax_number: e.target.value,
                            //   })
                            //   }
                            onChange={(e) => {
                              let inputValue = e.target.value;
                              inputValue = inputValue.replace(/[^\d]/g, "");
                              if (inputValue.length <= 9) {
                                inputValue = inputValue.replace(
                                  /(\d{3})(\d{2})(\d{4})/,
                                  "$1-$2-$3"
                                );
                                setInvestor({
                                  ...investor,
                                  tax_number: inputValue,
                                });
                              }
                            }}
                          ></TextField>
                        </Stack>
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          size="large"
                          sx={{
                            background: "#33383D",
                            color: "white",
                            fontWeight: 400,
                            "&:hover": {
                              background: "#33383D",
                            },
                            padding: "11px 16px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            fontSize: "16px",
                          }}
                          variant="contained"
                          disabled={loading}
                          type="submit"
                        >
                          {loading ? (
                            <CircularProgress
                              size={25}
                              color="inherit"
                              variant="indeterminate"
                            />
                          ) : (
                            <>
                              <span>Save</span>
                              <img src={plus}></img>
                            </>
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </Stack>
                </form>
              </Box>
            ) : (
              // <Box sx={{padding : {xs: '10px' , md: '24px'}, maxWidth : '892px' , backgroundColor : '#F7F9FD' , borderRadius : '8px' ,  margin : '48px auto 0px'}} className='tablesection'>
              //    <form onSubmit={handleCreateDetailInvestor}>
              //   <Stack >
              //    <Typography
              //      sx={{ fontWeight: 600,
              //        color: "#04091E",
              //        fontFamily: "Poppins, sans-serif",
              //        fontSize: 18,
              //        borderBottom : '1px solid #14121a',
              //        paddingBottom : '20px'
              //      }}
              //    >
              //      Investor Profile Details
              //    </Typography>
              //  </Stack>

              //  </form>
              // </Box>
              <></>
            )}
          </>
        )}
      </Container>
    </Box>
  );
};

export default ProfileComponent;
