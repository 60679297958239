import { Box } from "@mui/material";
import SignUpComponent from "../components/SignUpComponent";
import { useEffect } from "react";

const SignUp = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <Box className="sign-up">
      <SignUpComponent />
    </Box>
  );
};

export default SignUp;
