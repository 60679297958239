
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import hero from "../assets/img/faq_hero.png";
import plus from "../assets/img/Plus.png";
import minus from "../assets/img/Home/MinusCircle.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Faq() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [expanded, setExpanded] = useState<string | false>(false);

  const navigate = useNavigate();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <Box>
      {/*------------------ Hero section -------------------------*/}

      <Box
         pt={{ xs: '41px' , md: "145px" }}
         pb={{ xs: '71px' , md: "145px" }}
        sx={{
          backgroundImage: `url(${hero})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          borderBottomLeftRadius: "32px",
          borderBottomRightRadius: "32px",
          marginTop: {xs : "65px" , sm: '100px'},
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            color: "#4F575E",
            fontWeight: 600,
            fontFamily: "Poppins, sans-serif",
            textAlign: "center",
            lineHeight: "15px",
            backgroundColor: "#fff",
            borderRadius: "36px",
            padding: "4px 12px",
            maxWidth: "70px",
            margin: {xs: "0px auto 21px" , md: "0px auto 36px"},
          }}
        >
          FAQs
        </Typography>
        <Typography
          sx={{
            fontSize: {xs: "24px" , md: '48px'},
            color: "#fff",
            fontWeight: 600,
            fontFamily: "Poppins, sans-serif",
            textAlign: "center",
            lineHeight: {xs: '30px', md: "56px"},
          }}
        >
          Frequently Asked Questions
        </Typography>
        <Typography
          sx={{
            fontSize:{xs: '12px' , md: "16px"},
            color: "#CBCFD2",
            fontWeight: 400,
            fontFamily: "Poppins, sans-serif",
            textAlign: "center",
            lineHeight: {xs: "18px" , md: '24px'},
            paddingTop: {xs: "14px" , md: '24px'},
            maxWidth: { xs : '320px', md:"580px"},
            margin: "auto",
          }}
        >
          In a digital age of limitless possibilities, we embarked on a quest to
          redefine the standard of luxury cars and artwork ownership.
        </Typography>
      </Box>

 

       <Container maxWidth={"xl"} sx={{marginBottom : {xs:'41px' , md:'120px'} , marginTop : {xs: '30px' , md: '67px'}}}>
        <Grid container alignItems={'start'}>
          <Grid item  sx={{marginLeft : {lg: '20px'}}}>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              sx={{
                backgroundColor: "#fff",
                border:
                  expanded === "panel1"
                    ? "1px solid #ef32d9"
                    : "1px solid #E1E4ED",
                borderRadius: "6px !important",
                marginBottom: "12px",
                padding : '3px 0px',
                maxWidth : {lg:'533px'}
              }} className="tablesection"
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel1" ? (
                    <img src={minus} alt="minus"></img>
                  ) : (
                    <img src={plus} alt="plus"></img>
                  )
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{
                    width: "90%",
                    fontSize: 16,
                    color: expanded === "panel1" ? '#242424' :"#33383D",
                    fontWeight: expanded === "panel1" ? 600 : 500,
                    lineHeight: "20px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                 How does McQueen Lifestyle choose which assets to acquire?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingTop: 0 }}>
                <Typography
                  sx={{
                    width: "90%",
                    fontSize: 12,
                    color: "#6B767F",
                    fontWeight: 400,
                    lineHeight: "17px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  McQueen Lifestyle stands out for its commitment to quality and trust, sourcing assets exclusively from carefully vetted, reputable collectors. This selective approach ensures that each piece we acquire is part of a legacy of excellence, offering our clients exclusive access to the finest selections. By partnering with esteemed collectors, we open the door to a world of premium assets, each with a story to tell and a value to cherish.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              sx={{
                backgroundColor: "#fff",
                border:
                  expanded === "panel2"
                    ? "1px solid #ef32d9"
                    : "1px solid #E1E4ED",
                borderRadius: "6px !important",
                marginBottom: "12px",
                padding : '3px 0px',
                maxWidth : {lg:'533px'}
              }}
              className="tablesection"
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel2" ? (
                    <img src={minus} alt="minus"></img>
                  ) : (
                    <img src={plus} alt="plus"></img>
                  )
                }
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography
                  sx={{
                    width: "90%",
                    fontSize: 16,
                    color: expanded === "panel2" ? '#242424' :"#33383D",
                    fontWeight: expanded === "panel2" ? 600 : 500,
                    lineHeight: "20px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Why does McQueen Lifestyle only offer Classic Cars and Art?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingTop: 0 }}>
                <Typography
                  sx={{
                    width: "90%",
                    fontSize: 12,
                    color: "#6B767F",
                    fontWeight: 400,
                    lineHeight: "17px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  At present, McQueen Lifestyle only offers shares in classic cars and artwork, but it may expand to include additional types of assets in the future. Our initial focus on artwork and vintage cars was due to several compelling reasons:
                    <br />
                    <span style={{ fontWeight: 'bold' }}>Value Appreciation</span>: Over time, both art and classic cars have the potential to increase in value, especially if they are rare, sought after, or have historical significance.
                    <br />
                    <span style={{ fontWeight: 'bold' }}>Tangible Assets</span>: Unlike stocks or bonds, art and classic cars are tangible assets that investors can enjoy while they appreciate in value.
                    <br />
                    <span style={{ fontWeight: 'bold' }}>Diversification</span> :Investing in art and classic cars can diversify an investment portfolio, potentially reducing risk by spreading investments across different asset classes.
                    <br />
                    <span style={{ fontWeight: 'bold' }}>Inflation Hedge</span>: High-quality art and classic cars often retain their value and can even appreciate during times of inflation, acting as a hedge against currency devaluation.
                    <br />
                    <span style={{ fontWeight: 'bold' }}>Cultural and Emotional Value</span>: Beyond financial returns, these assets can offer cultural significance and emotional satisfaction, enriching the lives of collectors.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              sx={{
                backgroundColor: "#fff",
                border:
                  expanded === "panel3"
                    ? "1px solid #ef32d9"
                    : "1px solid #E1E4ED",
                borderRadius: "6px !important",
                marginBottom: "12px",
                padding : '3px 0px',
                maxWidth : {lg :'533px'}
              }}
              className="tablesection"
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel3" ? (
                    <img src={minus} alt="minus"></img>
                  ) : (
                    <img src={plus} alt="plus"></img>
                  )
                }
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography
                  sx={{
                    width: "90%",
                    fontSize: 16,
                    color: expanded === "panel3" ? '#242424' :"#33383D",
                    fontWeight: expanded === "panel3" ? 600 : 500,
                    lineHeight: "20px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Are there any risks involved in the securities that McQueen Lifestyle offers?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingTop: 0 }}>
                <Typography
                  sx={{
                    width: "90%",
                    fontSize: 12,
                    color: "#6B767F",
                    fontWeight: 400,
                    lineHeight: "17px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Investing in art and classic cars carries unique risks, such as market volatility where the value of these assets can fluctuate significantly based on trends and collector interest. Liquidity risk is also a factor; unlike stocks or bonds, selling these items quickly without a loss in value can be challenging. 
Lastly, the limited diversification offered by these assets can lead to higher risk if they form a significant part of an investment portfolio.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
              sx={{
                backgroundColor: "#fff",
                border:
                  expanded === "panel4"
                    ? "1px solid #ef32d9"
                    : "1px solid #E1E4ED",
                borderRadius: "6px !important",
                marginBottom: "12px",
                padding : '3px 0px',
                maxWidth : {lg :'533px'}
              }}
              className="tablesection"
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel4" ? (
                    <img src={minus} alt="minus"></img>
                  ) : (
                    <img src={plus} alt="plus"></img>
                  )
                }
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography
                  sx={{
                    width: "90%",
                    fontSize: 16,
                    color: expanded === "panel4" ? '#242424' :"#33383D",
                    fontWeight: expanded === "panel4" ? 600 : 500,
                    lineHeight: "20px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  What does McQueen Lifestyle charge buyers?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingTop: 0 }}>
                <Typography
                  sx={{
                    width: "90%",
                    fontSize: 12,
                    color: "#6B767F",
                    fontWeight: 400,
                    lineHeight: "17px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  All fees associated with McQueen Lifestyle are transparently disclosed to investors on the dedicated asset details page.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
              sx={{
                backgroundColor: "#fff",
                border:
                  expanded === "panel5"
                    ? "1px solid #ef32d9"
                    : "1px solid #E1E4ED",
                borderRadius: "6px !important",
                marginBottom: "12px",
                padding : '3px 0px',
                maxWidth : {lg :'533px'}
              }}
              className="tablesection"
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel5" ? (
                    <img src={minus} alt="minus"></img>
                  ) : (
                    <img src={plus} alt="plus"></img>
                  )
                }
                aria-controls="panel5bh-content"
                id="panel5bh-header"
              >
                <Typography
                  sx={{
                    width: "90%",
                    fontSize: 16,
                    color: expanded === "panel5" ? '#242424' :"#33383D",
                    fontWeight: expanded === "panel5" ? 600 : 500,
                    lineHeight: "20px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Can I list my artwork or classical car for sale on McQueen Lifestyle?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingTop: 0 }}>
                <Typography
                  sx={{
                    width: "90%",
                    fontSize: 12,
                    color: "#6B767F",
                    fontWeight: 400,
                    lineHeight: "17px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Asset owners interested in selling their assets are invited to contact our acquisitions team. This includes individual investors, classic car owners, gallery curators, institutional representatives, and artists. Please note that, we currently only consider artwork and classic cars with minimum value of $500,000 for listing.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item  sx={{marginRight : {lg :'20px'} ,marginLeft : 'auto' }}>
            <Accordion
              expanded={expanded === "panel6"}
              onChange={handleChange("panel6")}
              sx={{
                backgroundColor: "#fff",
                border:
                  expanded === "panel6"
                    ? "1px solid #ef32d9"
                    : "1px solid #E1E4ED",
                borderRadius: "6px !important",
                marginBottom: "12px",
                padding : '3px 0px',
                maxWidth : {lg :'533px'}
              }} className="tablesection"
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel6" ? (
                    <img src={minus} alt="minus"></img>
                  ) : (
                    <img src={plus} alt="plus"></img>
                  )
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{
                    width: "90%",
                    fontSize: 16,
                    color: expanded === "panel6" ? '#242424' :"#33383D",
                    fontWeight: expanded === "panel6" ? 600 : 500,
                    lineHeight: "20px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                 How can I contact McQueen Lifestyle to make a complaint?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingTop: 0 }}>
                <Typography
                  sx={{
                    width: "90%",
                    fontSize: 12,
                    color: "#6B767F",
                    fontWeight: 400,
                    lineHeight: "17px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Please send us an email on support@mcqueen.io
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel7"}
              onChange={handleChange("panel7")}
              sx={{
                backgroundColor: "#fff",
                border:
                  expanded === "panel7"
                    ? "1px solid #ef32d9"
                    : "1px solid #E1E4ED",
                borderRadius: "6px !important",
                marginBottom: "12px",
                padding : '3px 0px',
                maxWidth : {lg :'533px'}
              }}
              className="tablesection"
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel7" ? (
                    <img src={minus} alt="minus"></img>
                  ) : (
                    <img src={plus} alt="plus"></img>
                  )
                }
                aria-controls="panel7bh-content"
                id="panel7bh-header"
              >
                <Typography
                  sx={{
                    width: "90%",
                    fontSize: 16,
                    color: expanded === "panel7" ? '#242424' :"#33383D",
                    fontWeight: expanded === "panel7" ? 600 : 500,
                    lineHeight: "20px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Is McQueen Lifestyle SEC registered?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingTop: 0 }}>
                <Typography
                  sx={{
                    width: "90%",
                    fontSize: 12,
                    color: "#6B767F",
                    fontWeight: 400,
                    lineHeight: "17px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Yes, each asset we sell shares in is registered with and approved by SEC before it is listed on our platform. 
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel8"}
              onChange={handleChange("panel8")}
              sx={{
                backgroundColor: "#fff",
                border:
                  expanded === "panel8"
                    ? "1px solid #ef32d9"
                    : "1px solid #E1E4ED",
                borderRadius: "6px !important",
                marginBottom: "12px",
                padding : '3px 0px',
                maxWidth : {lg :'533px'}
              }}
              className="tablesection"
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel8" ? (
                    <img src={minus} alt="minus"></img>
                  ) : (
                    <img src={plus} alt="plus"></img>
                  )
                }
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography
                  sx={{
                    width: "90%",
                    fontSize: 16,
                    color: expanded === "panel8" ? '#242424' :"#33383D",
                    fontWeight: expanded === "panel8" ? 600 : 500,
                    lineHeight: "20px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  How can I learn about the compliance of each offering?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingTop: 0 }}>
                <Typography
                  sx={{
                    width: "90%",
                    fontSize: 12,
                    color: "#6B767F",
                    fontWeight: 400,
                    lineHeight: "17px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  We submit filings to the SEC, accessible at www.sec.gov. It is important to thoroughly examine the details provided in the offering documents for a specific investment opportunity prior to making an investment decision.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel9"}
              onChange={handleChange("panel9")}
              sx={{
                backgroundColor: "#fff",
                border:
                  expanded === "panel9"
                    ? "1px solid #ef32d9"
                    : "1px solid #E1E4ED",
                borderRadius: "6px !important",
                marginBottom: "12px",
                padding : '3px 0px',
                maxWidth : {lg :'533px'}
              }}
              className="tablesection"
            >
              <AccordionSummary
                expandIcon={
                  expanded === "panel9" ? (
                    <img src={minus} alt="minus"></img>
                  ) : (
                    <img src={plus} alt="plus"></img>
                  )
                }
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography
                  sx={{
                    width: "90%",
                    fontSize: 16,
                    color: expanded === "panel9" ? '#242424' :"#33383D",
                    fontWeight: expanded === "panel9" ? 600 : 500,
                    lineHeight: "20px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Who stores the assets that you sell shares in?
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ paddingTop: 0 }}>
                <Typography
                  sx={{
                    width: "90%",
                    fontSize: 12,
                    color: "#6B767F",
                    fontWeight: 400,
                    lineHeight: "17px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  The specifics vary with each offering. Information regarding storage, insurance, and safeguarding is comprehensively outlined on the detail page of each asset.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
       </Container>

      {/*------------------- Get In Touch section -------------------------*/}

      <Container maxWidth="xl">
        <Box
          sx={{
            backgroundColor: "#33383D",
            borderRadius: "16px",
            padding: { xs: '30px 22px', md:"64px 70px"},
            maxWidth : {xs: '358px' , md: '1140px'},
            margin : 'auto',
            display : 'flex',
            flexDirection : {xs: 'column' , md: 'row'},
            alignItems : {xs: 'start' , md : 'center'},
            justifyContent: "space-between",
            zIndex: 100,
            position: "relative",
            marginBottom: "-100px",
          }}
          className="tablesection"
        >
          <Box>
            <Typography
              sx={{
                fontSize:{xs : 25 , md: 30},
                color: "#fff",
                fontWeight: 600,
                lineHeight: {xs: '30px' , md: "38px"},
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Have any additional questions?
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                color: "#CBCFD2",
                fontWeight: 400,
                lineHeight: "24px",
                fontFamily: "Poppins, sans-serif",
                marginTop: "4px",
              }}
            >
              Get in touch with us to answer any other questions you have.
            </Typography>
          </Box>
          <Button
          onClick={() => navigate('/contact')}
            sx={{
              fontSize: 16,
              fontWeight: 600,
              lineHeight: "23px",
              fontFamily: "Inter, sans-serif",
              color: "#fff",
              backgroundColor: "#EF32D9",
              padding: "11px 42px",
              borderRadius: "6px",
              marginTop : {xs: '36px' , md: '0px'} 
            }}
            className="receivebutton"
          >
            Get in touch
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

export default Faq;
