import { Box, Button, Container, Grid, Typography } from "@mui/material";
import hero from "../assets/img/about/about_hero.png";
import about_offer from "../assets/img/about/about_offer.png";
import home_hero_arrow from "../assets/img/Home/home_hero_arrow.svg";
import member1 from "../assets/img/about/member-1.png";
import member2 from "../assets/img/about/member-2.png";
import member3 from "../assets/img/about/member-3.png";
import member4 from "../assets/img/about/member-4.png";
import member5 from "../assets/img/about/member-5.png";
import member6 from "../assets/img/about/member-6.png";
import member7 from "../assets/img/about/member-7.png";
import member8 from "../assets/img/about/member-8.png";
import member9 from "../assets/img/about/member-9.png";
import member10 from "../assets/img/about/member-10.png";
import member11 from "../assets/img/about/member-11.png";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Aboutus() {
    useEffect(() => {
     window.scrollTo({ top : 0 , behavior : 'smooth'})
    },[])

    const navigate = useNavigate();
  return (
    <Box>
      {/*------------------ Hero section -------------------------*/}

      <Box
        pt={{ xs: '41px' , md: "145px" }}
        pb={{ xs: '71px' , md: "145px" }}
        sx={{
          backgroundImage: `url(${hero})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          borderBottomLeftRadius: "32px",
          borderBottomRightRadius: "32px",
          marginTop: {xs : "65px" , sm: '100px'},
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            color: "#4F575E",
            fontWeight: 600,
            fontFamily: "Poppins, sans-serif",
            textAlign: "center",
            lineHeight: "15px",
            backgroundColor: "#fff",
            borderRadius: "36px",
            padding: "4px 12px",
            maxWidth: "120px",
            margin: {xs: "0px auto 21px" , md: "0px auto 36px"},
          }}
        >
          LEARN MORE
        </Typography>
        <Typography
          sx={{
            fontSize: {xs: "24px" , md: '48px'},
            color: "#fff",
            fontWeight: 600,
            fontFamily: "Poppins, sans-serif",
            textAlign: "center",
            lineHeight: {xs: '30px', md: "56px"},
          }}
        >
          About Us
        </Typography>
        <Typography
          sx={{
            fontSize:{xs: '12px' , md: "16px"},
            color: "#CBCFD2",
            fontWeight: 400,
            fontFamily: "Poppins, sans-serif",
            textAlign: "center",
            lineHeight: {xs: "18px" , md: '24px'},
            paddingTop: {xs: "14px" , md: '24px'},
            maxWidth: { xs : '320px', md:"580px"},
            margin: "auto",
          }}
        >
          In a digital age of limitless possibilities, we embarked on a quest to
          redefine the standard of luxury cars and artwork ownership.
        </Typography>
      </Box>

      {/*------------------- Offer section -----------------------*/}

      <Box pt={{xs: '56px' ,  md:"100px"}}  pb={{xs: '42px' ,  md:"100px"}}>
        <Container maxWidth="xl">
          <Grid container alignItems="center" columnSpacing={{ xl: "100px" }}>
            <Grid item lg={6} sx={{borderBottom : {xs: ' 1px solid #000' , md: 'none'} , paddingBottom : {xs: '33px' ,  md:"0px"}}}>
              <Typography
                sx={{
                  fontSize: 12,
                  color: "#F7F9FD",
                  fontWeight: 600,
                  lineHeight: "15px",
                  fontFamily: "Poppins, sans-serif",
                  backgroundColor: "#33383D",
                  borderRadius: "36px",
                  padding: "4px 0px",
                  maxWidth: 125,
                  marginBottom: { xs: '20px' , md:"36px"},
                  // letterSpacing: "1px",
                  textAlign: "center",
                }}
              >
                OUR OFFERING
              </Typography>
              <Typography
                sx={{
                  fontSize: {xs: 24 , md:30},
                  color: "#242424",
                  fontWeight: 600,
                  lineHeight: { xs: '30px' , md: "38px"},
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Giving people the ability to build generational wealth and high
                value asset portfolios through the power of fractionalization.
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: 14 , md:16},
                  color: "#4F575E",
                  fontWeight: 400,
                  lineHeight: { xs: '18px' , md:"24px"},
                  fontFamily: "Poppins, sans-serif",
                  marginTop: { xs: '12px !important' ,md:"24px !important"},
                  marginBottom: 0,
                  maxWidth: "550px",
                }}
              >
                Breaking into markets previously reserved for the wealthiest
                investors. Investing in fractional shares also relieves many of
                the individual burdens that typical ownership requires such as
                storage, appraisal, and concerns over counterfeits.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "center",
                  marginTop: {xs: '12px' , md: "27px"},
                }}
              >
                <Typography
                onClick={() => navigate('/sign-up')}
                  sx={{
                    fontSize: {xs: 14 , md: 20},
                    fontFamily: "Poppins, sans-serif !important",
                    lineHeight: "30px",
                    color: "#ef32d9",
                    fontWeight: 400,
                  }}
                >
                  Sign Up
                </Typography>

                <Box
                 component={'img'}
                  src={home_hero_arrow}
                  alt="Home Hero Arrow"
                  sx={{ width : {xs: '20px' , md: 'auto'} , marginLeft: {xs: '10px' , md:"14px"} }}
                />
              </Box>
            </Grid>
            <Grid item lg={6} sx={{ display: { xs: 'none' , md: "flex"} , marginTop : { md : '20px' , lg: '0px'} }}>
              <img
                src={about_offer}
                alt="img"
                style={{ marginLeft: "auto" }}
              ></img>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/*------------------- Team section -------------------------*/}

      <Container maxWidth="xl" sx={{ textAlign: {xs: 'left' , md:"center"}  }}>
      <Typography
          sx={{
            fontSize: 12,
            color: "#F7F9FD",
            fontWeight: 600,
            lineHeight: "15px",
            fontFamily: "Poppins, sans-serif",
            backgroundColor: "#33383D",
            borderRadius: "36px",
            padding: "4px 0px",
            maxWidth: 153,
            textAlign : 'center',
            margin: { xs: '0px auto 20px', md:"0px auto 36px"},
          }}
        >
        THE MCQUEEN TEAM
        </Typography>
        <Typography
          sx={{
            fontSize: {xs: 24 , md: 30},
            color: "#242424",
            fontWeight: 600,
            lineHeight: {xs: '30px' , md: "38px"},
            fontFamily: "Poppins, sans-serif",
          }}
        >
          Meet the uniting visionaries, building the new tomorrow
        </Typography>
        <Typography
          sx={{
            fontSize: {xs:14 , md:16},
            color: "#4F575E",
            fontWeight: 400,
            lineHeight: {xs: '18px' , md:"24px"},
            fontFamily: "Poppins, sans-serif",
            marginTop: {xs: '12px' , md: "24px"},
          }}
        >
          Behind every innovation lies a dedicated team sculpting the future of
          possibility
        </Typography>
        <Grid container alignItems={{ xs: "start" , md: 'center'}} columnSpacing={{xs : '20px' , md: '0px'}} pt={{xs: '48px' , md:"90px"}} pb={{ xs: '56px' , md: "100px"}}>
          <Grid item xs={6} md={3}>
            <img src={member1}></img>
            <Typography
              sx={{
                fontSize: 20,
                color: "#242424",
                fontWeight: 600,
                fontFamily: "Inter, sans-serif",
                marginTop: {xs: "5px" , md: '20px'},
              }}
            >
              Curt Hopkins
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                color: "#6B767F",
                fontWeight: 400,
                fontFamily: "Inter, sans-serif",
               marginTop: {xs: "5px" , md: '16px'},
              }}
            >
              CEO, McQueen
            </Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <img src={member2}></img>
            <Typography
              sx={{
                fontSize: 20,
                color: "#242424",
                fontWeight: 600,
                fontFamily: "Inter, sans-serif",
                marginTop: {xs: "5px" , md: '20px'},
              }}
            >
              Robbie Antonio
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                color: "#6B767F",
                fontWeight: 400,
                fontFamily: "Inter, sans-serif",
                marginTop: {xs: "5px" , md: '16px'},
              }}
            >
              CEO, Art Market
            </Typography>
          </Grid>
          <Grid item xs={6} md={3} pt={{ xs: "48px" , md: '0px'}}>
            <img src={member3}></img>
            <Typography
              sx={{
                fontSize: 20,
                color: "#242424",
                fontWeight: 600,
                fontFamily: "Inter, sans-serif",
                marginTop: {xs: "5px" , md: '20px'},
              }}
            >
              Paul Glavine
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                color: "#6B767F",
                fontWeight: 400,
                fontFamily: "Inter, sans-serif",
                marginTop: {xs: "5px" , md: '16px'},
              }}
            >
              Found, MP, Head of Markets
            </Typography>
          </Grid>
          <Grid item xs={6} md={3} pt={{xs: "48px" , md: '0px'}}>
            <img src={member4}></img>
            <Typography
              sx={{
                fontSize: 20,
                color: "#242424",
                fontWeight: 600,
                fontFamily: "Inter, sans-serif",
                marginTop: {xs: "5px" , md: '20px'},
              }}
            >
              Okan Altug
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                color: "#6B767F",
                fontWeight: 400,
                fontFamily: "Inter, sans-serif",
                marginTop: {xs: "5px" , md: '16px'},
              }}
            >
              Found, MP, Head of Buisness
            </Typography>
          </Grid>
          <Grid item xs={6} md={3} pt={{xs: "48px" , md: '60px'}}>
            <img src={member5}></img>
            <Typography
              sx={{
                fontSize: 20,
                color: "#242424",
                fontWeight: 600,
                fontFamily: "Inter, sans-serif",
                marginTop: {xs: "5px" , md: '20px'},
              }}
            >
              Lachlan DeFrancessco
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                color: "#6B767F",
                fontWeight: 400,
                fontFamily: "Inter, sans-serif",
                marginTop: {xs: "5px" , md: '16px'},
              }}
            >
              Co-Founder, Chief Cars
            </Typography>
          </Grid>
          <Grid item xs={6} md={3} pt={{xs: "48px" , md: '60px'}}>
            <img src={member6}></img>
            <Typography
              sx={{
                fontSize: 20,
                color: "#242424",
                fontWeight: 600,
                fontFamily: "Inter, sans-serif",
                marginTop: {xs: "5px" , md: '20px'},
              }}
            >
              Tansel Kaya
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                color: "#6B767F",
                fontWeight: 400,
                fontFamily: "Inter, sans-serif",
                marginTop: {xs: "5px" , md: '16px'},
              }}
            >
              Managing Partner, Technology
            </Typography>
          </Grid>
          <Grid item xs={6} md={3} pt={{xs: "48px" , md: '60px'}}>
            <img src={member7}></img>
            <Typography
              sx={{
                fontSize: 20,
                color: "#242424",
                fontWeight: 600,
                fontFamily: "Inter, sans-serif",
                marginTop: {xs: "5px" , md: '20px'},
              }}
            >
              Jonathan Held
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                color: "#6B767F",
                fontWeight: 400,
                fontFamily: "Inter, sans-serif",
                marginTop: {xs: "5px" , md: '16px'},
              }}
            >
              Managing Partner, Finance
            </Typography>
          </Grid>
          <Grid item xs={6} md={3} pt={{xs: "48px" , md: '60px'}}>
            <img src={member8}></img>
            <Typography
              sx={{
                fontSize: 20,
                color: "#242424",
                fontWeight: 600,
                fontFamily: "Inter, sans-serif",
                marginTop: {xs: "5px" , md: '20px'},
              }}
            >
              Shahrouz Hafez
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                color: "#6B767F",
                fontWeight: 400,
                fontFamily: "Inter, sans-serif",
                marginTop: {xs: "5px" , md: '16px'},
              }}
            >
              Director Business Dev.
            </Typography>
          </Grid>
          <Grid item xs={6} md={3} pt={{xs: "48px" , md: '60px'}}>
            <img src={member9}></img>
            <Typography
              sx={{
                fontSize: 20,
                color: "#242424",
                fontWeight: 600,
                fontFamily: "Inter, sans-serif",
                marginTop: {xs: "5px" , md: '20px'},
              }}
            >
              Sohiya Yotsukura
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                color: "#6B767F",
                fontWeight: 400,
                fontFamily: "Inter, sans-serif",
                marginTop: {xs: "5px" , md: '16px'},
              }}
            >
              Relationships Manager
            </Typography>
          </Grid>
          <Grid item xs={6} md={3} pt={{xs: "48px" , md: '60px'}}>
            <img src={member10}></img>
            <Typography
              sx={{
                fontSize: 20,
                color: "#242424",
                fontWeight: 600,
                fontFamily: "Inter, sans-serif",
                marginTop: {xs: "5px" , md: '20px'},
              }}
            >
              Benan Tumkaya
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                color: "#6B767F",
                fontWeight: 400,
                fontFamily: "Inter, sans-serif",
                marginTop: {xs: "5px" , md: '16px'},
              }}
            >
              Head of Technology
            </Typography>
          </Grid>
          <Grid item xs={6} md={3} pt={{xs: "48px" , md: '60px'}}>
            <img src={member11}></img>
            <Typography
              sx={{
                fontSize: 20,
                color: "#242424",
                fontWeight: 600,
                fontFamily: "Inter, sans-serif",
                marginTop: {xs: "5px" , md: '20px'},
              }}
            >
              Anthony Alexander
            </Typography>
            <Typography
              sx={{
                fontSize: 16,
                color: "#6B767F",
                fontWeight: 400,
                fontFamily: "Inter, sans-serif",
                marginTop: {xs: "5px" , md: '16px'},
              }}
            >
              Head of Brand & Marketing
            </Typography>
          </Grid>                          
        </Grid>
      </Container>

      {/*------------------- Get In Touch section -------------------------*/}

      <Container maxWidth="xl" >
        <Box
          sx={{
            backgroundColor: "#33383D",
            borderRadius: "16px",
            padding: { xs: '30px 22px', md:"64px 70px"},
            maxWidth : {xs: '358px' , md: '1140px'},
            margin : 'auto',
            display : 'flex',
            flexDirection : {xs: 'column' , md: 'row'},
            alignItems : {xs: 'start' , md : 'center'},
            justifyContent : 'space-between',
            zIndex : 100,
            position : 'relative',
            marginBottom : '-100px',
          }}
          className='tablesection'
        >
        <Box>
                  <Typography
            sx={{
              fontSize: 30,
              color: "#fff",
              fontWeight: 600,
              lineHeight: "38px",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            Join the Team
          </Typography>
          <Typography
            sx={{
              fontSize: 16,
              color: "#CBCFD2",
              fontWeight: 400,
              lineHeight: "24px",
              fontFamily: "Poppins, sans-serif",
              maxWidth : '650px',
              marginTop : '4px'
            }}
          >
            Our philosophy is simple — hire a team of diverse, passionate people and foster a culture that empowers you to do you best work.
          </Typography>  
        </Box>
        
        <Button  
          onClick={() => navigate('/contact')}
          sx={{
              fontSize: 16,
              fontWeight: 600,
              lineHeight: "23px",
              fontFamily: "Inter, sans-serif",
              color: "#fff",
              backgroundColor : '#EF32D9',
              padding : '11px 42px',
              borderRadius : '6px',
              marginTop : {xs: '36px' , md: '0px'} 
            }} className="receivebutton">Get in touch</Button>
            
        </Box>
      </Container>
    </Box>
  );
}

export default Aboutus;