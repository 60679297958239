import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";

//icons
import homeHeroBackground from "../assets/img/Home/hero_background.png";
import home_hero_arrow from "../assets/img/Home/home_hero_arrow.svg";
import header1 from "../assets/img/Home/header1.png";
import { useNavigate } from "react-router-dom";
import CarCard from "./Home/Product/Carcard";
import CarCardY from "./Home/Product/CarcardY";
import CarCardS from "./Home/Product/CarcardS";
import Rec from "../assets/img/Home/Rectangle 4404.svg";

const FeaturedComponent = () => {
  const navigate = useNavigate();
  return (
    <Box
      py={{ xs: 6 , lg: 25 }}
      sx={{
        backgroundImage: `url(${homeHeroBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        borderBottomLeftRadius: "32px",
        borderBottomRightRadius: "32px",
        marginTop: {xs : "65px" , sm: '100px'},
      }}
    >
      <Container maxWidth="xl">
        <Grid container columnSpacing={3} alignItems="center">
          <Grid item md={7} lg={8} sm={12} xs={12}>
            <Stack sx={{ maxWidth: "695px" }} spacing={3}>
              <Typography
                sx={{
                  backgroundColor: "white",
                  fontSize: "12px",
                  color: "#4F575E",
                  maxWidth: "157px",
                  fontWeight: 600,
                  fontFamily: "Poppins, sans-serif !important",
                  textAlign: "center",
                  borderRadius: "36px",
                  paddingTop: "3px",
                  paddingBottom: "3px",
                }}
              >
                THE INDUSTRY SHIFT
              </Typography>
              <Typography
                sx={{
                  color: "white",
                  fontWeight: 600,
                  fontSize: { xs: '24px' ,md: '35px' ,  lg: "48px"},
                  lineHeight: { xs: '30px' ,md: '40px' ,  lg: "56px"},
                  fontFamily: "Poppins, sans-serif !important",
                  marginTop : {xs : '20px !important' , md: '24px !important'}
                }}
              >
                Redefining the landscape of{" "}
                <span style={{ color: "#ef32d9" }}>
                  luxury cars and artwork,
                </span>{" "}
                forever altering the industry
              </Typography>

              <Typography
                sx={{
                  color: "#CBCFD2",
                  lineHeight: { xs: '18px' , md: " 24px"},
                  fontSize: { xs: '14px' , md: "16px"},
                  fontFamily: "Poppins, sans-serif !important",
                  marginTop : {xs : '12px !important' , md: '24px !important'}
                }}
              >
                We believe that people should have the ability to build
                generational wealth and high value asset portfolios through
                fractionalization. With our SEC approved offerings through
                fractionalized investments.
              </Typography>

              <Stack direction="row" sx={{ marginTop: {xs: '20px !important' , md: "32px !important"} }}>
                <Box sx={{ display: "flex", cursor: "pointer" , alignItems : 'center' }}>
                  <Typography
                    sx={{
                      fontSize: { xs: '12px' , md: "18px"},
                      fontFamily: "Poppins, sans-serif !important",
                      lineHeight: { xs: '14px' , md: "27px"},
                      color: "#ef32d9",
                      fontWeight: 400,
                    }}
                    onClick={() => navigate("/market")}
                  >
                    View Assets Now
                  </Typography>

                  <Box
                    component={"img"}
                    src={home_hero_arrow}
                    alt="Home Hero Arrow"
                    sx={{ marginLeft: {xs: "8px" , md: '15px'} , width : {xs: '15px' , md: 'auto'} }}
                    onClick={() => navigate("/market")}
                  />
                </Box>
              </Stack>
            </Stack>
          </Grid>

          {/*=========== hero section card =============*/}

          <Grid item md={5} lg={4} sm={12} xs={12} sx={{ position: "relative" ,display : {xs: 'none' , md: 'block'}, }}>
            <Box
              src={Rec}
              component={'img'}
              alt="Header1"
              sx={{
                width:  '100%',  
                height: "auto",
              }}
            ></Box>
            {/* <Box
              sx={{
                position: "absolute",
                top: "-140px",
                zIndex: 1,
                right: "0px",
                padding: "7px",
                backgroundColor: "white",
                maxWidth: "424px",
                borderRadius: "16px",
                filter: "blur(1px)",
              }}
            >
              <CarCardY />
            </Box> */}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FeaturedComponent;
