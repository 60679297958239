import { Box } from "@mui/material";
import VerifyComponent from "../components/VerifyComponent";

const Verify = () => {
  return (
    <Box>
      <VerifyComponent />
    </Box>
  );
};

export default Verify;
