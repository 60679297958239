import { Box } from "@mui/material";
import VerifyEmailComponent from "../components/VerifyEmailComponent";

const VerifyEmail = () => {
  return (
    <Box>
      <VerifyEmailComponent />
    </Box>
  );
};

export default VerifyEmail;
