import React from 'react'
import { Box, Button, Card, Container, Grid, Input, Stack, TextField, Typography } from "@mui/material";
import arrow from "../assets/img/cardlistdetail/card_detail_arrow.svg"
import car_1 from "../assets/img/cardlistdetail/car_1.svg"
import car_2 from "../assets/img/cardlistdetail/car_2.svg"
import car_3 from "../assets/img/cardlistdetail/car_3.svg"
import car_4 from "../assets/img/cardlistdetail/car_4.svg"
import { Height } from '@mui/icons-material';
import CardDetailComponant from '../components/carddetail/CardDetailComponant';




const CardListDetail = () => {
  return (
    <Box>
        <Container maxWidth="xl">
            <Box sx={{display: "flex", alignItems:"center",cursor:"pointer", marginTop: "45px", marginBottom: "40px", width: "83px"}}>
                <img src={arrow} alt='arrow'></img>
                <Typography sx={{ color: "#6B767F", fontWeight: 400, fontSize: "18px", fontFamily: "Poppins, sans-serif !important",marginLeft:"15px", letterSpacing: "0.14em",}}>BACK</Typography>
            </Box>
            <Grid  container columnSpacing={"43px"} sx={{marginTop:"58px"}}>
               <Grid item md={6}>
               <Typography sx={{ color: "#A7AEB4", fontWeight: 400, fontSize: "12px", fontFamily: "Poppins, sans-serif !important"}}>Automotive: Super Car / Mercedes SLS AMG Black Series</Typography>
               <Box sx={{position:"relative", marginTop: "18px"}}>
                    <img src={car_1} alt='car' style={{}}></img>
                    <Box sx={{position: "absolute", top: "20px", left:"20px", zIndex: 1}}>
                        <Box style={{height: "50px", width: "50px", border: "1px solid #F7F9FD", borderRadius: "6px"}}>
                            <img src={car_2} alt='car' style={{height: "100%", width: "100%", objectFit:"contain"}}></img>
                        </Box>
                        <Box style={{height: "50px", width: "50px", border: "1px solid #F7F9FD", borderRadius: "6px", marginTop: "6px"}}>
                            <img src={car_3} alt='car' style={{height: "100%", width: "100%", objectFit:"contain"}}></img>
                        </Box>
                        <Box style={{height: "50px", width: "50px", border: "1px solid #F7F9FD", borderRadius: "6px", marginTop: "6px"}}>
                            <img src={car_4} alt='car' style={{height: "100%", width: "100%", objectFit:"contain"}}></img>
                        </Box>
                        
                    </Box>
               </Box>

               </Grid>
               <Grid item md={6} sx={{marginTop:"28px"}}>
                   <Box>
                        <CardDetailComponant/>
                   </Box>
               </Grid>
            </Grid>
            
        </Container>
      
    </Box>
  )
}

export default CardListDetail
