import { FormEvent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Link,
  TextField,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import SignInImg from "../assets/img/sign-in.png";
import GoogleIcon from "../assets/img/google.svg";
import lock from "../assets/img/lock.svg";

import snackbar from "../config/snackbar";
import APIContext from "../context/ApiContext";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { dispatch } from "../store";
import { Login } from "../store/reducers/auth";
import cookiearrow from "../assets/img/cookie_arrow.svg";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 40,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 1,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#EF32D9" : "#EF32D9",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "8px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 21,
    height: 21,
  },
  "& .MuiSwitch-track": {
    borderRadius: 360,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#e3e1ea",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const CookieModal = () => {
  const navigate = useNavigate();

  const useSessionModal = () => {
    const session = "test";
    const [showModal, setShowModal] = useState<any>(false);
    const hideModal = () => {
      const modalKey = "modalSession";
      localStorage.setItem(modalKey, session);
      setShowModal(false);
    };
    useEffect(() => {
      const modalKey = "modalSession";
      const modalSession = localStorage.getItem(modalKey);
      setShowModal(modalSession !== session);
    });
    return [showModal, hideModal];
  };

  const [showModal, hideModal] = useSessionModal();

  const cookiemodalstyle = {
    "& .MuiPaper-root": {
      background: "#F7F9FD !important",
      borderRadius: " 6px !important",
      maxWidth: "1140px !important",
      padding: { xs: "0px !important", md: "20px !important" },
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "transparent",
    },
    "& .MuiDialog-container": {
      alignItems: "flex-end",
    },
  };

  return (
    <Dialog
      open={showModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      sx={cookiemodalstyle}
    >
      <DialogContent
        sx={{
          display: { lg: "flex" },
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography
            sx={{
              paddingBottom: "12px",
              color: "#556987",
              fontSize: { lg: 19 },
              fontWeight: 400,
              lineHeight: "24px",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            We use third-party cookies in order to personalize your experience
          </Typography>
          <Typography
            sx={{
              color: "#33383D",
              fontSize: 16,
              fontWeight: 500,
              lineHeight: "24px",
              fontFamily: "Poppins, sans-serif",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => navigate("/cookie-policy")}
          >
            Read our cookie policy
            <img
              src={cookiearrow}
              style={{ marginLeft: "10px" }}
              alt="cookiearrow"
            ></img>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: { xs: "20px", lg: "0px" },
          }}
        >
          <Button
            sx={{
              color: "#333F51",
              fontSize: 16,
              fontWeight: 500,
              lineHeight: "24px",
              fontFamily: "Poppins, sans-serif",
              border: "1px solid #D5DAE1",
              borderRadius: "6px",
              padding: "11px 18px",
            }}
            onClick={hideModal}
          >
            Decline
          </Button>
          <Button
            sx={{
              color: "#F0FDF4",
              fontSize: 16,
              fontWeight: 500,
              backgroundColor: "#EF32D9",
              lineHeight: "24px",
              fontFamily: "Poppins, sans-serif",
              borderRadius: "6px",
              padding: "11px 26px",
              marginLeft: "15px",
            }}
            className="accountbutton"
            onClick={hideModal}
          >
            Allow
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CookieModal;
