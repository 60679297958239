export const BASE_URL = "https://mcqueen-9c56671066ea.herokuapp.com/";
export const BACKEND = "https://mcqueen-9c56671066ea.herokuapp.com/api";

export interface Data {
  date: string;
  invesment: string;
  offer: string;
  price: number;
  share: number;
  paymentType: string;
  status: string;
  text: string;
  buttondis: Boolean;
}

export interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

export const States = [
  {
    id: 1,
    name: "Alabama",
    symbol: "AL",
  },
  {
    id: 2,
    name: "Alaska",
    symbol: "AK",
  },
  {
    id: 3,
    name: "Arizona",
    symbol: "AZ",
  },
  {
    id: 4,
    name: "Arkansas",
    symbol: "AR",
  },
  {
    id: 5,
    name: "California",
    symbol: "CA",
  },
  {
    id: 6,
    name: "Colorado",
    symbol: "CO",
  },
  {
    id: 7,
    name: "Connecticut",
    symbol: "CT",
  },
  {
    id: 8,
    name: "Delaware",
    symbol: "DE",
  },
  {
    id: 9,
    name: "Florida",
    symbol: "FL",
  },
  {
    id: 10,
    name: "Georgia",
    symbol: "GA",
  },
  {
    id: 11,
    name: "Hawaii",
    symbol: "HI",
  },
  {
    id: 12,
    name: "Idaho",
    symbol: "ID",
  },
  {
    id: 13,
    name: "Illinois",
    symbol: "IL",
  },
  {
    id: 14,
    name: "Indiana",
    symbol: "IN",
  },
  {
    id: 15,
    name: "Iowa",
    symbol: "IA",
  },
  {
    id: 16,
    name: "Kansas",
    symbol: "KS",
  },
  {
    id: 17,
    name: "Kentucky",
    symbol: "KY",
  },
  {
    id: 18,
    name: "Louisiana",
    symbol: "LA",
  },
  {
    id: 19,
    name: "Maine",
    symbol: "ME",
  },
  {
    id: 20,
    name: "Maryland",
    symbol: "MD",
  },
  {
    id: 21,
    name: "Massachusetts",
    symbol: "MA",
  },
  {
    id: 22,
    name: "Michigan",
    symbol: "MI",
  },
  {
    id: 23,
    name: "Minnesota",
    symbol: "MN",
  },
  {
    id: 24,
    name: "Mississippi",
    symbol: "MS",
  },
  {
    id: 25,
    name: "Missouri",
    symbol: "MO",
  },
  {
    id: 26,
    name: "Montana",
    symbol: "MT",
  },
  {
    id: 27,
    name: "Nebraska",
    symbol: "NE",
  },
  {
    id: 28,
    name: "Nevada",
    symbol: "NV",
  },
  {
    id: 29,
    name: "New Hampshire",
    symbol: "NH",
  },
  {
    id: 30,
    name: "New Jersey",
    symbol: "NJ",
  },
  {
    id: 31,
    name: "New Mexico",
    symbol: "NM",
  },
  {
    id: 32,
    name: "New York",
    symbol: "NY",
  },
  {
    id: 33,
    name: "North Carolina",
    symbol: "NC",
  },
  {
    id: 34,
    name: "North Dakota",
    symbol: "ND",
  },
  {
    id: 35,
    name: "Ohio",
    symbol: "OH",
  },
  {
    id: 36,
    name: "Oklahoma",
    symbol: "OK",
  },
  {
    id: 37,
    name: "Oregon",
    symbol: "OR",
  },
  {
    id: 38,
    name: "Pennsylvania",
    symbol: "PA",
  },
  {
    id: 39,
    name: "Rhode Island",
    symbol: "RI",
  },
  {
    id: 40,
    name: "South Carolina",
    symbol: "SC",
  },
  {
    id: 41,
    name: "South Dakota",
    symbol: "SD",
  },
  {
    id: 42,
    name: "Tennessee",
    symbol: "TN",
  },
  {
    id: 43,
    name: "Texas",
    symbol: "TX",
  },
  {
    id: 44,
    name: "Utah",
    symbol: "UT",
  },
  {
    id: 45,
    name: "Vermont",
    symbol: "VT",
  },
  {
    id: 46,
    name: "Virginia",
    symbol: "VA",
  },
  {
    id: 47,
    name: "Washington",
    symbol: "WA",
  },
  {
    id: 48,
    name: "West Virginia",
    symbol: "WV",
  },
  {
    id: 49,
    name: "Wisconsin",
    symbol: "WI",
  },
  {
    id: 50,
    name: "Wyoming",
    symbol: "WY",
  },
];

// export const headCells: readonly HeadCell[] = [
//   {
//     id: "date",
//     numeric: false,
//     disablePadding: false,
//     label: "Date",
//   },
//   {
//     id: "offerings",
//     numeric: true,
//     disablePadding: false,
//     label: "Offerings",
//   },
//   {
//     id: "sharePrice",
//     numeric: true,
//     disablePadding: false,
//     label: "Price Per Share",
//   },
//   {
//     id: "shares",
//     numeric: true,
//     disablePadding: false,
//     label: "Shares",
//   },
//   {
//     id: "paymentType",
//     numeric: true,
//     disablePadding: false,
//     label: "Payment Type",
//   },

//   {
//     id: "status",
//     numeric: true,
//     disablePadding: false,
//     label: "Status",
//   },
// ];

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export type Order = "asc" | "desc";

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
}

export function createData(
  date: string,
  invesment: string,
  offer: string,
  price: number,
  share: number,
  paymentType: string,
  status: string,
  text: string,
  buttondis: Boolean
): Data {
  return {
    date,
    invesment,
    offer,
    price,
    share,
    paymentType,
    status,
    text,
    buttondis,
  };
}

// export function createData(
//   name: string,
//   invesment: string,
//   offer: string,
//   price: string,
//   share: string,
//   text: string,
//   text1: string,
//   text2: string,
//   buttondis: Boolean
// ) {
//   return {
//     name,
//     invesment,
//     offer,
//     price,
//     share,
//     text,
//     text1,
//     text2,
//     buttondis,
//   };
// }
