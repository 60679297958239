import {
  Box,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import CarCard from "./Product/Carcard";
import CarCardY from "./Product/CarcardY";
import CarCardS from "./Product/CarcardS";
import star from "../../assets/img/Home/Star.png";
import Cell from "../../assets/img/Home/CellSignalFull.svg";
import Certificate from "../../assets/img/Home/Certificate.svg";
import category_arrow from "../../assets/img/Home/category_arrow.png";
import search_icon from "../../assets/img/Home/search_icon.png";

const ProductComponent = () => {
  const style = {
    "& label.Mui-focused": {
      color: "#626264 !important",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "none !important",
    },
    "& .MuiInputLabel-root": {
      color: "#626264 !important",
    },
    "& .MuiOutlinedInput-input": {
      color: "#626264 !important",
      backgroundColor: "#f7f7f7 !important",
      maxHeight: "26px !important",
      padding: "7px 0px 7px 30px !important",
      borderRadius: "6px !important",
      fontSize: "12px !important",
      width: "210px !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "6px !important",
        color: "#626264 !important",
        fontSize: "12px !important",
        padding: "0px !important",
      },
      "&:hover fieldset": {
        borderColor: "none !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: "none !important",
      },
    },
  };
  return (
    <Box pb={{ xs: "39px", md: "92px" }} pt={{ xs: "39px", md: "96px" }}>
      <Container maxWidth="xl">
        <Typography
          sx={{
            fontSize: 12,
            color: "#F7F9FD",
            fontWeight: 600,
            lineHeight: "15px",
            fontFamily: "Poppins, sans-serif",
            backgroundColor: "#33383D",
            borderRadius: "36px",
            padding: "4px 0px",
            maxWidth: 143,
            marginBottom: { xs: "20px", md: "35px" },
            // letterSpacing: "1px",
            textAlign: "center",
          }}
        >
          SEIZE THE MOMENT
        </Typography>
        <Grid container alignItems={"end"} pb={{ xs: "33px", md: " 48px" }}>
          <Grid item md={6} lg={6}>
            <Typography
              sx={{
                fontSize: { xs: 24, md: 29 },
                color: "#242424",
                fontWeight: 600,
                lineHeight: { xs: "30px", md: "37px" },
                fontFamily: "Poppins, sans-serif",
                maxWidth: "700px",
              }}
            >
              Invest in fractionalized luxury cars and artwork today for a
              wealthier tomorrow
            </Typography>
          </Grid>

          <Grid item xs={6} sx={{ display: { xs: "none", md: "block" } }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Typography
                sx={{
                  fontSize: 11,
                  color: "#242424",
                  fontWeight: 600,
                  lineHeight: "16px",
                  fontFamily: "Poppins, sans-serif",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Featured{" "}
                <span style={{ display: "flex", paddingLeft: "5px" }}>
                  <img src={star} alt="star"></img>
                </span>
              </Typography>
              <Typography
                sx={{
                  fontSize: 11,
                  color: "#6B767F",
                  fontWeight: 400,
                  lineHeight: "16px",
                  fontFamily: "Poppins, sans-serif",
                  display: "flex",
                  alignItems: "center",
                  padding: "0px 9px",
                }}
              >
                Top Performing{" "}
                <span style={{ display: "flex", paddingLeft: "5px" }}>
                  <img src={Cell} alt="cell"></img>
                </span>
              </Typography>
              <Typography
                sx={{
                  fontSize: 11,
                  color: "#6B767F",
                  fontWeight: 400,
                  lineHeight: "16px",
                  fontFamily: "Poppins, sans-serif",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Rare Assets{" "}
                <span style={{ display: "flex", paddingLeft: "5px" }}>
                  <img src={Certificate} alt="certificate"></img>
                </span>
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* search box section */}

        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "15px",
          }}
        >
          <TextField
            sx={style}
            placeholder="Search by Asset or Category"
          ></TextField>
          <Box component={'img'} src={search_icon} alt="search" sx={{position : 'absolute' , left : {xs: '25px' , md : '35px'}}}></Box>
          <Typography
            sx={{
              fontSize: 12,
              color: "#182135",
              fontWeight: 400,
              lineHeight: "15px",
              fontFamily: "Poppins, sans-serif",
              display: "flex",
            }}
          >
            Category
            <img src={category_arrow} alt="category"></img>
          </Typography>
        </Box>

        {/* product section  */}

        <Grid container columnSpacing={{ xs: "0px", sm: "26px" }}>
          <Grid item xs={12} sm={6} lg={4} sx={{ marginBottom: { xs: "17px", lg: "0px" } }}>
            <CarCardY />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} sx={{ marginBottom: { xs: "17px", lg: "0px" } }}>
            <CarCardS />
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <CarCard />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ProductComponent;
