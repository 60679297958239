import axios from "axios";
import { BASE_URL } from "../config";
import snackbar from "../config/snackbar";

import { store } from "../store";
import { Logout } from "../store/reducers/auth";

const axiosServices = axios.create();

axiosServices.interceptors.request.use(
  (config: any) => {
    config.baseURL = BASE_URL;
    const state = store.getState() as any;
    const accessToken = state.auth.token;
    if (accessToken) {
      config.headers.authorization = accessToken;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    if (response && response.status === 400) {
      snackbar(response.data.message, "error");
      // store.dispatch(Logout({} as any));
    } else if (response && response.status === 401) {
      snackbar(response.data.message, "error");
      store.dispatch(Logout({} as any));
    } else if (response && response.status === 413) {
      store.dispatch(Logout({} as any));
      snackbar(response.data.message, "error");
    } else if (response && response.status === 429) {
      store.dispatch(Logout({} as any));
      snackbar(response.data.message, "error");
    } else if (response && response.status === 500) {
      // store.dispatch(Logout({} as any));
      snackbar(response.data.message, "error");
    } else {
    }
    return Promise.reject(error);
  }
);

export default axiosServices;
