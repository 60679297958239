import { FormEvent, createRef, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Link,
  TextField,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogContentText,
  Stack,
} from "@mui/material";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import SignInImg from "../assets/img/sign-in.png";
import GoogleIcon from "../assets/img/google.svg";
import lock from "../assets/img/lock.svg";

import snackbar from "../config/snackbar";
import APIContext from "../context/ApiContext";
import ReCAPTCHA from "react-google-recaptcha";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { dispatch } from "../store";
import { Login } from "../store/reducers/auth";
import cookiearrow from "../assets/img/cookie_arrow.svg";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 40,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 1,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#EF32D9" : "#EF32D9",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "8px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 21,
    height: 21,
  },
  "& .MuiSwitch-track": {
    borderRadius: 360,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#e3e1ea",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const SignUpComponent = () => {
  const navigate = useNavigate();
  const { register, loading, login, sendEmailVerification } =
    useContext(APIContext);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };
  const recaptchaRef = createRef();
  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [isPassValid, setIsPassValid] = useState(true);

  const [Lastname, setLastname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [isEmailValid, setIsEmailValid] = useState(true);

  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [formsection, setformsection] = useState<Boolean>(false);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleResgister = async () => {
    if (!password || password !== confirmPassword) {
      snackbar("Invaild password", "error");
      return;
    }

    // setLoading(true);

    try {
      const { data } = await register({
        username: name + "  " + Lastname,
        email,
        password,
      });

      await sendEmailVerification(email);

      if (data.success) {
        const { data } = await login({ email, password });
        if (data.success) {
          const userData = {
            status: true,
            user: { email: email },
            session: data.response,
          };

          dispatch(Login(userData));

          navigate("/market");
        } else {
          snackbar(data.response, "error");
        }
      } else {
        snackbar(data.response, "error");
      }
    } catch (error: any) {
      snackbar(error.response.data.message, "error");
    }
    // setLoading(false);
  };

  const signup = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      handleClickOpen();

      axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then(async (res) => {
          try {
            const { data } = await register({
              username: res.data.name,
              email: res.data.email,
              password: `${res.data.email}qwert1234`,
            });

            if (data.success) {
              //snackbar("Successfully sign up.");
              const { data } = await login({
                email: res.data.email,
                password: `${res.data.email}qwert1234`,
              });
              if (data.success) {
                //snackbar("Login successfull.");
                const userData = {
                  status: true,
                  user: { email: email },
                  session: data.response,
                };

                dispatch(Login(userData));

                navigate("/profile");
              } else {
                snackbar(data.response, "error");
              }
            } else {
              snackbar(data.response, "error");
            }
          } catch (error: any) {
            snackbar(error.response.data.message, "error");
          }
          handleClose();
        });
    },
  });

  const onsubmit = async (e: any) => {
    e.preventDefault();

    handleClickOpen();
    const res = await handleResgister();
    handleClose();
  };

  const style = {
    "& label.Mui-focused": {
      color: "#232632 !important",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#232632 !important",
    },
    "& .MuiInputLabel-root": {
      color: "#232632 !important",
    },
    "& .MuiOutlinedInput-input": {
      color: "#232632 !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#232632 !important",
        border: "2px solid #232632 !important",
        borderRadius: "10px !important",
      },
      "&:hover fieldset": {
        borderColor: "#232632 !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#232632 !important",
      },
    },
  };

  const useSessionModal = () => {
    const session = "test";
    const [showModal, setShowModal] = useState<any>(false);
    const hideModal = () => {
      const modalKey = "modalSession";
      localStorage.setItem(modalKey, session);
      setShowModal(false);
    };
    useEffect(() => {
      const modalKey = "modalSession";
      const modalSession = localStorage.getItem(modalKey);
      setShowModal(modalSession !== session);
    });
    return [showModal, hideModal];
  };

  const [showModal, hideModal] = useSessionModal();

  const cookiemodalstyle = {
    "& .MuiPaper-root": {
      background: "#F7F9FD !important",
      borderRadius: " 6px !important",
      maxWidth: "1140px !important",
      padding: { xs: "0px !important", md: "20px !important" },
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "transparent",
    },
    "& .MuiDialog-container": {
      alignItems: "flex-end",
    },
  };

  const changeCapcha = (value: string) => {
    console.log("Captcha value:", value);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    const isValid = /^[a-zA-Z\s-]+$/.test(newName);
    const isLengthValid = newName.length <= 25;

    setName(newName);
    setNameError(!isValid || !isLengthValid);
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    const isValid = /^[a-zA-Z\s-]+$/.test(newName);
    const isLengthValid = newName.length <= 25;

    setLastname(newName);
    setLastNameError(!isValid || !isLengthValid);
  };

  const validateEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setEmail(email);
    setIsEmailValid(emailPattern.test(email));
  };

  const validatePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pass: string = event.target.value;
    const hasMinLength = pass.length >= 8;
    const hasUpperCase = /[A-Z]/.test(pass);
    const hasLowerCase = /[a-z]/.test(pass);
    const hasNumber = /\d/.test(pass);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(pass);

    const isPasswordValid =
      hasMinLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber &&
      hasSpecialChar;

    setIsValid(isPasswordValid);
    setPassword(pass);
  };

  const validateConfirmPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const pass: string = event.target.value;
    // const hasMinLength = pass.length >= 8;
    // const hasUpperCase = /[A-Z]/.test(pass);
    // const hasLowerCase = /[a-z]/.test(pass);
    // const hasNumber = /\d/.test(pass);
    // const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(pass);

    const isPasswordValid =
      // hasMinLength &&
      // hasUpperCase &&
      // hasLowerCase &&
      // hasNumber &&
      // hasSpecialChar &&
      pass == password;

    setIsPassValid(isPasswordValid);
    setConfirmPassword(pass);
  };

  return (
    <Box py={"100px"} sx={{ marginTop: { md: "110px" } }}>
      <Container maxWidth="xl">
        <Grid container>
          <Grid
            item
            md={6}
            xs={12}
            sm={12}
            sx={{ borderRight: { md: "1px solid #CBCFD2" } }}
            pt={formsection ? "" : "63px"}
            pb={formsection ? "" : { md: "63px" }}
          >
            <Box
              sx={{ maxWidth: "458px", textAlign: "center", margin: "auto" }}
            >
              {formsection ? (
                <Box>
                  <Typography
                    sx={{
                      fontSize: { xs: 30, md: 40 },
                      color: "#232632",
                      fontWeight: 600,
                      lineHeight: "48px",
                      fontFamily: "Poppins, sans-serif",
                      paddingBottom: "32px",
                    }}
                  >
                    Create Account
                  </Typography>
                  <form onSubmit={onsubmit}>
                    <TextField
                      id="outlined-basic"
                      label="Name"
                      variant="outlined"
                      value={name}
                      onChange={handleNameChange}
                      sx={style}
                      fullWidth
                      inputProps={{
                        style: { color: "#191B23" },
                      }}
                      autoComplete="off"
                      error={nameError}
                      helperText={
                        nameError
                          ? "Invalid format. Only letters and certain characters (max 25)."
                          : ""
                      }
                    />
                    <TextField
                      id="outlined-basic"
                      label="Last Name"
                      variant="outlined"
                      value={Lastname}
                      onChange={handleLastNameChange}
                      sx={{ ...style, marginTop: "16px" }}
                      fullWidth
                      inputProps={{
                        style: { color: "#191B23" },
                      }}
                      autoComplete="off"
                      error={lastNameError}
                      helperText={
                        lastNameError
                          ? "Invalid format. Only letters and certain characters (max 25)."
                          : ""
                      }
                    />
                    <TextField
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                      value={email}
                      onChange={(e) => validateEmail(e.target.value)}
                      sx={style}
                      inputProps={{
                        style: { color: "#191B23" },
                      }}
                      fullWidth
                      type="email"
                      className="registerfield"
                      autoComplete="off"
                      error={!isEmailValid}
                      helperText={!isEmailValid ? "Email invalid." : ""}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Password"
                      variant="outlined"
                      value={password}
                      onChange={validatePassword}
                      fullWidth
                      autoComplete="off"
                      sx={{ ...style, marginBottom: "16px" }}
                      type={showPassword ? "text" : "password"}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleTogglePasswordVisibility}
                              edge="end"
                              style={{ color: "black" }}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={!isValid}
                      helperText={
                        !isValid
                          ? "Minimum 8 letters, included number, upper and lower case , special letter."
                          : ""
                      }
                    />
                    <TextField
                      id="outlined-basic"
                      label="Confirm Password"
                      variant="outlined"
                      value={confirmPassword}
                      onChange={validateConfirmPassword}
                      fullWidth
                      sx={{ ...style, marginBottom: "16px" }}
                      autoComplete="off"
                      type={showConfirmPassword ? "text" : "password"}
                      // InputProps={{
                      //   endAdornment: (
                      //     <InputAdornment position="end">
                      //       <IconButton
                      //         onClick={handleToggleConfirmPasswordVisibility}
                      //         edge="end"
                      //         style={{ color: "black" }}
                      //       >
                      //         {showConfirmPassword ? (
                      //           <VisibilityOff />
                      //         ) : (
                      //           <Visibility />
                      //         )}
                      //       </IconButton>
                      //     </InputAdornment>
                      //   ),
                      // }}
                      error={!isPassValid}
                      helperText={!isPassValid ? "Passwords do not match" : ""}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <FormControlLabel control={<IOSSwitch />} label="" />
                      <Typography
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                          color: "#828282",
                          fontWeight: 400,
                          lineHeight: "22px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        I would like to receive the latest offers and updates
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                        marginBottom: "32px",
                      }}
                    >
                      <FormControlLabel
                        control={<IOSSwitch defaultChecked />}
                        label=""
                      />
                      <Typography
                        sx={{
                          fontSize: { xs: 12, md: 14 },
                          color: "#828282",
                          fontWeight: 400,
                          lineHeight: "22px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        I accept the terms and privacy policy
                      </Typography>
                    </Box>
                    <Stack pb={5}>
                      <ReCAPTCHA
                        style={{ width: "100%" }}
                        sitekey="6Lf8C-spAAAAACvv9EZlMOCqdP34_Lg6X-DDkhnA"
                        onChange={(e) => changeCapcha(e as any)}
                      />
                    </Stack>

                    <Button
                      sx={{
                        fontSize: { xs: 15, md: 20 },
                        color: "#fff",
                        fontWeight: 400,
                        lineHeight: { xs: "24px", md: "32px" },
                        backgroundColor: "#33383D",
                        borderRadius: "6px",
                        padding: { xs: "10px 0px", md: "12px 0px" },
                        width: "100%",
                      }}
                      className="accountbutton"
                      type="submit"
                      disabled={
                        password &&
                        email &&
                        name &&
                        Lastname &&
                        isValid &&
                        isEmailValid &&
                        isPassValid &&
                        !nameError &&
                        !lastNameError
                          ? false
                          : true
                      }
                    >
                      Create Account
                    </Button>
                  </form>
                  <Typography
                    sx={{
                      color: "#828282",
                      paddingTop: { xs: "10px", md: "10px" },
                      fontSize: 14,
                      fontWeight: 400,
                      lineHeight: "22px",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Already have an account?{" "}
                    <Link
                      // href="/sign-in"
                      onClick={() => setformsection(false)}
                      sx={{
                        color: "#ef32d9",
                        textDecoration: "none",
                        cursor: "pointer",
                      }}
                    >
                      Log in
                    </Link>
                  </Typography>
                </Box>
              ) : (
                <Box>
                  <Typography
                    sx={{
                      fontSize: { xs: 30, md: 40 },
                      color: "#232632",
                      fontWeight: 600,
                      lineHeight: { xs: "37px", md: "48px" },
                      fontFamily: "Poppins, sans-serif",
                      paddingBottom: "16px",
                    }}
                  >
                    Welcome to McQueen
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: 15, md: 20 },
                      color: "#242424",
                      fontWeight: 600,
                      lineHeight: { xs: "23px", md: "30px" },
                      fontFamily: "Poppins, sans-serif",
                      paddingBottom: { xs: "41px", md: "54px" },
                    }}
                  >
                    A marketplace for the future of luxury asset investments,
                    discover, invest, and grow.
                  </Typography>
                  <Button
                    sx={{
                      fontSize: { xs: 15, md: 20 },
                      color: "#fff",
                      fontWeight: 400,
                      lineHeight: { xs: "24px", md: "32px" },
                      backgroundColor: "#33383D",
                      borderRadius: "6px",
                      padding: { xs: "10px 0px", md: "12px 0px" },
                      width: "100%",
                    }}
                    className="accountbutton"
                    onClick={() => setformsection(true)}
                  >
                    Create Account
                  </Button>
                  <Typography
                    sx={{
                      fontSize: 16,
                      color: "#232632",
                      fontWeight: 400,
                      lineHeight: "26px",
                      fontFamily: "Poppins, sans-serif",
                      margin: { xs: "30px 0px", md: "40px 0px" },
                    }}
                    className="continue_heading"
                  >
                    or continue with
                  </Typography>
                  <Grid container spacing={"16px"}>
                    <Grid item xs={12}>
                      <Button
                        sx={{
                          fontSize: { xs: 15, md: 20 },
                          color: "#232632",
                          fontWeight: 400,
                          lineHeight: { xs: "24px", md: "32px" },
                          fontFamily: "Poppins, sans-serif",
                          padding: { xs: "10px 0px", md: "12px 0px" },
                          backgroundColor: "#f9f6ff",
                          borderRadius: "6px",
                          width: "100%",
                        }}
                        className="googlebutton"
                        onClick={() => signup()}
                      >
                        <img
                          src={GoogleIcon}
                          style={{ paddingRight: "10px" }}
                          alt="img"
                        ></img>
                        Google
                      </Button>
                    </Grid>
                  </Grid>
                  <Typography
                    sx={{
                      color: "#828282",
                      paddingTop: { xs: "30px", md: "40px" },
                      fontSize: 14,
                      fontWeight: 400,
                      lineHeight: "22px",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Already registered?{" "}
                    <Link
                      href="/sign-in"
                      sx={{ color: "#ef32d9", textDecoration: "none" }}
                    >
                      Tap here to Log In
                    </Link>
                  </Typography>
                </Box>
              )}
            </Box>
            {/* <Stack pt={5}>
              <Button
                variant="contained"
                sx={{ background: "white", color:"black", fontWeight: 600}}
                size="large"
                startIcon={<Box component="img" src={GoogleIcon}></Box>}
                onClick={() => signup()}
              >
                Sign up with Google
              </Button>
            </Stack> */}

            {/* <form onSubmit={handleResgister}>
              <Stack pt={5} spacing={2}>
                <Stack spacing={1}>
                  <Typography variant="caption" sx={{ color: "white" }}>
                    First & Last Name
                  </Typography>
                  <TextField
                    required
                    type="text"
                    sx={{ "&>div": { color: "white !important" } }}
                    placeholder="i.e. Gerald Brent"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="caption" sx={{ color: "white" }}>
                    Email Address
                  </Typography>
                  <TextField
                    required
                    type="email"
                    sx={{ "&>div": { color: "white !important" } }}
                    placeholder="i.e. geraldb@mail.com"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="caption" sx={{ color: "white" }}>
                    Password
                  </Typography>
                  <TextField
                    required
                    type="password"
                    sx={{ "&>div": { color: "white !important" } }}
                    placeholder="**********"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Stack>

                <Stack spacing={1}>
                  <Typography variant="caption" sx={{ color: "white" }}>
                    Confirm Password
                  </Typography>
                  <TextField
                    required
                    type="password"
                    sx={{ "&>div": { color: "white !important" } }}
                    placeholder="**********"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </Stack>

                <FormControlLabel
                  sx={{ "& span": { color: "white" } }}
                  control={
                    <Checkbox
                      name="remember"
                      sx={{ "&>div": { color: "white !important" } }}
                    />
                  }
                  label="Remember me"
                />
                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{
                    background: "#29FFF5",
                    fontWeight: 600,
                    color: "black",
                  }}
                  //disabled={loading}
                >
                  {loading ? (
                    <CircularProgress
                      size={25}
                      color="inherit"
                      variant="indeterminate"
                    />
                  ) : (
                    "Create Account"
                  )}
                </Button>
                <Typography sx={{ color: "#64748B" }}>
                  Already have an account? <Link href="/sign-in" sx={{color: "#29FFF5"}}>Log in</Link>
                </Typography>
              </Stack>
            </form> */}
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sm={12}
            display={"flex"}
            py={"63px"}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <Box
              component="img"
              src={SignInImg}
              sx={{ margin: "auto" }}
              alt="img"
            ></Box>
          </Grid>
        </Grid>
      </Container>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"md"}
      >
        <DialogContent sx={{ textAlign: "center" }}>
          <img src={lock} alt="img"></img>
          <DialogContentText
            sx={{
              paddingTop: "12px",
              paddingBottom: "8px",
              color: "#14121a",
              fontSize: 18,
              fontWeight: 900,
              lineHeight: "32px",
              fontFamily: "Inter, sans-serif",
            }}
            id="alert-dialog-description"
          >
            Creating your Account!
          </DialogContentText>
          <DialogContentText
            sx={{
              color: "#14121a",
              fontSize: 12,
              fontWeight: 600,
              fontFamily: "Inter, sans-serif",
            }}
            id="alert-dialog-description"
          >
            Welcome to your digital oasis
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default SignUpComponent;
