import { ReactNode, useEffect, useState } from "react";
import { Box, Button, Container, Divider, Stack, Typography } from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { dispatch, useSelector } from "../store";
import { Logout } from "../store/reducers/auth";
import { expireToken } from "../helpers";

const Layout = ({ children }: { children: ReactNode }) => {
  const [Menubar, setMenubar] = useState(false);
  const navigate = useNavigate();
  const { isLoggedIn, username , expiry }: any = useSelector(
    (state) => state.auth
  );

  const handleLogout = () => {
    const data: any = { status: false };
    dispatch(Logout(data));
  };

  useEffect(() => {
    if (expireToken(expiry) && isLoggedIn) {
      handleLogout();
    }
  }, [expiry, isLoggedIn]);
  return (
    <Box className="main">
      <Header Menubar={Menubar} setMenubar={setMenubar} />
      {children}
      <Footer />
      {Menubar && (
        <Box
          sx={{
            backgroundColor: "#fff",
            padding: "24px 0px",
            width: "100%",
            position: "absolute",
            top: {xs: '60px' , sm:"100px"},
            display: { xs: "flex", lg: "none" },
            borderBottomLeftRadius: "32px",
            borderBottomRightRadius: "32px",
            opacity: 0.96,
            zIndex : 1000
          }}
        >
          <Container maxWidth="xl">
            <Stack sx={{ borderLeft : '1px solid #D9D9D9'}}>
              <Typography
                sx={{
                  fontSize: 18,
                  color: window.location.pathname === '/' ? "#EF32D9" : '#777E90',
                  fontWeight: window.location.pathname === '/' ? 600 : 400,
                  borderLeft: window.location.pathname === '/' ? '1px solid #EF32D9' : '',
                  lineHeight: "25px",
                  fontFamily: "Poppins, sans-serif",
                  padding: "5px 0px",
                  paddingLeft : '23px',
                }}
                onClick={() => {navigate('/')
                  setMenubar(false)
                }}
              >
                Home
              </Typography>
              <Typography
                sx={{
                  fontSize: 18,
                  color: window.location.pathname === '/about-us' ? "#EF32D9" : '#777E90',
                  fontWeight: window.location.pathname === '/about-us' ? 600 : 400,
                  borderLeft: window.location.pathname === '/about-us' ? '1px solid #EF32D9' : '',
                  lineHeight: "25px",
                  fontFamily: "Poppins, sans-serif",
                  padding: "5px 0px",
                  paddingLeft : '23px',
                }}
                onClick={() => {navigate('/about-us')
                setMenubar(false)
                }}
              >
                About Us
              </Typography>
              <Typography
                sx={{
                  fontSize: 18,
                  color: window.location.pathname === '/how-it-works' ? "#EF32D9" : '#777E90',
                  fontWeight: window.location.pathname === '/how-it-works' ? 600 : 400,
                  borderLeft: window.location.pathname === '/how-it-works' ? '1px solid #EF32D9' : '',
                  lineHeight: "25px",
                  fontFamily: "Poppins, sans-serif",
                  padding: "5px 0px",
                  paddingLeft : '23px',
                }}
                onClick={() => {navigate('/how-it-works')
                setMenubar(false)
                }}
              >
                How it works
              </Typography>
              <Typography
                sx={{
                  fontSize: 18,
                  color: window.location.pathname === '/market' ? "#EF32D9" : '#777E90',
                  fontWeight: window.location.pathname === '/market' ? 600 : 400,
                  borderLeft: window.location.pathname === '/market' ? '1px solid #EF32D9' : '',
                  lineHeight: "25px",
                  fontFamily: "Poppins, sans-serif",
                  padding: "5px 0px",
                  paddingLeft : '23px',
                }}
                onClick={() => {navigate('/market')
                setMenubar(false)
                }}
              >
                Marketplace
              </Typography>
              <Typography
                sx={{
                  fontSize: 18,
                  color: window.location.pathname === '/contact' ? "#EF32D9" : '#777E90',
                  fontWeight: window.location.pathname === '/contact' ? 600 : 400,
                  borderLeft: window.location.pathname === '/contact' ? '1px solid #EF32D9' : '',
                  lineHeight: "25px",
                  fontFamily: "Poppins, sans-serif",
                  padding: "5px 0px",
                  paddingLeft : '23px',
                }}
                onClick={() => {navigate('/contact')
                setMenubar(false)
                }}
              >
                Contact
              </Typography>
            </Stack>
            {!isLoggedIn &&
            <>
                <Button
                  onClick={() => {navigate("/sign-up")
                    setMenubar(false)
                  }}
                  sx={{
                    fontWeight: 400,
                    fontSize : 12,
                    color: "#fff",
                    border: "1px solid rgba(255, 255, 255, 1)",
                    backgroundColor : "#33383D",
                    borderRadius: '100px !important',
                    padding : '5px 0px !important',
                    paddingLeft : '20px !important',
                    paddingRight : '20px !important',
                    marginLeft : '23px !important',
                    marginTop : '15px !important',
                    fontFamily : "Poppins, sans-serif"
                  }}
                  className="signupbutton"
                >
                  Sign Up
                </Button>
                <Button
                   sx={{
                    fontWeight: 400,
                    fontSize : 12,
                    color: "#242424",
                    border: "1px solid #242424",
                    backgroundColor : "#fff",
                    borderRadius: '100px !important',
                    padding : '5px 0px !important',
                    paddingLeft : '27px !important',
                    paddingRight : '27px !important',
                    marginLeft : '14px !important',
                    marginTop : '15px !important',
                    fontFamily : "Poppins, sans-serif"
                  }}
                  onClick={() => {navigate("/sign-in")
                  setMenubar(false)
                  }}
                  className="loginbutton"
                >
                  Login
                </Button>
            </> }
          </Container>
        </Box>
      )}
    </Box>
  );
};

export default Layout;
