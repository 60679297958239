import { Box } from "@mui/material";
import ProfileComponent from "../components/ProfileComponent";

const Profile = () => {
  return (
    <Box>
      <ProfileComponent />
    </Box>
  );
};

export default Profile;
