import { Box } from "@mui/material";
import DetailsComponent from "../components/DetailsComponent";

const Details = () => {
  return (
    <Box>
      <DetailsComponent />
    </Box>
  );
};

export default Details;
