import { Box, Container, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import hero from "../assets/img/howitwork/work_hero.png";
import about_offer from "../assets/img/howitwork/car_img.png";
import circlearrow from "../assets/img/howitwork/black_circle_arrow.svg";
import secure from "../assets/img/howitwork/secure.svg";
import easy from "../assets/img/howitwork/easy.svg";
import liquid from "../assets/img/howitwork/liquid.svg";
import diver from "../assets/img/howitwork/diver.svg";
import cost from "../assets/img/howitwork/cost.svg";
import home_hero_arrow from "../assets/img/Home/home_hero_arrow.svg";
import { useNavigate } from "react-router-dom";

function HowitWork() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  
  const navigate = useNavigate();
  return (
    <Box>

      {/*------------------ Hero section -------------------------*/}

      <Box
         pt={{ xs: '41px' , md: "145px" }}
         pb={{ xs: '71px' , md: "145px" }}
        sx={{
          backgroundImage: `url(${hero})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          borderBottomLeftRadius: "32px",
          borderBottomRightRadius: "32px",
          marginTop: {xs : "65px" , sm: '100px'},
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            color: "#4F575E",
            fontWeight: 600,
            fontFamily: "Poppins, sans-serif",
            textAlign: "center",
            lineHeight: "15px",
            backgroundColor: "#fff",
            borderRadius: "36px",
            padding: "4px 12px",
            maxWidth: "70px",
            margin: {xs: "0px auto 21px" , md: "0px auto 36px"},
          }}
        >
          LEARN
        </Typography>
        <Typography
          sx={{
            fontSize: {xs: "24px" , md: '48px'},
            color: "#fff",
            fontWeight: 600,
            fontFamily: "Poppins, sans-serif",
            textAlign: "center",
            lineHeight: {xs: '30px', md: "56px"},
          }}
        >
          How it Works{" "}
        </Typography>
        <Typography
          sx={{
            fontSize:{xs: '12px' , md: "16px"},
            color: "#CBCFD2",
            fontWeight: 400,
            fontFamily: "Poppins, sans-serif",
            textAlign: "center",
            lineHeight: {xs: "18px" , md: '24px'},
            paddingTop: {xs: "14px" , md: '24px'},
            maxWidth: { xs : '320px', md:"580px"},
            margin: "auto",
          }}
        >
          In a digital age of limitless possibilities, we embarked on a quest to
          redefine the standard of luxury cars and artwork ownership.
        </Typography>
      </Box>

      {/*------------------- Offer section -----------------------*/}

      <Box pt={{xs: '56px' ,  md:"100px"}}  pb={{xs: '42px' ,  md:"100px"}}>
        <Container maxWidth="xl">
          <Grid container alignItems="center" columnSpacing={{ xl: "100px" }}>
            <Grid item lg={6} sx={{ display: "flex" }}>
              <Box
              component={'img'}
                src={about_offer}
                alt="img"
                sx={{ marginRight: "auto" , display : {xs: 'none' , md: 'block'} }}
              ></Box>
            </Grid>
            <Grid item lg={6} sx={{ marginTop : { md: '20px' , xl : '0px'} }}>
              <Typography
                sx={{
                  fontSize: 12,
                  color: "#F7F9FD",
                  fontWeight: 600,
                  lineHeight: "15px",
                  fontFamily: "Poppins, sans-serif",
                  backgroundColor: "#33383D",
                  borderRadius: "36px",
                  padding: "4px 0px",
                  maxWidth: 117,
                  marginBottom: { xs: '20px' , md:"36px"},
                  // letterSpacing: "1px",
                  textAlign: "center",
                }}
              >
                OUR OFFERING
              </Typography>
              <Typography
                sx={{
                  fontSize: {xs: 24 , md:30},
                  color: "#242424",
                  fontWeight: 600,
                  lineHeight: { xs: '30px' , md: "38px"},
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Giving people the ability to build generational wealth and high
                value asset portfolios through the power of fractionalization.
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: 14 , md:16},
                  color: "#4F575E",
                  fontWeight: 400,
                  lineHeight: { xs: '18px' , md:"24px"},
                  fontFamily: "Poppins, sans-serif",
                  marginTop: { xs: '12px !important' ,md:"24px !important"},
                  marginBottom: 0,
                  maxWidth: "550px",
                }}
              >
                Breaking into markets previously reserved for the wealthiest
                investors. Investing in fractional shares also relieves many of
                the individual burdens that typical ownership requires such as
                storage, appraisal, and concerns over counterfeits.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "center",
                  marginTop: {xs: '12px' , md: "27px"},
                }}
              >
                <Typography
                onClick={() => navigate('/sign-up')}
                  sx={{
                    fontSize: {xs: 14 , md: 20},
                    fontFamily: "Poppins, sans-serif !important",
                    lineHeight: "30px",
                    color: "#ef32d9",
                    fontWeight: 400,
                  }}
                >
                  Sign Up
                </Typography>

                <Box
                component={'img'}
                  src={home_hero_arrow}
                  alt="Home Hero Arrow"
                  sx={{ width : {xs: '20px' , md: 'auto'} , marginLeft: {xs: '10px' , md:"14px"} }}
                />
              </Box>
            </Grid>
          </Grid>



          <Grid
            container
            alignItems="start"
            columnSpacing={"26px"}
            pt={{ xs: '35px' , md:"100px"}}
          >
            <Grid item xs={6} lg={3}>
              <Typography
                sx={{
                  fontSize: {xs: 14 , md: 20},
                  color: "#fff",
                  fontWeight: 600,
                  lineHeight: "30px",
                  fontFamily: "Poppins, sans-serif",
                  backgroundColor: "#242424",
                  width: {xs: 33 , md: 48},
                  height: {xs: 33 , md: 48},
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: {xs: '21px' , md:"24px"},
                }}
              >
                1
              </Typography>
              <Typography
                sx={{
                  fontSize: {xs: 15 ,md:20},
                  color: "#242424",
                  fontWeight: "bold",
                  lineHeight: {xs: '25px' ,md: "30px"},
                  fontFamily: "Poppins, sans-serif",
                  paddingBottom: "8px",
                }}
              >
                Create Account
              </Typography>
              <Typography
                sx={{
                  fontSize: {xs: 12 , md:14},
                  color: "#6B767F",
                  fontWeight: 400,
                  lineHeight: {xs: '16px',md:"20px"},
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Create your account to get access and browse the McQueen
                investment opportunities and offerings.
              </Typography>
            </Grid>
            <Grid item xs={6} lg={3}>
              <Typography
                sx={{
                  fontSize: {xs: 14 , md: 20},
                  color: "#fff",
                  fontWeight: 600,
                  lineHeight: "30px",
                  fontFamily: "Poppins, sans-serif",
                  backgroundColor: "#242424",
                  width: {xs: 33 , md: 48},
                  height: {xs: 33 , md: 48},
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: {xs: '21px' , md:"24px"},
                }}
              >
                2
              </Typography>
              <Typography
                sx={{
                  fontSize: {xs: 15 ,md:20},
                  color: "#242424",
                  fontWeight: "bold",
                  lineHeight: {xs: '25px' ,md: "30px"},
                  fontFamily: "Poppins, sans-serif",
                  paddingBottom: "8px",
                }}
              >
                Browse Marketplace
              </Typography>
              <Typography
                sx={{
                  fontSize: {xs: 12 , md:14},
                  color: "#6B767F",
                  fontWeight: 400,
                  lineHeight: {xs: '16px',md:"20px"},
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                After signup you’ll have access to all assets available on our
                platform. Buy, Build, and Creating your furture.
              </Typography>
            </Grid>
            <Grid item xs={6} lg={3} pt={{xs: '32px' , md: '0px'}}>
              <Typography
                sx={{
                  fontSize: {xs: 14 , md: 20},
                  color: "#fff",
                  fontWeight: 600,
                  lineHeight: "30px",
                  fontFamily: "Poppins, sans-serif",
                  backgroundColor: "#242424",
                  width: {xs: 33 , md: 48},
                  height: {xs: 33 , md: 48},
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: {xs: '21px' , md:"24px"},
                }}
              >
                3
              </Typography>
              <Typography
                sx={{
                  fontSize: {xs: 15 ,md:20},
                  color: "#242424",
                  fontWeight: "bold",
                  lineHeight: {xs: '25px' ,md: "30px"},
                  fontFamily: "Poppins, sans-serif",
                  paddingBottom: "8px",
                }}
              >
                Buy Exclusive Assets
              </Typography>
              <Typography
                sx={{
                  fontSize: {xs: 12 , md:14},
                  color: "#6B767F",
                  fontWeight: 400,
                  lineHeight: {xs: '16px',md:"20px"},
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Each share is initially valued at $20 and the minimum investment
                is $500 (25 shares) per asset
              </Typography>
            </Grid>
            <Grid item xs={6} lg={3} pt={{xs: '32px' , md: '0px'}}>
              <Typography
                sx={{
                  fontSize: {xs: 14 , md: 20},
                  color: "#fff",
                  fontWeight: 600,
                  lineHeight: "30px",
                  fontFamily: "Poppins, sans-serif",
                  backgroundColor: "#242424",
                  width: {xs: 33 , md: 48},
                  height: {xs: 33 , md: 48},
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: {xs: '21px' , md:"24px"},
                }}
              >
                4
              </Typography>
              <Typography
                sx={{
                  fontSize: {xs: 15 ,md:20},
                  color: "#242424",
                  fontWeight: "bold",
                  lineHeight: {xs: '25px' ,md: "30px"},
                  fontFamily: "Poppins, sans-serif",
                  paddingBottom: "8px",
                }}
              >
                Liquidate Investment
              </Typography>
              <Typography
                sx={{
                  fontSize: {xs: 12 , md:14},
                  color: "#6B767F",
                  fontWeight: 400,
                  lineHeight: {xs: '16px',md:"20px"},
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Upon reaching their investment term, assets will be sold either
                through auction or private transaction.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/*------------------- Industry section -----------------------*/}

      <Box
        py={{xs: '101px' , md:"183px"}}
        sx={{
          backgroundImage: `url(${hero})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Container maxWidth={"xl"}>
          <Typography
            sx={{
              fontSize: {xs: '18px' ,md: "48px"},
              color: "#fff",
              fontWeight: 600,
              fontFamily: "Poppins, sans-serif",
              lineHeight: {xs: '24px' , md:"56px"},
              maxWidth: {xs: '317px' , md: "1077px"},
              textAlign : 'center',
              margin: "auto",
            }}
          >
            We are transforming an industry lacking transparency, inclusivity,
            and accessibility. We champion shared ownership for all.
          </Typography>
        </Container>
      </Box>

      {/*------------------- Ownership  section -----------------------*/}

      <Container maxWidth={"xl"} >
        <Box sx={{ padding: {xs: '41px 0px 41px' , md:"60px 0px 88px"} }}>
        <Typography
          sx={{
            fontSize: 12,
            color: "#F7F9FD",
            fontWeight: 600,
            lineHeight: "15px",
            fontFamily: "Poppins, sans-serif",
            backgroundColor: "#33383D",
            borderRadius: "36px",
            padding: "4px 0px",
            maxWidth: 120,
            marginBottom: {xs:'20px' , md:"36px"},
            textAlign: "center",
          }}
        >
          WHY IT WORKS
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection : {xs: 'column' , md: 'row'},
            alignItems: {xs: 'start' , md:"end"},
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: {xs:24 ,md:40},
              color: "#242424",
              fontWeight: 600,
              lineHeight: {xs:'30px' , md:"48px"},
              fontFamily: "Poppins, sans-serif",
            }}
          >
            Benefits on Fractional Ownership?
          </Typography>
          <Box   sx={{
                  display: "flex",
                  cursor: "pointer",
                  alignItems: "center",
                  marginTop: {xs: '12px' , md: "27px"},
                }}>
             <Typography
          onClick={() => navigate("/contact")}
            sx={{
              fontSize: {xs: 14 , md: 20},
              fontFamily: "Poppins, sans-serif !important",
              lineHeight: "30px",
              color: "#ef32d9",
              fontWeight: 400,
            }}
          >
            Get more Information
          </Typography> 
           <Box
            component={'img'}
                  src={home_hero_arrow}
                  alt="Home Hero Arrow"
                  sx={{ width : {xs: '20px' , md: 'auto'} , marginLeft: {xs: '10px' , md:"14px"} }}
                />
          </Box>
         
        </Box>
        <Grid container mt={{xs: '24px' ,md:"50px"}} columnSpacing={{ md: "40px", xl: "250px" }}>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                fontSize: {xs: 14 , md: 18},
                color: "#000",
                fontWeight: 400,
                lineHeight: {xs:'18px' , md:"28px"},
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Allowing people who could never before afford to own, buy or
              collect access to some of the rarest investment opportunities that
              are usually limited to just the ultra wealthy. Breaking down the
              barriers and disrupting a market where the barriers to entry are
              high. Fractionalization of assets help make it more accessible to
              more investors.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              sx={{
                fontSize: {xs: 14 , md: 18},
                color: "#000",
                fontWeight: 400,
                lineHeight: {xs:'18px' , md:"28px"},
                fontFamily: "Poppins, sans-serif",
              }}
            >
              The market has long been dominated by the ultra-wealthy, but the
              rise of fractionalized shares has begun to open up the market,
              allowing more investors to invest in fractions of masterpieces. As
              a strategic diversifier within a portfolio allowing investors to
              help improve their own returns and potentially limit downside
              losses. 
            </Typography>
          </Grid>
        </Grid>

            
        <Box sx={{marginTop : {xs:'34px' , md:'66px'} ,  marginBottom: {xs: '0px' , md: "34px"}, gap : '25px' , display : 'flex' , flexWrap : 'wrap' , alignItems : 'center'}}>
          <Typography
            sx={{
              fontSize: {xs: 13 ,md:21},
              color: "#242424",
              fontWeight: 600,
              lineHeight: {xs : '13px', md: "26px"},
              fontFamily: "Inter, sans-serif",
              backgroundColor: "#fff",
              borderRadius: {xs:'5px' ,md:"10px"},
              padding: {xs:'10px 11px' , md:"20px 20px"},
              textAlign: "center",
              minWidth: {xs: '135px' ,md:"270px"},
              maxWidth: {xs: '135px' ,md:"270px"},
              display: "flex",
              alignItems: "center",
              justifyContent : 'center'
            }}
            className="ownerection"
          >
            <Box component={'img'} src={secure} sx={{ marginRight: {xs: '6px' ,md:"15px"}  , width : {xs: '13px' , md: 'auto'}}}></Box>
            Safe & Secure
          </Typography>
          <Typography
            sx={{
              fontSize: {xs: 13 ,md:21},
              color: "#242424",
              fontWeight: 600,
              lineHeight: {xs : '13px', md: "26px"},
              fontFamily: "Inter, sans-serif",
              backgroundColor: "#fff",
              borderRadius: {xs:'5px' ,md:"10px"},
              padding: {xs:'10px 11px' , md:"20px 20px"},
              textAlign: "center",
              minWidth: {xs: '162px' ,md:"324px"},
              maxWidth: {xs: '162px' ,md:"324px"},
              display: "flex",
              alignItems: "center",
              justifyContent : 'center'

            }}
            className="ownerection"
          >
         <Box component={'img'} src={easy} sx={{ marginRight: {xs: '6px' ,md:"15px"}  , width : {xs: '13px' , md: 'auto'}}}></Box>
            Easy & Accessible
          </Typography>
          <Typography
            sx={{
              fontSize: {xs: 13 ,md:21},
              color: "#242424",
              fontWeight: 600,
              lineHeight: {xs : '13px', md: "26px"},
              fontFamily: "Inter, sans-serif",
              backgroundColor: "#fff",
              borderRadius: {xs:'5px' ,md:"10px"},
              padding: {xs:'10px 11px' , md:"20px 20px"},
              textAlign: "center",
              minWidth: {xs: '155px' ,md:"310px"},
              maxWidth: {xs: '155px' ,md:"310px"},
              display: "flex",
              alignItems: "center",
              justifyContent : 'center'

            }}
            className="ownerection"
          >
            <Box component={'img'} src={liquid} sx={{ marginRight: {xs: '6px' ,md:"15px"}  , width : {xs: '13px' , md: 'auto'}}}></Box>
            Creates Liquidity
          </Typography>
        
          <Typography
            sx={{
              fontSize: {xs: 13 ,md:21},
              color: "#242424",
              fontWeight: 600,
              lineHeight: {xs : '13px', md: "26px"},
              fontFamily: "Inter, sans-serif",
              backgroundColor: "#fff",
              borderRadius: {xs:'5px' ,md:"10px"},
              padding: {xs:'10px 11px' , md:"20px 20px"},
              textAlign: "center",
              minWidth: {xs: '140px' ,md:"278px"},
              maxWidth: {xs: '140px' ,md:"278px"},
              display: "flex",
              alignItems: "center",
              justifyContent : 'center'
            }}
            className="ownerection"
          >
            <Box component={'img'} src={diver} sx={{ marginRight: {xs: '6px' ,md:"15px"}  , width : {xs: '13px' , md: 'auto'}}}></Box>
            Diversification
          </Typography>
          <Typography
            sx={{
              fontSize: {xs: 13 ,md:21},
              color: "#242424",
              fontWeight: 600,
              lineHeight: {xs : '13px', md: "26px"},
              fontFamily: "Inter, sans-serif",
              backgroundColor: "#fff",
              borderRadius: {xs:'5px' ,md:"10px"},
              padding: {xs:'10px 11px' , md:"20px 20px"},
              textAlign: "center",
              minWidth: {xs: '133px' ,md:"265px"},
              maxWidth: {xs: '133px' ,md:"265px"},
              display: "flex",
              alignItems: "center",
              justifyContent : 'center'
            }}
            className="ownerection"
          >
            <Box component={'img'} src={cost} sx={{ marginRight: {xs: '6px' ,md:"15px"}  , width : {xs: '13px' , md: 'auto'}}}></Box>
            Shared Costs
          </Typography>
        </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default HowitWork;