import { useContext, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import APIContext from "../context/ApiContext";
import snackbar from "../config/snackbar";
import passwordimg from "../assets/img/passwordright.png";

const ResetComponent = () => {
  const [email, setEmail] = useState<string>("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const { sendResetMail } = useContext(APIContext);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const style = {
    "& label.Mui-focused": {
      color: "#232632 !important",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#232632 !important",
    },
    "& .MuiInputLabel-root": {
      color: "#232632 !important",
    },
    "& .MuiOutlinedInput-input": {
      color: "#232632 !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#232632 !important",
        border: "2px solid #232632 !important",
        borderRadius: "10px !important",
      },
      "&:hover fieldset": {
        borderColor: "#232632 !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#232632 !important",
      },
    },
  };

  const onSubmitform = async (e: any) => {
    e.preventDefault();
    if (!isEmailValid) {
      return;
    }
    const { data } = await sendResetMail(email);
    if (data.message) {
      handleClickOpen();
      // snackbar(data.message);
    } else {
      snackbar("Sever Error", "error");
    }
  };

  const validateEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setEmail(email);
    setIsEmailValid(emailPattern.test(email));
  };

  return (
    <Box sx={{ maxWidth: "458px", textAlign: "center", margin: "auto" }}>
      <Box>
        <Typography
          sx={{
            fontSize: { xs: 30, md: 40 },
            color: "#232632",
            fontWeight: 600,
            lineHeight: "48px",
            fontFamily: "Poppins, sans-serif",
            paddingBottom: "16px",
          }}
        >
          Reset Password
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: 12, md: 14 },
            color: "#828282",
            fontWeight: 400,
            lineHeight: "22px",
            fontFamily: "Poppins, sans-serif",
            paddingBottom: "32px",
          }}
        >
          Please enter your email to receive <br /> password reset instructions.
        </Typography>
        <form onSubmit={onSubmitform}>
          <TextField
            id="outlined-basic"
            label="Email"
            variant="outlined"
            value={email}
            onChange={(e) => validateEmail(e.target.value)}
            sx={style}
            inputProps={{
              style: { color: "#191B23" },
            }}
            fullWidth
            type="email"
            autoComplete="off"
            error={!isEmailValid}
            helperText={!isEmailValid ? "Email invalid." : ""}
          />
          <Typography
            sx={{
              fontSize: 14,
              color: "#828282",
              fontWeight: 400,
              lineHeight: "22px",
              fontFamily: "Poppins, sans-serif",
              paddingBottom: "32px",
              paddingTop: "16px",
            }}
          >
            Didn’t get an email?
            <span
              style={{
                color: "#EF32D9",
                cursor: "pointer",
                paddingLeft: "3px",
              }}
            >
              Click Here to Resend
            </span>
          </Typography>
          <Button
            sx={{
              fontSize: { xs: 15, md: 20 },
              color: "#fff",
              fontWeight: 400,
              lineHeight: { xs: "24px", md: "32px" },
              backgroundColor: "#33383D",
              borderRadius: "6px",
              padding: { xs: "10px 0px", md: "12px 0px" },
              width: "100%",
            }}
            className="accountbutton"
            type="submit"
            disabled={!(isEmailValid && email)}
          >
            Submit
          </Button>
        </form>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"md"}
        sx={{
          "& .MuiPaper-root": {
            background: "#fff !important",
            borderRadius: "12px !important",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
      >
        <DialogContent sx={{ textAlign: "center" }}>
          <img src={passwordimg} alt="img"></img>
          <DialogContentText
            sx={{
              paddingTop: "12px",
              paddingBottom: "8px",
              color: "#14121a",
              fontSize: 18,
              fontWeight: 700,
              lineHeight: "32px",
              fontFamily: "Inter, sans-serif",
            }}
            id="alert-dialog-description"
          >
            Email sent successfully{" "}
          </DialogContentText>
          {/* <DialogContentText
            sx={{
              color: "#14121a",
              fontSize: 12,
              fontWeight: 600,
              fontFamily: "Inter, sans-serif",
            }}
            id="alert-dialog-description"
          >
            Your password has successfully been reset.
          </DialogContentText> */}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ResetComponent;
