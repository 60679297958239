import { useNavigate } from "react-router-dom";
import { Box, Container, Grid, Typography } from "@mui/material";
import circlearrow from "../../assets/img/Home/CaretCircleRight.svg";
import plus from "../../assets/img/Home/Plus.svg";
import minus from "../../assets/img/Home/MinusCircle.svg";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";

const FaqComponent = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <Box pb={{xs: '60px' , md: "100px"}} >
      <Container maxWidth="xl">
        <Typography
          sx={{
            fontSize: 12,
            color: "#F7F9FD",
            fontWeight: 600,
            lineHeight: "15px",
            fontFamily: "Poppins, sans-serif",
            backgroundColor: "#33383D",
            borderRadius: "36px",
            padding: "4px 0px",
            maxWidth: 225,
            marginBottom: {xs: '20px' ,md: "36px"},
            // letterSpacing: "1px",
            textAlign: "center",
          }}
        >
          FREQUENTLY ASKED QUESTIONS
        </Typography>
        <Grid container alignItems="start" spacing={{xs : '0px' , md: "10px"}}>
          <Grid item md={6}>
            <Typography
              sx={{
                fontSize: {xs: 24 , md: '35px' ,  lg : 40},
                color: "#242424",
                fontWeight: 600,
                lineHeight: {xs: '30px' ,md: '40px' ,  lg: "48px"},
                fontFamily: "Poppins, sans-serif",
                marginBottom: {xs: '20px' , md:"67px"},
              }}
            >
              Need more information <br /> or have more questions? <br /> We
              have the answers.
            </Typography>

            <Typography
            onClick={() => navigate("/faq")}
              sx={{
                fontSize: {xs: 14 , md: 18},
                color: "#ef32d9",
                fontWeight: 400,
                lineHeight: "27px",
                fontFamily: "Poppins, sans-serif",
                marginBottom: 0,
                display: "flex",
                alignItems: "center",
                cursor : 'pointer'
              }}
            >
              Get more Information
              <span style={{ display: "flex", marginLeft: "12px" }}>
                <Box component={'img'} src={circlearrow} sx={{ width : {xs: '20px' , md: 'auto'} }}></Box>
              </span>
            </Typography>
          </Grid>

          {/* faq section */}

          <Grid item md={6} sx={{ marginTop : {xs: '30px' , md: '0px'}}}>
            <div>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
                sx={{
                  backgroundColor: "#fff",
                  border:
                    expanded === "panel1"
                      ? "1px solid #ef32d9"
                      : "1px solid #242424",
                  borderRadius: "6px !important",
                  marginBottom: "12px",
                }}
              >
                <AccordionSummary
                  expandIcon={ expanded === "panel1" ? <img src={minus} alt="minus"></img> : <img src={plus} alt="plus"></img>}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography
                    sx={{
                      width: "90%",
                      fontSize: 16,
                      color: "#33383D",
                      fontWeight: expanded === "panel1" ? 600 : 500,
                      lineHeight: "20px",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    How does McQueen Lifestyle choose which assets to acquire?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingTop: 0 }}>
                  <Typography
                    sx={{
                      width: "90%",
                      fontSize: 12,
                      color: "#6B767F",
                      fontWeight: 400,
                      lineHeight: "17px",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    McQueen Lifestyle stands out for its commitment to quality and trust, sourcing assets exclusively from carefully vetted, reputable collectors. This selective approach ensures that each piece we acquire is part of a legacy of excellence, offering our clients exclusive access to the finest selections. By partnering with esteemed collectors, we open the door to a world of premium assets, each with a story to tell and a value to cherish.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
                sx={{
                  backgroundColor: "#fff",
                  border:
                    expanded === "panel2"
                      ? "1px solid #ef32d9"
                      : "1px solid #242424",
                  borderRadius: "6px !important",
                  marginBottom: "12px",
                }}
              >
                <AccordionSummary
                expandIcon={ expanded === "panel2" ? <img src={minus} alt="minus"></img> : <img src={plus} alt="plus"></img>}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography
                    sx={{
                      width: "90%",
                      fontSize: 16,
                      color: "#33383D",
                      fontWeight: expanded === "panel2" ? 600 : 500,
                      lineHeight: "20px",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Why does McQueen Lifestyle only offer Classic Cars and Art?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingTop: 0 }}>
                  <Typography
                    sx={{
                      width: "90%",
                      fontSize: 12,
                      color: "#6B767F",
                      fontWeight: 400,
                      lineHeight: "17px",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    At present, McQueen Lifestyle only offers shares in classic cars and artwork, but it may expand to include additional types of assets in the future. Our initial focus on artwork and vintage cars was due to several compelling reasons:
                    <br />
                    <span style={{ fontWeight: 'bold' }}>Value Appreciation</span>: Over time, both art and classic cars have the potential to increase in value, especially if they are rare, sought after, or have historical significance.
                    <br />
                    <span style={{ fontWeight: 'bold' }}>Tangible Assets</span>: Unlike stocks or bonds, art and classic cars are tangible assets that investors can enjoy while they appreciate in value.
                    <br />
                    <span style={{ fontWeight: 'bold' }}>Diversification</span> :Investing in art and classic cars can diversify an investment portfolio, potentially reducing risk by spreading investments across different asset classes.
                    <br />
                    <span style={{ fontWeight: 'bold' }}>Inflation Hedge</span>: High-quality art and classic cars often retain their value and can even appreciate during times of inflation, acting as a hedge against currency devaluation.
                    <br />
                    <span style={{ fontWeight: 'bold' }}>Cultural and Emotional Value</span>: Beyond financial returns, these assets can offer cultural significance and emotional satisfaction, enriching the lives of collectors.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
                sx={{
                  backgroundColor: "#fff",
                  border:
                    expanded === "panel3"
                      ? "1px solid #ef32d9"
                      : "1px solid #242424",
                  borderRadius: "6px !important",
                  marginBottom: "12px",
                }}
              >
                <AccordionSummary
                 expandIcon={ expanded === "panel3" ? <img src={minus} alt="minus"></img> : <img src={plus} alt="plus"></img>}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                >
                  <Typography
                    sx={{
                      width: "90%",
                      fontSize: 16,
                      color: "#33383D",
                      fontWeight: expanded === "panel3" ? 600 : 500,
                      lineHeight: "20px",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Are there any risks involved in the securities that McQueen Lifestyle offers?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingTop: 0 }}>
                  <Typography
                    sx={{
                      width: "90%",
                      fontSize: 12,
                      color: "#6B767F",
                      fontWeight: 400,
                      lineHeight: "17px",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Investing in art and classic cars carries unique risks, such as market volatility where the value of these assets can fluctuate significantly based on trends and collector interest. Liquidity risk is also a factor; unlike stocks or bonds, selling these items quickly without a loss in value can be challenging. 
Lastly, the limited diversification offered by these assets can lead to higher risk if they form a significant part of an investment portfolio.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
                sx={{
                  backgroundColor: "#fff",
                  border:
                    expanded === "panel4"
                      ? "1px solid #ef32d9"
                      : "1px solid #242424",
                  borderRadius: "6px !important",
                  marginBottom: "12px",
                }}
              >
                <AccordionSummary
                  expandIcon={ expanded === "panel4" ? <img src={minus} alt="minus"></img> : <img src={plus} alt="plus"></img>}
                  aria-controls="panel4bh-content"
                  id="panel4bh-header"
                >
                  <Typography
                    sx={{
                      width: "90%",
                      fontSize: 16,
                      color: "#33383D",
                      fontWeight: expanded === "panel4" ? 600 : 500,
                      lineHeight: "20px",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    What does McQueen Lifestyle charge buyers?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingTop: 0 }}>
                  <Typography
                    sx={{
                      width: "90%",
                      fontSize: 12,
                      color: "#6B767F",
                      fontWeight: 400,
                      lineHeight: "17px",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    All fees associated with McQueen Lifestyle are transparently disclosed to investors on the dedicated asset details page.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
                sx={{
                  backgroundColor: "#fff",
                  border:
                    expanded === "panel5"
                      ? "1px solid #ef32d9"
                      : "1px solid #242424",
                  borderRadius: "6px !important",
                  marginBottom: "12px",
                }}
              >
                <AccordionSummary
                expandIcon={ expanded === "panel5" ? <img src={minus} alt="minus"></img> : <img src={plus} alt="plus"></img>}
                  aria-controls="panel5bh-content"
                  id="panel5bh-header"
                >
                  <Typography
                    sx={{
                      width: "90%",
                      fontSize: 16,
                      color: "#33383D",
                      fontWeight: expanded === "panel5" ? 600 : 500,
                      lineHeight: "20px",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Can I list my artwork or classical car for sale on McQueen Lifestyle?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ paddingTop: 0 }}>
                  <Typography
                    sx={{
                      width: "90%",
                      fontSize: 12,
                      color: "#6B767F",
                      fontWeight: 400,
                      lineHeight: "17px",
                      fontFamily: "Poppins, sans-serif",
                    }}
                  >
                    Asset owners interested in selling their assets are invited to contact our acquisitions team. This includes individual investors, classic car owners, gallery curators, institutional representatives, and artists. Please note that, we currently only consider artwork and classic cars with minimum value of $500,000 for listing.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FaqComponent;
