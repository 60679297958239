import { Box, Button, Dialog, DialogTitle, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import tabicon from "../../assets/img/tableicon.svg";
import { Data } from "../../config";
import { useState } from "react";
import closebutton from "../../assets/img/close_button.svg";
import download from "../../assets/img/download.svg";
import { InvestmentType } from "../../types";

function TableComponent({ data }: { data: Data[] }) {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: " #F7F9FD",
      color: "#33383D",
      fontSize: 12,
      fontWeight: 600,
      fontFamily: "Inter, sans-serif",
      padding: "0 2px 4px",
    },
    [`&.${tableCellClasses.body}`]: {
      backgroundColor: " #F7F9FD",
      color: "#33383D",
      padding: "4px 4px",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
      border: 0,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>();


  const handleClose = () => {
    setOpen(false);
  };
  
  const handleClick = (event: React.MouseEvent<unknown>, item: any) => {
    setSelectedItem(item);
    setOpen(true);
  };

  
  return (
    <Box
      mt={"43px"}
      p={"24px"}
      sx={{ backgroundColor: "#F7F9FD", borderRadius: "8px" }}
      className="tablesection"
    >
      <Typography
        sx={{
          color: "#33383D",
          fontSize: 18,
          fontWeight: 900,
          fontFamily: "Inter, sans-serif",
          paddingBottom: "20px",
          borderBottom: "1px solid #14121a",
        }}
      >
        Portfolio Overview
      </Typography>
      <TableContainer component={Paper} sx={{ marginTop: "24px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "140px",
                    justifyContent: "space-between",
                    backgroundColor: "#fff",
                    padding: "12px 16px",
                    borderRadius: "12px",
                  }}
                >
                  Date <img src={tabicon} alt="tab"></img>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "140px",
                    justifyContent: "space-between",
                    backgroundColor: "#fff",
                    padding: "12px 16px",
                    borderRadius: "12px",
                  }}
                >
                  Investment ID <img src={tabicon} alt="tab"></img>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "140px",
                    justifyContent: "space-between",
                    backgroundColor: "#fff",
                    padding: "12px 16px",
                    borderRadius: "12px",
                  }}
                >
                  Offerings <img src={tabicon} alt="tab"></img>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "140px",
                    justifyContent: "space-between",
                    backgroundColor: "#fff",
                    padding: "12px 16px",
                    borderRadius: "12px",
                  }}
                >
                  Price/Share <img src={tabicon} alt="tab"></img>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "140px",
                    justifyContent: "space-between",
                    backgroundColor: "#fff",
                    padding: "12px 16px",
                    borderRadius: "12px",
                  }}
                >
                  Shares <img src={tabicon} alt="tab"></img>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "140px",
                    justifyContent: "space-between",
                    backgroundColor: "#fff",
                    padding: "12px 16px",
                    borderRadius: "12px",
                  }}
                >
                  Net Amount <img src={tabicon} alt="tab"></img>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "140px",
                    justifyContent: "space-between",
                    backgroundColor: "#fff",
                    padding: "12px 16px",
                    borderRadius: "12px",
                  }}
                >
                  Payment <img src={tabicon} alt="tab"></img>
                </Box>
              </StyledTableCell>
              <StyledTableCell>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "140px",
                    justifyContent: "space-between",
                    backgroundColor: "#fff",
                    padding: "12px 16px",
                    borderRadius: "12px",
                  }}
                >
                  Status <img src={tabicon} alt="tab"></img>
                </Box>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row: Data, key: number) => (
              <StyledTableRow key={row.date}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{
                    color: "#33383D",
                    fontSize: 12,
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "140px",
                      justifyContent: "center",
                      backgroundColor: "#fff",
                      padding: "12px 16px",
                      borderRadius: "12px",
                    }}
                  >
                    {row.date}
                  </Box>
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    color: "#33383D",
                    fontSize: 12,
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "140px",
                      justifyContent: "center",
                      backgroundColor: "#fff",
                      padding: "12px 16px",
                      borderRadius: "12px",
                    }}
                  >
                    {row.invesment}
                  </Box>
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    color: "#33383D",
                    fontSize: 14,
                    fontWeight: "bold",
                    fontFamily: "Inter, sans-serif",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "140px",
                      justifyContent: "center",
                      backgroundColor: "#fff",
                      padding: "12px 16px",
                      borderRadius: "12px",
                    }}
                  >
                    {row.offer}
                  </Box>
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    color: "#33383D",
                    fontSize: 12,
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "140px",
                      justifyContent: "center",
                      backgroundColor: "#fff",
                      padding: "12px 16px",
                      borderRadius: "12px",
                    }}
                  >
                    {row.price}
                  </Box>
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    color: "#33383D",
                    fontSize: 12,
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "140px",
                      justifyContent: "center",
                      backgroundColor: "#fff",
                      padding: "12px 16px",
                      borderRadius: "12px",
                    }}
                  >
                    {row.share}
                  </Box>
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    color: "#33383D",
                    fontSize: 12,
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "140px",
                      justifyContent: "center",
                      backgroundColor: "#fff",
                      padding: "12px 16px",
                      borderRadius: "12px",
                    }}
                  >
                    {row.text}
                  </Box>
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    color: "#33383D",
                    fontSize: 12,
                    fontWeight: 600,
                    fontFamily: "Inter, sans-serif",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "140px",
                      justifyContent: "center",
                      backgroundColor: "#fff",
                      padding: "12px 16px",
                      borderRadius: "12px",
                    }}
                  >
                    {row.paymentType}
                  </Box>
                </StyledTableCell>
                <StyledTableCell>
                  <Button
                    sx={{
                      backgroundColor:
                        row.buttondis === true ? "#ef32d9" : "#CBCFD2",
                      color: "#fff",
                      fontSize: 12,
                      fontWeight: 600,
                      fontFamily: "Inter, sans-serif",
                      borderRadius: "12px",
                      padding: row.status === "started" ? '14px 46px' : "14px 41px",
                    }}
                    className={`${
                      row.buttondis === true ? "receivebutton" : "disbutton"
                    }`}
                    onClick={(event) => {
                      if (row.buttondis === true) {
                        handleClick(event, row)
                      }
                    }}
                  >
                    {row.status === "started" ? "Started " : "Received"}
                  </Button>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          paddingTop: "20px",
          borderBottom: "1px solid #14121a",
        }}
      ></Box>
        <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"md"}
        sx={{
          "& .MuiPaper-root": {
            background: "#F7F9FD !important",
            borderRadius: " 8px !important",
            maxWidth: "619px !important",
            padding: "24px !important",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid #14121a",
            padding: "0px 0px 20px !important",
          }}
        >
          <Typography
            sx={{
              color: "#33383D",
              fontSize: 18,
              fontWeight: "bold",
              fontFamily: "Inter, sans-serif",
            }}
          >
            <strong>Invest Information</strong>
          </Typography>
          <img src={closebutton} onClick={handleClose} style={{cursor : 'pointer'}}></img>
        </DialogTitle>
        <Grid container columnSpacing={'16px'} sx={{ paddingTop : '17px'}}>
          <Grid item xs={6}>
            <Typography  sx={{
              color: "#000",
              fontSize: 12,
              fontWeight: 500,
            }}>
            <strong>Amount</strong> 
            </Typography>
            <Typography  sx={{
              color: "#242424",
              fontSize: 14,
              fontWeight: 400,
              paddingTop : '11px'
            }}>
            {selectedItem?.text} 
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography  sx={{
              color: "#000",
              fontSize: 12,
              fontWeight: 500,
            }}>
            <strong>Quantity</strong> 
            </Typography>
            <Typography  sx={{
              color: "#242424",
              fontSize: 14,
              fontWeight: 400,
              paddingTop : '11px'
            }}>
            {selectedItem?.share}  
            </Typography>
          </Grid>
          <Grid item xs={6} pt={'25px'}>
            <Typography  sx={{
              color: "#000",
              fontSize: 12,
              fontWeight: 500,
            }}>
            <strong>Administrative Fee</strong> 
            </Typography>
            <Typography  sx={{
              color: "#242424",
              fontSize: 14,
              fontWeight: 400,
              paddingTop : '11px'
            }}>
              {selectedItem?.price}  
            </Typography>
          </Grid>
          <Grid item xs={6} pt={'25px'}>
            <Typography  sx={{
              color: "#000",
              fontSize: 12,
              fontWeight: 500,
            }}>
            <strong>Reference ID </strong>
            </Typography>
            <Typography  sx={{
              color: "#242424",
              fontSize: 14,
              fontWeight: 400,
              paddingTop : '11px'
            }}>
            {selectedItem?.offer}  
            </Typography>
          </Grid>
          <Grid item xs={6} pt={'25px'}>
            <Typography  sx={{
              color: "#000",
              fontSize: 12,
              fontWeight: 500,
            }}>
            <strong>Payment Method</strong>
            </Typography>
            <Typography  sx={{
              color: "#242424",
              fontSize: 14,
              fontWeight: 400,
              paddingTop : '11px'
            }}>
              {selectedItem?.paymentType}   
            </Typography>
          </Grid>
          <Grid item xs={6} pt={'25px'}>
            <Typography  sx={{
              color: "#000",
              fontSize: 12,
              fontWeight: 500,
            }}>
            <strong>Date</strong> 
            </Typography>
            <Typography  sx={{
              color: "#242424",
              fontSize: 14,
              fontWeight: 400,
              paddingTop : '11px'
            }}>
             {selectedItem?.date}   
            </Typography>
          </Grid>
          <Grid item xs={6} pt={'25px'}>
            <Typography  sx={{
              color: "#000",
              fontSize: 12,
              fontWeight: 500,
            }}>
            <strong>Order Status</strong> 
            </Typography>
            <Typography  sx={{
              color: "#242424",
              fontSize: 14,
              fontWeight: 400,
              paddingTop : '11px'
            }}>
            {selectedItem?.status}
            </Typography>
          </Grid>
          <Grid item xs={6} pt={'25px'} alignSelf={'center'}>
            <Button fullWidth  sx={{
              color: "#fff",
              fontSize: 12,
              fontWeight: 500,
              backgroundColor : '#33383D',
              fontFamily : 'poppins, sans-serif',
              display : 'flex',
              alignItems : 'center',
              justifyContent : 'space-between',
              borderRadius : '10px',
              padding : '10px 12px'
            }} className="hero_btn">
            
                Download Receipt  
            <img src={download}></img>
            </Button>
          
     
          </Grid>
        </Grid>
      </Dialog>
    </Box>
  );
}

export default TableComponent;
