import { Box } from "@mui/material";
import InvestmentsComponent from "../components/InvestmentComponent";

const Investment = () => {
  return (
    <Box>
      <InvestmentsComponent />
    </Box>
  );
};

export default Investment;
