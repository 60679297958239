import { FormEvent, useContext, useEffect, useState } from "react";

import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

import carlexus from "../../src/assets/img/car-new.png";
import lexusLogo from "../../src/assets/img/Home/lexusLogo.svg";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import arrow from "../assets/img/cardlistdetail/card_detail_arrow.svg";

import axiosServices from "../hooks/axios";
import snackbar from "../config/snackbar";
import { useSelector } from "../store";
import APIContext from "../context/ApiContext";
import { OfferDataType } from "../types";

function DetailsOfMarketComponent() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const navigate = useNavigate();
  const handleBackClick = () => {
    window.history.back(); // This will navigate back in the browser history
  };

  const { token, investorId, isVerified } = useSelector((state) => state.auth);
  const { createInvestment, setLoading } = useContext(APIContext);

  const [shares] = useState<number>(500);
  const [offerData, setOfferData] = useState<OfferDataType>();
  const [showIframe, setShowIframe] = useState<boolean>(false);
  const formattedAmount = (amount: {
    toLocaleString: (arg0: string) => any;
  }) => {
    return amount.toLocaleString("en-US");
  };

  const handleCreateInvestment = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const req = {
        offering: 148, // your offering ID.
        quantity: shares, // The number of shares to buy.
        investor: investorId, // The investor ID (see /investors/ endpoints).
        from_invoice: true,
        accredited_investor: true,
        net_worth: 0,
        annual_income: 0,
        us_person: true,
        exempt_from_backup_withholding: true,
      };

      const response = await createInvestment(req);

      if (response.data.success) {
        //snackbar("You have invested successfully.");
        setShowIframe(true);
      } else {
        snackbar(response.data.response, "error");
      }

      window.addEventListener("message", (e) => {
        if (
          typeof e.data.target != "undefined" &&
          e.data.target === "issuance"
        ) {
          if (e.data.payload.status === "ready") {
            // @ts-ignore
            const iframe: any = document.querySelector("iframe").contentWindow;
            iframe?.postMessage(
              {
                target: "issuance",
                payload: {
                  investment: response.data.response.id,
                  token: `Token ${token}`,
                  payment_intent_client_secret:
                    response.data.response.payment_intent_client_secret,
                  payment_method_types: "card",
                },
              },
              "*"
            );
          }

          if (e.data.payload.status === "complete") {
            console.log("success!!!");
            //snackbar("You payment successfull.");
            navigate("/profile");
          }
        }
      });
    } catch (error: any) {
      snackbar(error.message, "error");
    }
    setLoading(false);
  };

  const load = async () => {
    try {
      const { data } = await axiosServices.get(`/issuance/offerings/${148}`);
      setOfferData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isVerified) {
      navigate("/verify");
    } else {
      load();
    }
  }, [isVerified]);

  return (
    <Box
      pt={{ xs: "110px", sm: "140px", lg: "50px" }}
      pb={{ xs: "50px", md: "100px" }}
    >
      {!showIframe ? (
        <Container maxWidth="xl" sx={{ marginTop: { lg: 15 } }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              margin: { xs: "0px 0px 30px", xl: "40px 0px" },
              width: "83px",
            }}
            onClick={handleBackClick}
          >
            <img src={arrow} alt="arrow"></img>
            <Typography
              sx={{
                color: "#6B767F",
                fontWeight: 400,
                fontSize: "18px",
                fontFamily: "Poppins, sans-serif !important",
                marginLeft: "15px",
                letterSpacing: "0.14em",
              }}
            >
              BACK
            </Typography>
          </Box>
          {/* mobile view for heading */}

          <Grid item xs={12} sx={{ display: { xs: "block", md: "none" } }}>
            <Box>
              <Typography
                sx={{
                  fontSize: 10,
                  color: "#6B767F",
                  fontWeight: 400,
                  lineHeight: "10px",
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                COLLECTIBLE SUPER CAR
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 36,
                    color: "#242424",
                    fontWeight: 600,
                    lineHeight: "45px",
                    fontFamily: "Poppins, sans-serif",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  Lexus LFA<br></br>
                  2012 Model White
                </Typography>
                <Box
                  component={"img"}
                  src={lexusLogo}
                  sx={{ width: "46px", height: "auto", objectFit: "contain" }}
                  alt="Lexus Logo"
                />
              </Box>
              <Typography
                sx={{
                  fontSize: 16,
                  color: "#242424",
                  fontWeight: 500,
                  lineHeight: "25px",
                  fontFamily: "Poppins, sans-serif",
                  marginBottom: "20px",
                }}
              >
                Manufactured: 2013 - 2015
              </Typography>
            </Box>
          </Grid>
          <Typography
            sx={{
              fontSize: 12,
              lineHeight: "20px",
              color: "#A7AEB4",
              paddingBottom: "10px",
            }}
          >
            Automotive: Super Car / 2012 Lexus LFA
          </Typography>
          <Grid container alignItems="start">
            <Grid item xs={12} md={6}>
              <Box
                component={"img"}
                src={carlexus}
                alt="img"
                sx={{
                  width: { xs: "100%", md: "95%" },
                  height: "auto",
                  borderRadius: "10px",
                }}
              ></Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box sx={{ display: { xs: "none", md: "block" } }}>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "#CBCFD2",
                    fontWeight: 400,
                    lineHeight: "20px",
                    fontFamily: "Poppins, sans-serif",
                    marginBottom: 2,
                  }}
                >
                  COLLECTIBLE SUPER CAR
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 41,
                      color: "#242424",
                      fontWeight: 600,
                      lineHeight: "45px",
                      fontFamily: "Poppins, sans-serif",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    Lexus LFA<br></br>
                    2012 Model White
                  </Typography>
                  <Box
                    component={"img"}
                    src={lexusLogo}
                    sx={{}}
                    alt="Lexus Logo"
                  />
                </Box>
                <Typography
                  sx={{
                    fontSize: 20,
                    color: "#242424",
                    fontWeight: 500,
                    lineHeight: "28px",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Manufactured: 2013 - 2015
                </Typography>
              </Box>

              <Typography
                sx={{
                  fontSize: { xs: 14, md: 16 },
                  color: "#4F575E",
                  fontWeight: 400,
                  lineHeight: { xs: "20px", md: "24px" },
                  fontFamily: "Poppins, sans-serif",
                  marginTop: "24px !important",
                  marginBottom: 0,
                }}
              >
                The Lexus LFA, a masterpiece of automotive engineering and
                design, stands as a pinnacle in the world of collector cars.
                This iconic supercar, limited to just 500 units worldwide, is
                not just a testament to Lexus' commitment to excellence, but
                also a symbol of the rarefied world of luxury automobiles...
                <span style={{ color: "#A0A0A0", cursor: "pointer" }}>
                  read more
                </span>
              </Typography>
              <form onSubmit={handleCreateInvestment}>
                <Grid item md={12}>
                  {/* desktop view button section */}

                  <Grid
                    container
                    mt={"21px"}
                    columnSpacing={2}
                    alignItems="center"
                    sx={{ display: { xs: "none", md: "flex" } }}
                  >
                    <Grid item xs>
                      <Button
                        style={{
                          backgroundColor: "#ef32d9",
                          borderRadius: "5px",
                          width: "100%",
                          padding: "10px 0px",
                        }}
                        type="submit"
                        onClick={() => navigate("/invest")}
                      >
                        <Typography
                          sx={{
                            fontSize: 14,
                            color: "#fff",
                            fontWeight: 400,
                            lineHeight: "22px",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          Invest Now
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item xs>
                      <Button
                        style={{
                          border: "1px solid #CBCFD2",
                          borderRadius: "5px",
                          width: "100%",
                          padding: "9px 0px",
                        }}
                        onClick={() => navigate("/details")}
                      >
                        <Typography
                          sx={{
                            fontSize: 14,
                            color: "#4F575E",
                            fontWeight: 400,
                            lineHeight: "22px",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          Set Reminder
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid container alignItems="start" columnSpacing={"5px"}>
                    <Grid item xs={8} md={12}>
                      <Box
                        sx={{
                          backgroundColor: "#fff",
                          borderRadius: "9px",
                          padding: "17px 9px 9px",
                          marginTop: "23px",
                          border: "1px solid #000",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: 14, md: 19 },
                            color: "#000",
                            fontWeight: 600,
                            lineHeight: "24px",
                            fontFamily: "Poppins, sans-serif",
                            paddingBottom: "3px",
                          }}
                        >
                          $2.34
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { xs: 10, md: 12 },
                            color: "#000",
                            fontWeight: 600,
                            fontFamily: "Inter, sans-serif",
                          }}
                        >
                          Price Per Share
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item pt={{ xs: "23px", md: "6px" }} xs={4} md={3}>
                      <Box
                        sx={{
                          border: "1px solid #242424",
                          borderRadius: "7px",
                          padding: { xs: "17px 9px 9px", md: "17px 9px 7px" },
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: 14, md: 19 },
                            color: "#242424",
                            fontWeight: 600,
                            lineHeight: { xs: "24px", md: "14px" },
                            fontFamily: "Poppins, sans-serif",
                            paddingBottom: "3px",
                          }}
                        >
                          $300K{" "}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { xs: 10, md: 12 },
                            color: "#242424",
                            fontWeight: 600,
                            fontFamily: "Inter, sans-serif",
                          }}
                        >
                          Price Offering
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item pt={"6px"} xs={6} md={3}>
                      <div
                        style={{
                          border: "1px solid #6B767F",
                          borderRadius: "7px",
                          padding: "17px 9px 7px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: 14, md: 19 },
                            color: "#242424",
                            fontWeight: 600,
                            lineHeight: "14px",
                            fontFamily: "Poppins, sans-serif",
                            paddingBottom: "3px",
                          }}
                        >
                          $500{" "}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { xs: 10, md: 12 },
                            color: "#242424",
                            fontWeight: 600,
                            fontFamily: "Inter, sans-serif",
                          }}
                        >
                          Min. Investment
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item pt={"6px"} xs={6}>
                      <div
                        style={{
                          border: "1px solid #6B767F",
                          borderRadius: "7px",
                          padding: "17px 9px 7px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: 14, md: 19 },
                            color: "#242424",
                            fontWeight: 600,
                            lineHeight: "14px",
                            fontFamily: "Poppins, sans-serif",
                            paddingBottom: "3px",
                          }}
                        >
                          130 Total: 30 SBY
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { xs: 10, md: 12 },
                            color: "#242424",
                            fontWeight: 600,
                            fontFamily: "Inter, sans-serif",
                          }}
                        >
                          Rarity (World Wide)
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>

                  {/* mobile view button section */}

                  <Grid
                    container
                    mt={"21px"}
                    columnSpacing={2}
                    alignItems="center"
                    sx={{ display: { xs: "flex", md: "none" } }}
                  >
                    <Grid item xs>
                      <Button
                        style={{
                          backgroundColor: "#ef32d9",
                          borderRadius: "5px",
                          width: "100%",
                          padding: "10px 0px",
                        }}
                        type="submit"
                        onClick={() => navigate("/invest")}
                      >
                        <Typography
                          sx={{
                            fontSize: 14,
                            color: "#fff",
                            fontWeight: 400,
                            lineHeight: "22px",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          Invest Now
                        </Typography>
                      </Button>
                    </Grid>
                    <Grid item xs>
                      <Button
                        style={{
                          border: "1px solid #CBCFD2",
                          borderRadius: "5px",
                          width: "100%",
                          padding: "9px 0px",
                        }}
                        onClick={() => navigate("/details")}
                      >
                        <Typography
                          sx={{
                            fontSize: 14,
                            color: "#4F575E",
                            fontWeight: 400,
                            lineHeight: "22px",
                            fontFamily: "Poppins, sans-serif",
                          }}
                        >
                          Set Reminder
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            </Grid>

            <br />

            <Grid spacing={2} mt={{ xs: "30px", md: "46px" }}>
              <Card
                sx={{
                  padding: "9px",
                  borderRadius: "12px",
                  backgroundColor: "#F7F9FD",
                }}
              >
                <CardActionArea>
                  <CardContent sx={{ padding: "12px" }}>
                    <Typography
                      gutterBottom
                      component="div"
                      sx={{
                        fontSize: 14,
                        color: "#33383D",
                        fontWeight: 700,
                        lineHeight: "25px",
                        fontFamily: "Poppins, sans-serif",
                      }}
                    >
                      OverView
                    </Typography>
                    <Typography
                      gutterBottom
                      component="div"
                      sx={{
                        fontSize: 14,
                        color: "#33383D",
                        fontWeight: 400,
                        lineHeight: "25px",
                        fontFamily: "Poppins, sans-serif",
                        paddingTop: "12px",
                      }}
                    >
                      The Lexus LFA, a masterpiece of automotive engineering and
                      design, stands as a pinnacle in the world of collector
                      cars. This iconic supercar, limited to just 500 units
                      worldwide, is not just a testament to Lexus' commitment to
                      excellence, but also a symbol of the rarefied world of
                      luxury automobiles. <br /> <br />
                      Crafted with meticulous attention to detail, the LFA was
                      introduced in 2010 after nearly a decade of development.
                      Its 4.8-liter V10 engine, a result of Lexus' collaboration
                      with Yamaha, produces an astonishing 552 horsepower,
                      propelling the car from 0 to 60 mph in just 3.6 seconds.
                      The engine's unique sound, often compared to a Formula 1
                      car, is a symphony to the ears of automotive enthusiasts.{" "}
                      <br /> <br />
                      But what sets the LFA apart is not just its performance,
                      but its construction. The car's frame is made entirely of
                      carbon fiber-reinforced polymer (CFRP), making it
                      incredibly lightweight yet exceptionally strong. This was
                      a pioneering use of CFRP in the automotive industry,
                      showcasing Lexus' innovation. <br /> <br />
                      The LFA's rarity is another factor that makes it a prized
                      possession for collectors. With only 500 ever made, each
                      LFA is virtually unique, especially considering the level
                      of customization offered to each original buyer. Colors,
                      interiors, and even the type of leather were tailored to
                      individual tastes, making every LFA a personal statement
                      of its owner. <br /> <br />
                      Furthermore, the LFA's value has appreciated over time.
                      Originally sold at around $375,000, its value has
                      increased significantly, with some models being sold for
                      well over the original price. This appreciation is a
                      testament to the LFA's desirability and its status as a
                      modern classic.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Container>
      ) : (
        <Container>
          <Stack pt={5}>
            <Typography sx={{ fontWeight: 600, color: "white" }} variant="h4">
              Payment
            </Typography>
          </Stack>

          <Stack py={10} justifyContent="center" alignItems="center">
            <iframe
              style={{ height: "1100px", width: "100%", border: "none" }}
              src="https://demo.issuance.com/invest_thirdparty"
              id="iframe"
              title="iframe"
              allow="payment *"
            />
          </Stack>
        </Container>
      )}
    </Box>
  );
}

export default DetailsOfMarketComponent;
