import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect } from "react";
import banner from "../assets/img/contact/contact_banner.png";
import email from "../assets/img/contact/email_c.svg";
import phone from "../assets/img/contact/phone_c.svg";
import office from "../assets/img/contact/office_c.svg";
import social from "../assets/img/contact/social_c.svg";
import Facebook from "../assets/img/contact/Facebook.svg";
import Twitter from "../assets/img/contact/Twitter.svg";
import Instagram from "../assets/img/contact/Instagram.svg";
import LinkedIn from "../assets/img/contact/LinkedIn.svg";

function Contact() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const style = {
    "& label.Mui-focused": {
      color: "#D5DAE1 !important",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#D5DAE1 !important",
    },
    "& .MuiInputLabel-root": {
      color: "#667085 !important",

    },
    "& .MuiOutlinedInput-input": {
      color: "#667085 !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#D5DAE1 !important",
        border: "1px solid #D5DAE1 !important",
        borderRadius: "8px !important",

      },
      "&:hover fieldset": {
        borderColor: "#D5DAE1 !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#D5DAE1 !important",
      },
    },
  };
  return (
    <Box>
      {/*------------------ Hero section -------------------------*/}

      <Box
        pt={{ xs: '41px' , md: "145px" }}
        pb={{ xs: '71px' , md: "145px" }}
        sx={{
          backgroundImage: `url(${banner})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          borderBottomLeftRadius: "32px",
          borderBottomRightRadius: "32px",
          marginTop: {xs : "65px" , sm: '100px'},
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            color: "#4F575E",
            fontWeight: 600,
            fontFamily: "Poppins, sans-serif",
            textAlign: "center",
            lineHeight: "15px",
            backgroundColor: "#fff",
            borderRadius: "36px",
            padding: "4px 12px",
            maxWidth: "114px",
            margin: {xs: "0px auto 21px" , md: "0px auto 36px"},
          }}
        >
          GET IN TOUCH
        </Typography>
        <Typography
          sx={{
            fontSize: {xs: "24px" , md: '48px'},
            color: "#fff",
            fontWeight: 600,
            fontFamily: "Poppins, sans-serif",
            textAlign: "center",
            lineHeight: {xs: '30px', md: "56px"},
          }}
        >
          Let’s Stay Connected
        </Typography>
        <Typography
          sx={{
            fontSize:{xs: '12px' , md: "16px"},
            color: "#CBCFD2",
            fontWeight: 400,
            fontFamily: "Poppins, sans-serif",
            textAlign: "center",
            lineHeight: {xs: "18px" , md: '24px'},
            paddingTop: {xs: "14px" , md: '24px'},
            maxWidth: { xs : '320px', md:"580px"},
            margin: "auto",
          }}
        >
          In a digital age of limitless possibilities, we embarked on a quest to
          redefine the standard of luxury cars and artwork ownership.
        </Typography>
      </Box>

      <Container maxWidth="xl" >
        <Stack sx={{ padding: {xs: '41px 0px' ,md:"130px 0px"} }}>
        <Grid container alignItems={"start"}>
          {/*------------------ Connected section -------------------------*/}

          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={6}>
                <img src={email}></img>
                <Typography
                  sx={{
                    fontSize: {xs: 21 ,md: 30},
                    color: "#2A3342",
                    fontWeight: 600,
                    lineHeight: {xs: '31px' , md:"39px"},
                    fontFamily: "Poppins, sans-serif",
                    margin: {xs:'20px 0px 8px' ,md:"24px 0px 24px"},
                  }}
                >
                  Email
                </Typography>
                <Link
                  href="mailto:info@mcqueen.io"
                  sx={{
                    fontSize: {xs:16 ,md: 20},
                    color: "#556987",
                    fontWeight: 500,
                    lineHeight: {xs:'24px' ,md:"30px"},
                    fontFamily: "Poppins, sans-serif",
                    textDecoration : 'none'
                  }}
                >
                  info@mcqueen.io
                </Link>
              </Grid>
              <Grid item xs={6}>
                <img src={phone}></img>
                <Typography
                  sx={{
                    fontSize: {xs: 21 ,md: 30},
                    color: "#2A3342",
                    fontWeight: 600,
                    lineHeight: {xs: '31px' , md:"39px"},
                    fontFamily: "Poppins, sans-serif",
                    margin: {xs:'20px 0px 8px' ,md:"24px 0px 24px"},
                  }}
                >
                  Phone
                </Typography>
                <Link
                  href="tel:+7-843-672-431"
                  sx={{
                    fontSize: {xs:16 ,md: 20},
                    color: "#556987",
                    fontWeight: 500,
                    lineHeight: {xs:'24px' ,md:"30px"},
                    fontFamily: "Poppins, sans-serif",
                    textDecoration : 'none'
                  }}
                >
                  +7-843-672-431
                </Link>
              </Grid>
              <Grid item xs={6} mt={{xs:'32px' ,md:"57px"}}>
                <img src={office}></img>
                <Typography
                  sx={{
                    fontSize: {xs: 21 ,md: 30},
                    color: "#2A3342",
                    fontWeight: 600,
                    lineHeight: {xs: '31px' , md:"39px"},
                    fontFamily: "Poppins, sans-serif",
                    margin: {xs:'20px 0px 8px' ,md:"24px 0px 24px"},
                  }}
                >
                  Office
                </Typography>
                <Typography
                  sx={{
                    fontSize: {xs:16 ,md: 20},
                    color: "#556987",
                    fontWeight: 500,
                    lineHeight: {xs:'24px' ,md:"30px"},
                    fontFamily: "Poppins, sans-serif",
                    textDecoration : 'none'
                  }}
                >
                  Fort Lauderdale, USA
                </Typography>
              </Grid>
              <Grid item xs={6} mt={{xs:'32px' ,md:"57px"}}>
                <img src={social}></img>
                <Typography
                  sx={{
                    fontSize: {xs: 21 ,md: 30},
                    color: "#2A3342",
                    fontWeight: 600,
                    lineHeight: {xs: '31px' , md:"39px"},
                    fontFamily: "Poppins, sans-serif",
                    margin: {xs:'20px 0px 8px' ,md:"24px 0px 24px"},
                  }}
                >
                  Socials
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>

                <a href="https://www.facebook.com/mcqueenlifestyle"  target="_blank" rel="noreferrer" >
                  <Box component={'img'} src={Facebook} sx={{ cursor: "pointer" }}></Box>
                  </a>

                  <a href="https://x.com/ownmcqueen" target="_blank" rel="noreferrer">
                  <Box component={'img'} src={Twitter} sx={{ margin: {xs: '0px 20px' , md:"0px 33px"}, cursor: "pointer" }}></Box>
                  </a>

                  <a href="https://www.instagram.com/mcqueenlifestyle/" target="_blank" rel="noreferrer">
                  <Box component={'img'} src={Instagram} sx={{ cursor: "pointer" }}></Box>
                  </a>

                  <a href="https://www.linkedin.com/company/mcqueen-lifestyle/"  target="_blank" rel="noreferrer">
                  <Box component={'img'} src={LinkedIn} sx={{ marginLeft: {xs: '20px' , md:"33px"}, cursor: "pointer" }}></Box>
                  </a>
                  
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {/*------------------ Form section -------------------------*/}

          <Grid item xs={12} md={6} mt={{ xs: '52px' , md : '0px'}}>
            <Box
              sx={{
                borderRadius: "6px",
                padding: {xs:'24px' , md:"41px"},
                maxWidth: {md :"570px"},
                marginLeft: "auto",
              }}
              className="ownerection"
            >
              <form>
                <Typography
                  style={{
                    fontSize: "16px",
                    color: "#333F51",
                    fontWeight: 500,
                    fontFamily: "Poppins, sans-serif",
                    lineHeight: "24px",
                    marginBottom: "6px",
                  }}
                >
                  Email
                </Typography>
                <TextField
                  sx={style}
                  required
                  fullWidth
                  placeholder="Enter your email"
                ></TextField>

                <Typography
                  style={{
                    fontSize: "16px",
                    color: "#333F51",
                    fontWeight: 500,
                    fontFamily: "Poppins, sans-serif",
                    lineHeight: "24px",
                    marginBottom: "6px",
                    marginTop: "24px",
                  }}
                >
                  Message
                </Typography>
                <TextField
                  sx={style}
                  multiline
                  rows={8}
                  maxRows={8}
                  required
                  fullWidth
                  placeholder="Enter your message..."
                ></TextField>
                <Button
                  sx={{
                    fontSize: {xs: '12px' ,md:"18px"},
                    color: "#F0FDF4",
                    fontWeight: 500,
                    fontFamily: "Poppins, sans-serif",
                    lineHeight: {xs:'17px' ,md:"28px"},
                    backgroundColor: "#EF32D9",
                    padding: {xs: '9px 0px' ,md:"14px 0px"},
                    width: "100%",
                    borderRadius: "6px",
                    marginTop: "24px",
                  }}
                  type="submit"
                  className="receivebutton"
                >
                  Send
                </Button>
              </form>
            </Box>
          </Grid>
        </Grid>
        </Stack>
      </Container>
    </Box>
  );
}

export default Contact;