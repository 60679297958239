import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Container, Menu, MenuItem, Stack } from "@mui/material";

import LogoImg from "../assets/img/logo.svg";
import burger_icon from "../assets/img/Home/burger_icon.png";
import user_ic from "../assets/img/Home/user_ic.png";
import close_shape from "../assets/img/Home/close_shape.png";

import { dispatch, useSelector } from "../store";
import { Logout } from "../store/reducers/auth";
import { expireToken } from "../helpers";
import snackbar from "../config/snackbar";
const Header = ({ Menubar, setMenubar }: any) => {
  const navigate = useNavigate();
  const { user, isLoggedIn, username, expiry }: any = useSelector(
    (state) => state.auth
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    const data: any = { status: false };
    dispatch(Logout(data));
    window.location.href = "/sign-in";
  };

  useEffect(() => {
    if (expireToken(expiry) && isLoggedIn) {
      handleLogout();
      //snackbar("Token was Expired", "error");
    }
  }, [expiry, isLoggedIn]);

  return (
    <Box className="header">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            component="img"
            src={LogoImg}
            sx={{
              cursor: "pointer",
              width: { xs: "150px", md: "170px", lg: "auto" },
            }}
            onClick={() => navigate("/")}
            alt="img"
          />
          <Stack
            direction="row"
            sx={{
              display: { xs: "flex", md: "none", lg: "none" },
              alignItems: "center",
            }}
          >
            {isLoggedIn && (
              <Button
                sx={{
                  fontWeight: 600,
                  color: "#F765B8",
                  border: "2px solid white",
                  borderRadius: 0,
                }}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <Box
                  component="img"
                  src={user_ic}
                  sx={{ cursor: "pointer" }}
                  alt="img"
                />
              </Button>
            )}
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  navigate("/profile");
                }}
                sx={{ minWidth: "200px" }}
              >
                Profile
              </MenuItem>

              <MenuItem
                onClick={() => {
                  handleClose();
                  handleLogout();
                }}
                sx={{ minWidth: "200px" }}
              >
                Logout
              </MenuItem>
            </Menu>
            {Menubar ? (
              <Box
                component="img"
                src={close_shape}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setMenubar(!Menubar);
                }}
                alt="img"
              />
            ) : (
              <Box
                component="img"
                src={burger_icon}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  setMenubar(!Menubar);
                }}
                alt="img"
              />
            )}
          </Stack>
          <Stack
            direction="row"
            spacing={3}
            sx={{ display: { xs: "none", md: "flex", lg: "flex" } }}
          >
            <Button
              sx={{
                fontWeight: 400,
                color: "#242424",
                fontFamily: "Poppins, sans-serif",
              }}
              onClick={() => navigate("/")}
            >
              Home
            </Button>
            <Button
              sx={{
                fontWeight: 400,
                color: "#242424",
                fontFamily: "Poppins, sans-serif",
              }}
              onClick={() => navigate("/market")}
            >
              Marketplace
            </Button>
            <Button
              sx={{
                fontWeight: 400,
                color: "#242424",
                fontFamily: "Poppins, sans-serif",
              }}
              onClick={() => navigate("/faq")}
            >
              FAQs
            </Button>
            <Button
              sx={{
                fontWeight: 400,
                color: "#242424",
                fontFamily: "Poppins, sans-serif",
              }}
              onClick={() => navigate("/contact")}
            >
              Contact
            </Button>
            {isLoggedIn ? (
              <>
                <Button
                  sx={{
                    fontWeight: 600,
                    fontSize: 12,
                    color: "#F765B8",
                    border: "1px solid #F765B8",
                    backgroundColor: "white",
                    borderRadius: "6px !important",
                    padding: "10px 30px !important",
                    marginLeft: "50px !important",
                    fontFamily: "Poppins, sans-serif",
                  }}
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  {username.split(" ").length > 2
                    ? username.split("  ")[0]
                    : username}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      navigate("/profile");
                    }}
                    sx={{ minWidth: "200px" }}
                  >
                    Profile
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      handleClose();
                      handleLogout();
                    }}
                    sx={{ minWidth: "200px" }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <>
                <Button
                  onClick={() => navigate("/sign-up")}
                  sx={{
                    fontWeight: 400,
                    fontSize: 12,
                    color: "#fff",
                    border: "1px solid rgba(255, 255, 255, 1)",
                    backgroundColor: "#33383D",
                    borderRadius: "6px !important",
                    padding: "10px 30px !important",
                    marginLeft: "50px !important",
                    fontFamily: "Poppins, sans-serif",
                  }}
                  className="signupbutton"
                >
                  Sign Up
                </Button>
                <Button
                  sx={{
                    fontWeight: 400,
                    fontSize: 12,
                    color: "#242424",
                    border: "1px solid #242424",
                    backgroundColor: "#fff",
                    borderRadius: "6px !important",
                    padding: "10px 39px !important",
                    marginLeft: "14px !important",
                    fontFamily: "Poppins, sans-serif",
                  }}
                  onClick={() => navigate("/sign-in")}
                  className="loginbutton"
                >
                  Login
                </Button>
              </>
            )}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default Header;
