import { Box } from "@mui/material";
import LogInComponent from "../components/LogInComponent";

const LogIn = () => {
  return (
    <Box>
      <LogInComponent />
    </Box>
  );
};

export default LogIn;