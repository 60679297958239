import { Box, Container, Grid, Typography } from "@mui/material";

import earth from "../assets/img/Footer/earth.svg";
import bulb from "../assets/img/Footer/bulb.svg";
import Info from "../assets/img/Footer/Info.svg";
import UserCircle from "../assets/img/Footer/UserCirclePlus.svg";
import phone from "../assets/img/Footer/phone.svg";
import brush from "../assets/img/Footer/brush.svg";
import CarSimple from "../assets/img/Footer/CarSimple.svg";
import Fire from "../assets/img/Footer/Fire.svg";
import footer_f from "../assets/img/Footer/facebook.png";
import footer_t from "../assets/img/Footer/twitter.png";
import footer_l from "../assets/img/Footer/linkedin.png";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <Box
      pt={{xs: window.location.pathname === '/about-us' || window.location.pathname === '/faq' ? '140px' :"41px" , md: '162px'}}
      // pb={{xs: "41px" , md: '139px'}}
      sx={{
        backgroundColor: "#242424",
        borderRadius: "32px 32px 0px 0px",
        zIndex: 10,
      }}
    >
      <Container maxWidth="xl">
        <Grid container alignItems="start">
          <Grid item xs={12} sm={6}>
            <Typography
              sx={{
                fontSize: 20,
                color: "#fff",
                fontWeight: 600,
                lineHeight: "30px",
                fontFamily: "Poppins, sans-serif",
                paddingBottom: "16px",
              }}
            >
              Stay in the loop
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                color: "#CBCFD2",
                fontWeight: 400,
                lineHeight: "20px",
                fontFamily: "Poppins, sans-serif",
                maxWidth: "362px",
              }}
            >
              Follow us on social media to stay in the loop with our newest
              feature releases, drops, and tips and tricks for navigating our
              revolutionary platform.
            </Typography>
            <Box
              sx={{ display: "flex", alignItems: "center", marginTop: {xs: "30px" , md: '60px'} }}
            >
              <a href="https://www.facebook.com/mcqueenlifestyle" target="_blank">
              <img src={footer_f}  alt="Facebook"></img>
              </a>
              <a href="https://x.com/ownmcqueen" target="_blank">
              <img src={footer_t} style={{ margin: "12px" }} alt="X"></img>
              </a>
              <a href="https://www.linkedin.com/company/mcqueen-lifestyle/" target="_blank">
              <img src={footer_l} alt="LinkedIn"></img>
              </a>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} >
            <Box sx={{ marginTop : {xs: '45px' , sm: '0px'}}}>
              <Grid container alignItems="start" >
                <Grid item xs={6} md={4}>
                  <Typography
                    sx={{
                      fontSize: 20,
                      color: "#fff",
                      fontWeight: 600,
                      lineHeight: "30px",
                      fontFamily: "Poppins, sans-serif",
                      paddingBottom: "18px",
                    }}
                  >
                    Navigation
                  </Typography>
                  
                    <Typography
                    onClick={() => navigate("/about-us")}
                      sx={{
                        fontSize: 14,
                        color: "#CBCFD2",
                        fontWeight: 400,
                        lineHeight: "20px",
                        fontFamily: "Poppins, sans-serif",
                        display: "flex",
                        alignItems: "center",
                        padding: "8px 0px",
                        cursor: "pointer",
                      }}
                    >
                      <span style={{ minWidth: "20px" }}>
                        <img
                          src={earth}
                          alt="earth"
                          style={{ paddingRight: "12px" }}
                        ></img>
                      </span>{" "}
                      About Us
                    </Typography>
                  
                    <Typography
                    onClick={() => navigate("/how-it-works")}
                      sx={{
                        fontSize: 14,
                        color: "#CBCFD2",
                        fontWeight: 400,
                        lineHeight: "20px",
                        fontFamily: "Poppins, sans-serif",
                        display: "flex",
                        alignItems: "center",
                        padding: "8px 0px",
                        cursor: "pointer",
                      }}
                    >
                      <span style={{ minWidth: "20px" }}>
                        <img
                          src={bulb}
                          alt="bulb"
                          style={{ paddingRight: "12px" }}
                        ></img>
                      </span>{" "}
                      How it Works
                    </Typography>
                  
                    <Typography
                    onClick={() => navigate("/faq")}
                      sx={{
                        fontSize: 14,
                        color: "#CBCFD2",
                        fontWeight: 400,
                        lineHeight: "20px",
                        fontFamily: "Poppins, sans-serif",
                        display: "flex",
                        alignItems: "center",
                        padding: "8px 0px",
                        cursor: "pointer",
                      }}
                    >
                      <span style={{ minWidth: "20px" }}>
                        <img
                          src={Info}
                          alt="info"
                          style={{ paddingRight: "12px" }}
                        ></img>
                      </span>{" "}
                      FAQ’s
                    </Typography>
                  
                  
                  <Typography
                  onClick={() => navigate("/contact")}
                    sx={{
                      fontSize: 14,
                      color: "#CBCFD2",
                      fontWeight: 400,
                      lineHeight: "20px",
                      fontFamily: "Poppins, sans-serif",
                      display: "flex",
                      alignItems: "center",
                      padding: "8px 0px",
                      cursor: "pointer",
                    }}
                  >
                    <span style={{ minWidth: "20px" }}>
                      <img
                        src={phone}
                        alt="phone"
                        style={{ paddingRight: "12px" }}
                      ></img>
                    </span>{" "}
                    Contact Us
                  </Typography>
                </Grid>
                <Grid item  md={4} sx={{display : {xs : 'none' , md: 'block'}}}>
                  <Typography
                    sx={{
                      fontSize: 20,
                      color: "#fff",
                      fontWeight: 600,
                      lineHeight: "30px",
                      fontFamily: "Poppins, sans-serif",
                      paddingBottom: "18px",
                    }}
                  >
                    Asset Classes
                  </Typography>
                  <Typography
                  onClick={() => navigate("/market")}
                    sx={{
                      fontSize: 14,
                      color: "#CBCFD2",
                      fontWeight: 400,
                      lineHeight: "20px",
                      fontFamily: "Poppins, sans-serif",
                      display: "flex",
                      alignItems: "center",
                      padding: "8px 0px",
                      cursor: "pointer",
                    }}
                  >
                    <span style={{ minWidth: "20px" }}>
                      <img
                        src={brush}
                        alt="brush"
                        style={{ paddingRight: "12px" }}
                      ></img>
                    </span>{" "}
                    Artwork
                  </Typography>
                  <Typography
                  onClick={() => navigate("/market")}
                    sx={{
                      fontSize: 14,
                      color: "#CBCFD2",
                      fontWeight: 400,
                      lineHeight: "20px",
                      fontFamily: "Poppins, sans-serif",
                      display: "flex",
                      alignItems: "center",
                      padding: "8px 0px",
                      cursor: "pointer",
                    }}
                  >
                    <span style={{ minWidth: "20px" }}>
                      <img
                        src={CarSimple}
                        alt="car"
                        style={{ paddingRight: "12px" }}
                      ></img>
                    </span>{" "}
                    Automotive
                  </Typography>
                  
                </Grid>
                <Grid item xs={6} md={4}>
                  <Typography
                    sx={{
                      fontSize: 20,
                      color: "#fff",
                      fontWeight: 600,
                      lineHeight: "30px",
                      fontFamily: "Poppins, sans-serif",
                      paddingBottom: "18px",
                    }}
                  >
                    Legal
                  </Typography>
                  
                  <Typography
                  onClick={() => navigate("/termsofuse")}
                    sx={{
                      fontSize: 14,
                      color: "#CBCFD2",
                      fontWeight: 400,
                      lineHeight: "20px",
                      fontFamily: "Poppins, sans-serif",
                      display: "flex",
                      alignItems: "center",
                      padding: "8px 0px",
                      cursor: "pointer",
                    }}
                  >
                    <span style={{ minWidth: "20px" }}>
                      <img
                        src={earth}
                        alt="earth"
                        style={{ paddingRight: "12px" }}
                      ></img>
                    </span>{" "}
                    Terms of Use
                  </Typography>
                  
                  
                  <Typography
                  onClick={() => navigate("/privacy-policy")}
                    sx={{
                      fontSize: 14,
                      color: "#CBCFD2",
                      fontWeight: 400,
                      lineHeight: "20px",
                      fontFamily: "Poppins, sans-serif",
                      display: "flex",
                      alignItems: "center",
                      padding: "8px 0px",
                      cursor: "pointer",
                    }}
                  >
                    <span style={{ minWidth: "20px" }}>
                      <img
                        src={bulb}
                        alt="bulb"
                        style={{ paddingRight: "12px" }}
                      ></img>
                    </span>{" "}
                    Privacy Policy
                  </Typography>
                  
                  
                  <Typography
                  onClick={() => navigate("/cookie-policy")}
                    sx={{
                      fontSize: 14,
                      color: "#CBCFD2",
                      fontWeight: 400,
                      lineHeight: "20px",
                      fontFamily: "Poppins, sans-serif",
                      display: "flex",
                      alignItems: "center",
                      padding: "8px 0px",
                      cursor: "pointer",
                    }}
                  >
                    <span style={{ minWidth: "20px" }}>
                      <img
                        src={Info}
                        alt="info"
                        style={{ paddingRight: "12px" }}
                      ></img>
                    </span>{""}
                    Cookie Policy
                  </Typography>
                  
                  
                  <Typography
                  onClick={() => navigate("/disclosure")}
                    sx={{
                      fontSize: 14,
                      color: "#CBCFD2",
                      fontWeight: 400,
                      lineHeight: "20px",
                      fontFamily: "Poppins, sans-serif",
                      display: "flex",
                      alignItems: "center",
                      padding: "8px 0px",
                      cursor: "pointer",
                    }}
                  >
                    <span style={{ minWidth: "20px" }}>
                      <img
                        src={UserCircle}
                        alt="user"
                        style={{ paddingRight: "12px" }}
                      ></img>
                    </span>{" "}
                    Disclosure
                  </Typography>
                  
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{borderTop : '1px solid #fff' ,  marginTop : {xs: "41px" , md: '139px'}, paddingBottom : '41px'}}>
          <Typography sx={{
                fontSize: 14,
                color: "#CBCFD2",
                fontWeight: 400,
                fontFamily: "Poppins, sans-serif",
                paddingTop : '27px',
                textAlign : 'center'
              }}>
          Copyright © 2024 McQueen Labs
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;