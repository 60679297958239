import { FormEvent, useContext, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ArrowIcon from "../assets/img/icons/arrow.svg";
import CheckIcon from "../assets/img/icons/check.svg";
import ArrowLeftIcon from "../assets/img/icons/arrow-left.svg";
import snackbar from "../config/snackbar";
import APIContext from "../context/ApiContext";
import { useNavigate } from "react-router-dom";
import { dispatch, useSelector } from "../store";
import { UpdateInvester } from "../store/reducers/auth";
import e from "express";
import React from "react";
import { MuiTelInput } from "mui-tel-input";
import { profile } from "console";
import Dropdown from "../assets/img/Dropdown.png";
import { States } from "../config";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const style = {
  "& label.Mui-focused": {
    color: "#232632 !important",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#232632 !important",
  },
  "& .MuiInputLabel-root": {
    color: "#232632 !important",
  },
  "& .MuiOutlinedInput-input": {
    color: "#232632 !important",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#232632 !important",
      border: "2px solid #232632 !important",
      borderRadius: "10px !important",
    },
    "&:hover fieldset": {
      borderColor: "#232632 !important",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#232632 !important",
    },
  },
};

const VerifyComponent = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = React.useState("");

  const handleChange = (newPhone: React.SetStateAction<string>) => {
    setPhone(newPhone);
  };
  const [nameError, setNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [birthday, setBirthday] = useState(dayjs(new Date()));
  const [birthdayError, setBirthdayError] = useState(false);

  const { createInvestor, loading, setLoading } = useContext(APIContext);
  const { user }: any = useSelector((state) => state.auth);
  const [type, setType] = useState<string>("person");
  const [status, setStatus] = useState<boolean>(false);
  const { usern, isLoggedIn, username, expiry }: any = useSelector(
    (state) => state.auth
  );

  const formatDate = (date: string) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const [investor, setInvestor] = useState({
    first_name: username.split("  ")[0],
    last_name: username.split("  ")[1],
    email: user.email,
    citizenship: "United States",
    date_of_birth: formatDate(dayjs(new Date()).toString()),
    phone: "",
    street_address_1: "",
    street_address_2: "",
    city: "AR",
    postal_code: "",
    state: "AR",
    region: "",
    country: "US",
    tax_id_number: "",
  });

  const handleCreateInvestor = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!status) {
      setStatus(!status);
      return;
    }
    setLoading(true);

    try {
      const data = await createInvestor({
        type,
        entity: investor,
      });

      if (data.investor_id) {
        dispatch(UpdateInvester({ investor_id: data.investor_id }));
        //snackbar("Successfully Verified.");
        navigate("/market");
      } else {
        navigate("/market");
        snackbar(data.response, "error");
      }
    } catch (error: any) {
      navigate("/market");
      snackbar(error.message, "error");
    }
    setLoading(false);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    const isValid = /^[a-zA-Z\s-]+$/.test(newName);
    const isLengthValid = newName.length <= 25;

    setInvestor({
      ...investor,
      first_name: newName,
    });

    setNameError(!isValid || !isLengthValid);
  };

  const handleBirthdayChange = (event: any) => {
    setBirthday(dayjs(event));
    setInvestor({
      ...investor,
      date_of_birth: formatDate(dayjs(event).toString()),
    });
    console.log(validateBirthday(formatDate(dayjs(event).toString())), "123");
    setBirthdayError(!validateBirthday(formatDate(dayjs(event).toString())));
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newName = event.target.value;
    const isValid = /^[a-zA-Z\s-]+$/.test(newName);
    const isLengthValid = newName.length <= 25;
    setInvestor({
      ...investor,
      last_name: newName,
    });
    setLastNameError(!isValid || !isLengthValid);
  };

  const validateBirthday = (birthday: string) => {
    let dateParts = birthday.split("/");
    let year = parseInt(dateParts[2]);
    let month = parseInt(dateParts[1]);
    let day = parseInt(dateParts[0]);

    if (isNaN(year) || isNaN(month) || isNaN(day)) {
      return false;
    }

    if (month < 1 || month > 12) {
      return false;
    }

    if (day < 1 || day > 31) {
      return false;
    }

    if (month == 2 && day > 28) {
      return false;
    }

    if (month == 4 || month == 6 || month == 9 || (month == 11 && day > 30)) {
      return false;
    }

    if (year < 1900 || year > 2024) {
      return false;
    }

    if (new Date(year, month - 1, day) > new Date()) {
      return false;
    }

    if (year < 1900 || year > 2024) {
      return false;
    }

    return true;
  };
  return (
    <Box>
      <Container>
        <Stack pt={5}>
          <Stack p={0.5} sx={{ background: "#334155" }}>
            <Box
              sx={{
                background: "#29FFF5",
                height: "10px",
                width: status ? "100%" : "50%",
              }}
            ></Box>
          </Stack>

          {status ? (
            <form onSubmit={handleCreateInvestor}>
              <Stack pt={{ xs: 5, md: 14 }}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: "#04091E",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: { xs: 24, md: 48 },
                    lineHeight: { xs: "24px", md: "48px" },
                  }}
                >
                  Investor Profile Setup
                </Typography>
                <Typography sx={{ color: "#33383D", paddingTop: "5px" }}>
                  We are now going to verify your details with an external
                  agency.  Please note that this is not a credit check, and will
                  not affect your credit history. Please fill in the form for
                  instant verification.
                </Typography>
              </Stack>

              <Stack pt={{ xs: 5 }} pb={{ xs: 5, md: 20 }}>
                <Grid container spacing={{ xs: 2, md: 5 }}>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <TextField
                        id="outlined-basic"
                        label="Address"
                        variant="outlined"
                        required
                        className="input"
                        placeholder="Enter your address"
                        value={investor.street_address_1}
                        onChange={(e) =>
                          setInvestor({
                            ...investor,
                            street_address_1: e.target.value,
                          })
                        }
                        sx={style}
                        fullWidth
                        inputProps={{
                          style: { color: "#191B23" },
                        }}
                        autoComplete="off"
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={6}>
                    <Stack spacing={1}>
                      <TextField
                        id="outlined-basic"
                        label="Zip / Postal Code"
                        variant="outlined"
                        required
                        className="input"
                        placeholder="12345"
                        value={investor.postal_code}
                        onChange={(e) =>
                          setInvestor({
                            ...investor,
                            postal_code: e.target.value,
                          })
                        }
                        sx={style}
                        fullWidth
                        inputProps={{
                          style: { color: "#191B23" },
                        }}
                        autoComplete="off"
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={6} sx={{ position: "relative" }}>
                    <Stack spacing={1}>
                      <TextField
                        id="outlined-basic"
                        label="State"
                        variant="outlined"
                        required
                        placeholder="Select"
                        className="input"
                        value={investor.state}
                        onChange={(e) =>
                          setInvestor({ ...investor, state: e.target.value })
                        }
                        sx={style}
                        fullWidth
                        inputProps={{
                          style: { color: "#191B23" },
                        }}
                        autoComplete="off"
                        select
                      >
                        {States?.map((state, index) => (
                          <MenuItem key={index} value={state.symbol}>
                            {state.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Stack>
                    <Box
                      src={Dropdown}
                      component={"img"}
                      sx={{
                        position: "absolute",
                        right: "20px",
                        top: { xs: "40px", md: "60px" },
                      }}
                    ></Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Stack spacing={1}>
                      <TextField
                        id="outlined-basic"
                        label="Country"
                        variant="outlined"
                        required
                        placeholder="Select"
                        className="input"
                        value={investor.country}
                        onChange={(e) =>
                          setInvestor({
                            ...investor,
                            country: e.target.value,
                          })
                        }
                        sx={style}
                        fullWidth
                        inputProps={{
                          style: { color: "#191B23" },
                        }}
                        autoComplete="off"
                        select
                      >
                        <MenuItem value="US">United States</MenuItem>
                      </TextField>
                    </Stack>
                  </Grid>

                  <Grid item xs={6}>
                    <Stack spacing={1}>
                      <MuiTelInput
                        value={phone}
                        id="outlined-basic"
                        label="Phone number"
                        variant="outlined"
                        onChange={handleChange}
                        defaultCountry="US"
                        sx={style}
                      />
                      {/* <TextField
                        required
                        className="input"
                        placeholder="(123)456-7890"
                        value={investor.phone}
                        onChange={(e) =>
                          setInvestor({
                            ...investor,
                            phone: e.target.value,
                          })
                        }
                      ></TextField> */}
                      {/* <TextField
                        required
                        className="input"
                        placeholder="(123)456-7890"
                        value={investor.phone}
                        onChange={(e) =>
                          setInvestor({
                            ...investor,
                            phone: e.target.value,
                          })
                        }
                      ></TextField> */}
                    </Stack>
                  </Grid>

                  <Grid item xs={6}>
                    <Stack spacing={1}>
                      <TextField
                        id="outlined-basic"
                        label="Email address"
                        variant="outlined"
                        sx={style}
                        required
                        type="email"
                        className="input"
                        placeholder="joe.smith@email.com"
                        value={investor.email}
                        onChange={(e) =>
                          setInvestor({
                            ...investor,
                            email: e.target.value,
                          })
                        }
                      ></TextField>
                    </Stack>
                  </Grid>

                  <Grid item xs={6}>
                    <Stack spacing={1}>
                      <TextField
                        id="outlined-basic"
                        label="Social Security Number (SSN)"
                        variant="outlined"
                        sx={style}
                        required
                        className="input"
                        placeholder="XXX - XX - XXXX"
                        value={investor.tax_id_number}
                        // onChange={(e) =>
                        //   setInvestor({
                        //     ...investor,
                        //     tax_id_number: e.target.value,
                        //   })
                        //   }
                        onChange={(e) => {
                          let inputValue = e.target.value;
                          inputValue = inputValue.replace(/[^\d]/g, "");
                          if (inputValue.length <= 9) {
                            inputValue = inputValue.replace(
                              /(\d{3})(\d{2})(\d{4})/,
                              "$1-$2-$3"
                            );
                            setInvestor({
                              ...investor,
                              tax_id_number: inputValue,
                            });
                          }
                        }}
                      ></TextField>
                    </Stack>
                  </Grid>

                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      size="large"
                      sx={{
                        background: "#2D2F30",
                        color: "white",
                        fontWeight: 600,
                        padding: "10px 0px",
                      }}
                      onClick={() => setStatus(false)}
                      className="prevoiusbutton"
                    >
                      Previous
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      size="large"
                      sx={{
                        background: "#EF32D9",
                        color: "white",
                        fontWeight: 600,
                        "&:hover": {
                          background: "#EF32D9",
                        },
                      }}
                      variant="contained"
                      disabled={loading}
                      type="submit"
                    >
                      {loading ? (
                        <CircularProgress
                          size={25}
                          color="inherit"
                          variant="indeterminate"
                        />
                      ) : (
                        "Submit"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </form>
          ) : (
            <form onSubmit={handleCreateInvestor}>
              <Stack pt={{ xs: 5, md: 14 }}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: "#04091E",
                    fontFamily: "Poppins, sans-serif",
                    fontSize: { xs: 24, md: 48 },
                    lineHeight: { xs: "24px", md: "48px" },
                  }}
                >
                  Investor Profile Setup
                </Typography>
                <Typography sx={{ color: "#33383D", paddingTop: "5px" }}>
                  We are now going to verify your details with an external
                  agency.  Please note that this is not a credit check, and will
                  not affect your credit history. Please fill in the form for
                  instant verification.
                </Typography>
              </Stack>

              <Stack pt={{ xs: 5 }} pb={{ xs: 5, md: 20 }}>
                <Grid container spacing={{ xs: 2, md: 5 }}>
                  <Grid item xs={12} sx={{ position: "relative" }}>
                    <Stack spacing={1}>
                      <TextField
                        id="outlined-basic"
                        label="Investor Type"
                        variant="outlined"
                        required
                        className="input"
                        defaultValue="person"
                        value={type}
                        onChange={(e) => setType(e.target.value)}
                        sx={style}
                        fullWidth
                        inputProps={{
                          style: { color: "#191B23" },
                        }}
                        autoComplete="off"
                        select
                      >
                        <MenuItem value="person">an Individual</MenuItem>
                        <MenuItem value="company">Company</MenuItem>
                        <MenuItem value="trust">Trust</MenuItem>
                      </TextField>
                    </Stack>
                    <Box
                      src={Dropdown}
                      component={"img"}
                      sx={{
                        position: "absolute",
                        right: "20px",
                        top: { xs: "40px", md: "60px" },
                      }}
                    ></Box>
                  </Grid>

                  <Grid item xs={6}>
                    <Stack spacing={1}>
                      <TextField
                        id="outlined-basic"
                        label="First Name"
                        variant="outlined"
                        required
                        className="input"
                        // placeholder={username.split(" ")[0]}
                        value={investor.first_name}
                        onChange={handleNameChange}
                        sx={style}
                        fullWidth
                        inputProps={{
                          style: { color: "#191B23" },
                        }}
                        autoComplete="off"
                        error={nameError}
                        helperText={
                          nameError
                            ? "Invalid format. Only letters and certain characters (max 25)."
                            : ""
                        }
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={6}>
                    <Stack spacing={1}>
                      <TextField
                        id="outlined-basic"
                        label="Last Name"
                        variant="outlined"
                        required
                        className="input"
                        // placeholder={username.split(" ")[1]}
                        value={investor.last_name}
                        onChange={handleLastNameChange}
                        sx={style}
                        fullWidth
                        inputProps={{
                          style: { color: "#191B23" },
                        }}
                        autoComplete="off"
                        error={lastNameError}
                        helperText={
                          lastNameError
                            ? "Invalid format. Only letters and certain characters (max 25)."
                            : ""
                        }
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={6}>
                    <Stack spacing={1}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          className="date"
                          label="Date of Birth"
                          value={birthday}
                          onChange={handleBirthdayChange}
                        />
                        {birthdayError ? "Wrong birthday." : ""}
                      </LocalizationProvider>

                      {/* <TextField
                        id="outlined-basic"
                        label="Birthday"
                        variant="outlined"
                        required
                        className="input"
                        placeholder="MM/DD/YYYY"
                        value={investor.date_of_birth}
                        onChange={(e) => {
                          let inputValue = e.target.value;
                          inputValue = inputValue.replace(/[^\d]/g, "");
                          if (inputValue.length <= 8) {
                            inputValue = inputValue.replace(
                              /(\d{2})(\d{2})(\d{4})/,
                              "$3-$1-$2"
                            );
                            setInvestor({
                              ...investor,
                              date_of_birth: inputValue,
                            });
                          }
                        }}
                        sx={style}
                        fullWidth
                        inputProps={{
                          style: { color: "#191B23" },
                        }}
                        autoComplete="off"
                      /> */}
                    </Stack>
                  </Grid>

                  <Grid item xs={6} sx={{ position: "relative" }}>
                    <Stack spacing={1}>
                      <TextField
                        id="outlined-basic"
                        label="Citizenship"
                        variant="outlined"
                        required
                        className="input"
                        defaultValue="United States"
                        value={investor.citizenship}
                        onChange={(e) =>
                          setInvestor({
                            ...investor,
                            citizenship: e.target.value,
                          })
                        }
                        sx={style}
                        fullWidth
                        inputProps={{
                          style: { color: "#191B23" },
                        }}
                        autoComplete="off"
                        select
                      >
                        <MenuItem value="United States">United States</MenuItem>
                      </TextField>
                    </Stack>
                    <Box
                      src={Dropdown}
                      component={"img"}
                      sx={{
                        position: "absolute",
                        right: "20px",
                        top: { xs: "40px", md: "60px" },
                      }}
                    ></Box>
                  </Grid>

                  <Grid item xs={6} />
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      size="large"
                      sx={{
                        background: "#EF32D9",
                        color: "white",
                        fontWeight: 600,
                        "&:hover": {
                          background: "#EF32D9",
                        },
                        "&:disabled": {
                          background: "#EF32D9",
                        },
                      }}
                      variant="contained"
                      type="submit"
                      disabled={
                        investor.last_name &&
                        investor.first_name &&
                        investor.date_of_birth &&
                        !nameError &&
                        !lastNameError
                          ? false
                          : true
                      }
                    >
                      Confirm
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </form>
          )}
        </Stack>
      </Container>
    </Box>
  );
};

export default VerifyComponent;
