import { FormEvent, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

import CarImg from "../assets/img/Lexas.png";
import DoneIcon from "../assets/img/icons/check.svg";
import ArrowRightIcon from "../assets/img/invest_arrow.png";

import { useSelector } from "../store";
import APIContext from "../context/ApiContext";
import snackbar from "../config/snackbar";
import { useNavigate } from "react-router-dom";
import axiosServices from "../hooks/axios";
import { OfferDataType } from "../types";
import { Fullscreen } from "@mui/icons-material";

const InvestmentComponent = () => {
  const navigate = useNavigate();
  const { token, investorId, isVerified } = useSelector((state) => state.auth);
  const { createInvestment, loading, setLoading } = useContext(APIContext);
  const isMobile = useMediaQuery("(max-width:600px)");

  const [shares, setShares] = useState<number>(500);
  const [offerData, setOfferData] = useState<OfferDataType>();
  const [showIframe, setShowIframe] = useState<boolean>(false);

  const formattedAmount = (amount: {
    toLocaleString: (arg0: string) => any;
  }) => {
    return amount.toLocaleString("en-US");
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleCreateInvestment = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const req = {
        offering: 148, // your offering ID.
        quantity: shares, // The number of shares to buy.
        investor: investorId, // The investor ID (see /investors/ endpoints).
        from_invoice: true,
        accredited_investor: true,
        net_worth: 0,
        annual_income: 0,
        us_person: true,
        exempt_from_backup_withholding: true,
      };

      const response = await createInvestment(req);

      if (response.data.success) {
        //snackbar("You have invested successfully.");
        setShowIframe(true);
      } else {
        snackbar(response.data.response, "error");
      }

      window.addEventListener("message", (e) => {
        if (
          typeof e.data.target != "undefined" &&
          e.data.target === "issuance"
        ) {
          if (e.data.payload.status === "ready") {
            // @ts-ignore
            const iframe: any = document.querySelector("iframe").contentWindow;
            iframe?.postMessage(
              {
                target: "issuance",
                payload: {
                  investment: response.data.response.id,
                  token: `Token ${token}`,
                  payment_intent_client_secret:
                    response.data.response.payment_intent_client_secret,
                  payment_method_types: "card",
                },
              },
              "*"
            );
          }

          if (e.data.payload.status === "complete") {
            console.log("success!!!");
            //snackbar("You payment successfull.");
            navigate("/profile");
          }
        }
      });
    } catch (error: any) {
      snackbar(error.message, "error");
    }
    setLoading(false);
  };

  const load = async () => {
    try {
      const { data } = await axiosServices.get(`/issuance/offerings/${148}`);
      setOfferData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!isVerified) {
      navigate("/verify");
    } else {
      load();
    }
  }, [isVerified]);

  const style: any = {
    "& .MuiOutlinedInput-input": {
      color: "#191B23",
    },
    "& .MuiInputLabel-root": {
      color: "#191B23",
    },
    "& .MuiPaper-root": {
      backdropFilter: "none !important", // Remove backdrop filter
      backgroundColor: "none !important", // Set background to transparent if needed
      boxShadow: "none !important", // Remove any box shadow if present
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#191B23",
      borderRadius: "10px !important",
      border: "1px solid #191B23 !important",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: "#191B23",
    },
    "&:hover .MuiInputLabel-root": {
      color: "#191B23",
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#191B23",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: "#191B23",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#191B23",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#191B23",
    },
  };

  return (
    <Box>
      {!showIframe ? (
        <Container>
          <Stack
            pt={{ xs: "100px", sm: "160px" }}
            sx={{
              borderBottom: "2px solid #A7AEB4",
              paddingBottom: { xs: "10px", md: "20px" },
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                color: "#04091E",
                fontFamily: "Poppins, sans-serif",
                fontSize: { xs: 24, md: 48 },
                lineHeight: { xs: "24px", md: "48px" },
              }}
            >
              Invest Now
            </Typography>
            <Typography
              sx={{
                color: "#747681",
                fontSize: { xs: 14, md: 18 },
                lineHeight: { xs: "16px", md: "22px" },
              }}
            >
              Select number of shares you wish to purchase.
            </Typography>
          </Stack>

          <Box
            p={{ xs: 1, md: 3 }}
            sx={{ backgroundColor: "#F7F9FD", borderRadius: "8px" }}
            my={5}
          >
            <Typography
              sx={{
                fontWeight: 700,
                color: "#33383D",
                fontFamily: "Inter, sans-serif",
                fontSize: { xs: 16, md: 18 },
                borderBottom: "1px solid #14121a",
                paddingBottom: "10px",
              }}
            >
              Invesment Amount
            </Typography>
            <Stack
              direction="row"
              spacing={{ md: 5 }}
              sx={{ paddingTop: "30px" }}
            >
              <Box
                component="img"
                src={CarImg}
                sx={{
                  display: { xs: "none", md: "flex" },
                  borderRadius: "16px",
                }}
              ></Box>
              <Stack
                sx={{
                  width: "100%",
                  border: "1px solid #14121a",
                  borderRadius: "16px",
                  padding: { xs: "16px", md: "88px 16px 16px" },
                }}
              >
                <Stack spacing={1} justifyContent="end">
                  <Stack direction="row" justifyContent="space-between">
                    <Stack spacing={2}>
                      <Typography
                        sx={{
                          color: "#33383D",
                          margin: "0px",
                          fontSize: "12px",
                        }}
                      >
                        2012 Model White
                      </Typography>
                      <Typography
                        sx={{
                          color: "#242424",
                          fontSize: "24px",
                          fontWeight: 600,
                          lineHeight: "32px",
                          marginTop: "0px !important",
                        }}
                      >
                        Lexus LFA
                      </Typography>
                    </Stack>
                    <Stack spacing={2}>
                      <Typography
                        sx={{
                          color: "#33383D",
                          margin: "0px",
                          fontSize: "12px",
                        }}
                      >
                        Price Per Share
                      </Typography>
                      <Typography
                        sx={{
                          color: "#242424",
                          fontSize: "24px",
                          fontWeight: 600,
                          lineHeight: "32px",
                          marginTop: "0px !important",
                        }}
                      >
                        ${offerData?.share_price} USD
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <form onSubmit={handleCreateInvestment}>
              <Stack sx={{ paddingTop: "30px" }}>
                <Grid container>
                  <Grid item md={12} xs={12} sm={12}>
                    <Stack
                      direction="row"
                      spacing={{ xs: 1, md: 5 }}
                      alignItems="center"
                    >
                      <Stack sx={{ width: "100%" }} spacing={1}>
                        <TextField
                          id="outlined-basic"
                          label="Number of Shares"
                          variant="outlined"
                          sx={style}
                          fullWidth
                          inputProps={{
                            style: { color: "#191B23" },
                          }}
                          autoComplete="off"
                          className="input"
                          placeholder="1000 Shares"
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                <Typography sx={{ color: "#33383D" }}>
                                  Max. 5000
                                </Typography>
                              </InputAdornment>
                            ),
                          }}
                          value={shares}
                          onChange={(e) => setShares(Number(e.target.value))}
                        ></TextField>
                      </Stack>
                      <Box component="img" src={ArrowRightIcon}></Box>
                      <Stack sx={{ width: "100%" }} spacing={1}>
                        <TextField
                          id="outlined-basic"
                          label="Investment Total"
                          variant="outlined"
                          sx={style}
                          fullWidth
                          inputProps={{
                            style: { color: "#191B23" },
                          }}
                          autoComplete="off"
                          className="input"
                          // placeholder="50 Shares"
                          type="text"
                          value={`$ ${formattedAmount(
                            Number(offerData?.share_price) * shares
                          )}`}
                          // onChange={(e) => setShares(Number(e.target.value))}
                        ></TextField>
                      </Stack>
                    </Stack>
                    {/* <Typography
                      variant="caption"
                      sx={{ color: "rgba(255, 255, 255, 0.8)" }}
                    >
                      Enter or select how many shares you would like to
                      purchase.
                    </Typography> */}
                  </Grid>

                  {/* <Grid item md={12} xs={12} sm={12}> */}
                  {/* <Stack spacing={1}>
                    <Typography sx={{ fontWeight: 600, color: "white" }}>
                      Common Investment Amounts
                    </Typography> */}

                  {/* <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={3}
                    > */}
                  {/* <Box
                        py={2}
                        width="100%"
                        textAlign="center"
                        border="1px solid white"
                        sx={{ cursor: "pointer" }}
                        onClick={() => setShares(500)}
                      >
                        <Typography sx={{ color: "white" }}>
                          500 Shares
                        </Typography>
                        <Typography sx={{ color: "white" }}>
                          (${}
                          {(Number(offerData?.share_price) * 50).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                          )
                        </Typography>
                      </Box>
                      <Box
                        py={2}
                        width="100%"
                        textAlign="center"
                        border="1px solid white"
                        sx={{ cursor: "pointer" }}
                        onClick={() => setShares(2000)}
                      >
                        <Typography sx={{ color: "white" }}>
                          2000 Shares
                        </Typography>
                        <Typography sx={{ color: "white" }}>
                          (${}
                          {(
                            Number(offerData?.share_price) * 200
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                          )
                        </Typography>
                      </Box>

                      <Box
                        py={2}
                        width="100%"
                        textAlign="center"
                        border="1px solid white"
                        sx={{ cursor: "pointer" }}
                        onClick={() => setShares(5000)}
                      >
                        <Typography sx={{ color: "white" }}>
                          5000 Shares
                        </Typography>
                        <Typography sx={{ color: "white" }}>
                          (${}
                          {(
                            Number(offerData?.share_price) * 500
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                          )
                        </Typography>
                      </Box> */}
                  {/* </Stack> */}
                  {/* </Stack> */}
                  {/* </Grid> */}
                  <Grid container>
                    <Button
                      fullWidth
                      endIcon={loading ? <></> : <Box component="img"></Box>}
                      sx={{
                        background: "#242424",
                        color: "white",
                        fontWeight: 600,
                        "&:hover": {
                          background: "#EF32D9",
                        },
                        borderRadius: "6px",
                        marginTop: "20px",
                      }}
                      variant="contained"
                      type="submit"
                    >
                      {loading ? (
                        <CircularProgress
                          size={25}
                          color="inherit"
                          variant="indeterminate"
                        />
                      ) : (
                        "Buy Now!"
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            </form>
          </Box>
        </Container>
      ) : (
        <Container maxWidth="xl">
          <Stack
            pt={{ xs: "100px", sm: "160px" }}
            sx={{
              borderBottom: "2px solid #A7AEB4",
              paddingBottom: { xs: "10px", md: "20px" },
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                color: "#04091E",
                fontFamily: "Poppins, sans-serif",
                fontSize: { xs: 24, md: 48 },
                lineHeight: { xs: "24px", md: "48px" },
              }}
            >
              Payment
            </Typography>
            <Typography
              sx={{
                color: "#747681",
                fontSize: { xs: 14, md: 18 },
                lineHeight: { xs: "16px", md: "22px" },
                paddingTop: "5px",
              }}
            >
              Complete your payment and finalize your purchase.
            </Typography>
          </Stack>

          <Stack
            py={5}
            sx={{ width: "100%" }}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <iframe
              style={{
                height: "1100px",
                width: isMobile ? "100%" : "80%",
                border: "none",
                margin: "auto",
              }}
              src="https://demo.issuance.com/invest_thirdparty"
              id="iframe"
              title="iframe"
              allow="payment *"
            />
          </Stack>
        </Container>
      )}
    </Box>
  );
};

export default InvestmentComponent;
