import { Box, Container, Typography } from "@mui/material";
import hero from "../assets/img/privacypolicy_hero.png";
import { useEffect } from "react";

function PrivacyPolicy() {
    useEffect(() => {
     window.scrollTo({ top : 0 , behavior : 'smooth'})
    },[])
  return (
    <Box>
    {/*------------------ Hero section -------------------------*/}
    <Box
    pt={{ xs: '41px' , md: "145px" }}
    pb={{ xs: '71px' , md: "145px" }}
      sx={{
        backgroundImage: `url(${hero})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        borderBottomLeftRadius: "32px",
        borderBottomRightRadius: "32px",
        marginTop: {xs : "65px" , sm: '100px'},
      }}
    >
      <Typography
        sx={{
          fontSize: "12px",
          color: "#4F575E",
          fontWeight: 600,
          fontFamily: "Poppins, sans-serif",
          textAlign: "center",
          lineHeight: "15px",
          backgroundColor: "#fff",
          borderRadius: "36px",
          padding: "4px 12px",
          maxWidth: "200px",
          margin: {xs: "0px auto 21px" , md: "0px auto 36px"},
        }}
      >
        HOW WE USE YOUR DATA
      </Typography>
      <Typography
        sx={{
          fontSize: {xs: "24px" , md: '48px'},
          color: "#fff",
          fontWeight: 600,
          fontFamily: "Poppins, sans-serif",
          textAlign: "center",
          lineHeight: {xs: '30px', md: "56px"},
        }}
      >
        Privacy Policy
      </Typography>
      <Typography
        sx={{
          fontSize:{xs: '12px' , md: "16px"},
          color: "#CBCFD2",
          fontWeight: 400,
          fontFamily: "Poppins, sans-serif",
          textAlign: "center",
          lineHeight: {xs: "18px" , md: '24px'},
          paddingTop: {xs: "14px" , md: '24px'},
          maxWidth: { xs : '320px', md:"580px"},
          margin: "auto",
        }}
      >
        At McQueen Labs, we are committed to protecting the privacy and security of your personal information.
      </Typography>
    </Box>
    <Container maxWidth="xl">
    <Typography
          sx={{
            fontSize: 12,
            color: "#F7F9FD",
            fontWeight: 600,
            lineHeight: "15px",
            fontFamily: "Poppins, sans-serif",
            backgroundColor: "#33383D",
            borderRadius: "36px",
            padding: "4px 0px",
            maxWidth: '150px',
            marginBottom: {xs: "20px" , md: '36px'},
            marginTop: "36px",
            // letterSpacing: "1px",
            textAlign: "center",
          }}
        >
          PRIVACY POLICY
        </Typography>
        <Box pb={{xs: "20px" , md: '48px'}} pt={"1px"}>
          <Typography
            sx={{
              fontSize: {xs: 14 , md: 21},
              color: "#242424",
              fontWeight: 400,
              lineHeight: {xs: '18px', md: "38px"},
              fontFamily: "Poppins, sans-serif",
            }}
          >
            McQueen Labs ("McQueen Labs," "we," "us," or "our") is committed to protecting the privacy and security of your personal information. This Privacy Policy describes how we collect, use, disclose, and protect your information when you use our SEC approved illiquid assets investing platform for cars and artwork ("Platform"). By accessing or using our Platform, you agree to the terms of this Privacy Policy.
          </Typography>
          </Box>
          <Box pb={"48px"} pt={"1px"}>

          <Typography
            sx={{
              fontSize: {xs: 12 , md: 18},
              color: "#464646",
              fontWeight: 400,
              lineHeight: {xs: '18px' , md: '38px'},
              fontFamily: "Poppins, sans-serif",
            }}
          >
            Information We Collect: (A) Personal Information: When you register an account with McQueen Labs, we may collect personal information such as your name, email address, contact information, and payment details. (B) Transaction Information: We collect information about your investment transactions on our platform, including the assets you invest in, the amount invested, and transaction history. (C) Device Information: We may collect information about the device you use to access our platform, including IP address, browser type, and operating system. (D) Usage Information: We may collect information about how you interact with our platform, such as pages visited, features used, and duration of use.
          </Typography>
          <Typography
            sx={{
              fontSize: {xs: 12 , md: 18},
              color: "#464646",
              fontWeight: 400,
              lineHeight: {xs: '18px' , md: '38px'},
              fontFamily: "Poppins, sans-serif",
            }}
          >
            How We Use Your Information: (A) Provide Services: We use your information to provide you with access to our illiquid assets investing platform and facilitate investment transactions. (B) Improve User Experience: We may use your information to analyze usage trends, optimize our platform, and personalize your experience. (C) Communication: We may use your contact information to communicate with you about your account, transactions, updates to our platform, and promotional offers.
          </Typography>
          <Typography
            sx={{
              fontSize: {xs: 12 , md: 18},
              color: "#464646",
              fontWeight: 400,
              lineHeight: {xs: '18px' , md: '38px'},
              fontFamily: "Poppins, sans-serif",
            }}
          >
            Compliance: We may use your information to comply with legal and regulatory requirements, including SEC regulations.
          </Typography>
          <Typography
            sx={{
              fontSize: {xs: 12 , md: 18},
              color: "#464646",
              fontWeight: 400,
              lineHeight: {xs: '18px' , md: '38px'},
              fontFamily: "Poppins, sans-serif",
            }}
          >
            Information Sharing and Disclosure: (A) Service Providers: We may share your information with trusted third-party service providers who assist us in operating our platform, processing transactions, and delivering services. (B) Legal Compliance: We may disclose your information when required by law, subpoena, or other legal process, or to protect our rights, property, or safety.
          </Typography>
          <Typography
            sx={{
              fontSize: {xs: 12 , md: 18},
              color: "#464646",
              fontWeight: 400,
              lineHeight: {xs: '18px' , md: '38px'},
              fontFamily: "Poppins, sans-serif",
            }}
          >
            Data Security: We implement industry-standard security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. Despite our best efforts, no method of transmission over the internet or electronic storage is completely secure. Therefore, we cannot guarantee absolute security of your information.
          </Typography>
          
        </Box>
        </Container>
    </Box>
  );
}

export default PrivacyPolicy;