import { Box, Container } from "@mui/material";
import FeaturedComponent from "../components/FeaturedComponent";
import ProcessComponent from "../components/Home/ProcessComponent";
import CommunityComponent from "../components/Home/CommunityComponent";
import FaqComponent from "../components/Home/FaqComponent";
import NewsletterComponent from "../components/Home/NewsletterComponent";
import ProductComponent from "../components/Home/ProductComponent";


const Home = () => {
  return (
    <Box className="home">
      <FeaturedComponent />
      <ProductComponent/>
      <Container maxWidth="xl"><hr/></Container>
      <ProcessComponent/>
      <NewsletterComponent/>
      <CommunityComponent/>
      <FaqComponent/>

    </Box>
  );
};

export default Home;
