import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  investors: [],
};

const investors = createSlice({
  name: "investors",
  initialState,
  reducers: {
    GetInvestors(state, action) {
      const data = action.payload!;
      state.investors = data;
    },
  },
});

export default investors.reducer;

export const { GetInvestors } = investors.actions;
