import { useContext, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import APIContext from "../context/ApiContext";
import snackbar from "../config/snackbar";
import { useParams, useSearchParams } from "react-router-dom";

const ResetPasswordComponent = ({ handleClickOpen }: any) => {
  const [password, setPassword] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [isPassValid, setIsPassValid] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const { resetPassword } = useContext(APIContext);
  const [param] = useSearchParams();
  const style = {
    "& label.Mui-focused": {
      color: "#232632 !important",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#232632 !important",
    },
    "& .MuiInputLabel-root": {
      color: "#232632 !important",
    },
    "& .MuiOutlinedInput-input": {
      color: "#232632 !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#232632 !important",
        border: "2px solid #232632 !important",
        borderRadius: "10px !important",
      },
      "&:hover fieldset": {
        borderColor: "#232632 !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#232632 !important",
      },
    },
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };

  const onSubmitForm = async (e: any) => {
    e.preventDefault();
    const token = param.get("token");
    if (!token) {
      snackbar("Incorrect reset url.", "error");
      return;
    }

    if (confirmPassword == password && password) {
      const { data } = await resetPassword({ token, password });
      if (data.mail) {
        handleClickOpen();
      } else {
        snackbar("Password reset was faild.", "error");
      }
    } else {
      snackbar("Password no correct.", "error");
    }
  };

  const validatePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const pass: string = event.target.value;
    const hasMinLength = pass.length >= 8;
    const hasUpperCase = /[A-Z]/.test(pass);
    const hasLowerCase = /[a-z]/.test(pass);
    const hasNumber = /\d/.test(pass);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(pass);

    const isPasswordValid =
      hasMinLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber &&
      hasSpecialChar;

    setIsValid(isPasswordValid);
    setPassword(pass);
  };

  const validateConfirmPassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const pass: string = event.target.value;
    // const hasMinLength = pass.length >= 8;
    // const hasUpperCase = /[A-Z]/.test(pass);
    // const hasLowerCase = /[a-z]/.test(pass);
    // const hasNumber = /\d/.test(pass);
    // const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(pass);

    const isPasswordValid =
      // hasMinLength &&
      // hasUpperCase &&
      // hasLowerCase &&
      // hasNumber &&
      // hasSpecialChar &&
      pass == password;

    setIsPassValid(isPasswordValid);
    setConfirmPassword(pass);
  };

  return (
    <Box sx={{ maxWidth: "458px", textAlign: "center", margin: "auto" }}>
      <Box>
        <Typography
          sx={{
            fontSize: { xs: 30, md: 40 },
            color: "#232632",
            fontWeight: 600,
            lineHeight: "48px",
            fontFamily: "Poppins, sans-serif",
            paddingBottom: "16px",
          }}
        >
          Reset Password
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: 12, md: 14 },
            color: "#828282",
            fontWeight: 400,
            lineHeight: "22px",
            fontFamily: "Poppins, sans-serif",
          }}
        >
          Please enter your new password here.
        </Typography>
        <Typography
          sx={{
            fontSize: { xs: 12, md: 14 },
            color: "#828282",
            fontWeight: 400,
            lineHeight: "22px",
            fontFamily: "Poppins, sans-serif",
            paddingBottom: "32px",
          }}
        >
          Must be different than your previous password.
        </Typography>
        <form onSubmit={onSubmitForm}>
          <TextField
            id="outlined-basic"
            label="Password"
            variant="outlined"
            value={password}
            onChange={validatePassword}
            fullWidth
            autoComplete="off"
            sx={{ ...style, marginBottom: "16px" }}
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                    style={{ color: "black" }}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={!isValid}
            helperText={
              !isValid
                ? "Minimum 8 letters, included number, upper and lower case , special letter."
                : ""
            }
          />
          <TextField
            id="outlined-basic"
            label="Confirm Password"
            variant="outlined"
            value={confirmPassword}
            onChange={validateConfirmPassword}
            fullWidth
            sx={{ ...style, marginBottom: "32px" }}
            autoComplete="off"
            type={showConfirmPassword ? "text" : "password"}
            // InputProps={{
            //   endAdornment: (
            //     <InputAdornment position="end">
            //       <IconButton
            //         onClick={handleToggleConfirmPasswordVisibility}
            //         edge="end"
            //         style={{ color: "black" }}
            //       >
            //         {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
            //       </IconButton>
            //     </InputAdornment>
            //   ),
            // }}
            error={!isPassValid}
            helperText={!isPassValid ? "Passwords do not match" : ""}
          />
          <Button
            sx={{
              fontSize: { xs: 15, md: 20 },
              color: "#fff",
              fontWeight: 400,
              lineHeight: { xs: "24px", md: "32px" },
              backgroundColor: "#33383D",
              borderRadius: "6px",
              padding: { xs: "10px 0px", md: "12px 0px" },
              width: "100%",
            }}
            className="accountbutton"
            type="submit"
            disabled={
              isValid && isPassValid && password && confirmPassword
                ? false
                : true
            }
          >
            Confirm
          </Button>
        </form>
      </Box>
    </Box>
  );
};

export default ResetPasswordComponent;
