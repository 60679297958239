import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Home from "./pages/Home";
import Layout from "./layout";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import LogIn from "./pages/LogIn";
import Profile from "./pages/Profile";
import Verify from "./pages/Verify";
import VerifyEmail from "./pages/VerifyEmail";
import Investment from "./pages/Investment";
import Order from "./pages/Order";
import Details from "./pages/Details";
import ResetPassword from "./pages/ResetPassword";
import ResetP from "./pages/ResetP";
import Market from "./pages/Market";
import CardList from "./pages/CardList";
//redux
import { useSelector } from "./store";
import CardListDetail from "./pages/CardListDetail";
import DetailsOfMarket from "./pages/DetailsOfMarket";
import Aboutus from "./pages/Aboutus";
import HowitWork from "./pages/HowitWork";
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";

import TermsofUse from "./pages/TermsofUse";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiePolicy from "./pages/CookiePolicy";
import Disclosure from "./pages/Disclosure";
import Page404 from "./pages/Page404";
import CookieModal from "./components/CookieModal";

const Router = () => {
  const { isLoggedIn }: any = useSelector((state) => state.auth);
  console.log(isLoggedIn, "isLoggedIn");

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <Layout>
              <Home />
              <CookieModal />
            </Layout>
          }
        />
        <Route
          path="/404"
          element={
            <Layout>
              <Page404 />
            </Layout>
          }
        />
        <Route path="*" element={<Navigate to={"/404"}></Navigate>} />
        <Route
          path="/home"
          element={
            <Layout>
              <Home />
              <CookieModal />
            </Layout>
          }
        />
        <Route
          path="/about-us"
          element={
            <Layout>
              <Aboutus />
              <CookieModal />
            </Layout>
          }
        />
        <Route
          path="/how-it-works"
          element={
            <Layout>
              <HowitWork />
              <CookieModal />
            </Layout>
          }
        />
        <Route
          path="/faq"
          element={
            <Layout>
              <Faq />
              <CookieModal />
            </Layout>
          }
        />
        <Route
          path="/termsofuse"
          element={
            <Layout>
              <TermsofUse />
              <CookieModal />
            </Layout>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <Layout>
              <PrivacyPolicy />
              <CookieModal />
            </Layout>
          }
        />
        <Route
          path="/cookie-policy"
          element={
            <Layout>
              <CookiePolicy />
            </Layout>
          }
        />
        <Route
          path="/disclosure"
          element={
            <Layout>
              <Disclosure />
              <CookieModal />
            </Layout>
          }
        />
        <Route
          path="/contact"
          element={
            <Layout>
              <Contact />
              <CookieModal />
            </Layout>
          }
        />
        <Route
          path="/sign-up"
          element={
            isLoggedIn ? (
              <Navigate to="/profile" />
            ) : (
              <Layout>
                <SignUp />
                <CookieModal />
              </Layout>
            )
          }
        />
        <Route
          path="/verifyEmail"
          element={
            isLoggedIn ? (
              <Navigate to="/verifyEmail" />
            ) : (
              <Layout>
                <VerifyEmail />
                <CookieModal />
              </Layout>
            )
          }
        />
        <Route
          path="/sign-in"
          element={
            isLoggedIn ? (
              <Navigate to="/profile" />
            ) : (
              <Layout>
                <LogIn />
                {/* <SignIn /> */}
                <CookieModal />
              </Layout>
            )
          }
        />

        <Route
          path="/profile"
          element={
            isLoggedIn ? (
              <Layout>
                <Profile />
                <CookieModal />
              </Layout>
            ) : (
              <Navigate to="/sign-in" />
            )
          }
        />
        <Route
          path="/details"
          element={
            isLoggedIn ? (
              <Layout>
                <DetailsOfMarket />
                <CookieModal />
              </Layout>
            ) : (
              <Navigate to="/sign-in" />
            )
          }
        />
        <Route
          path="/resetpassword/:id"
          element={
            <Layout>
              <ResetPassword />
              <CookieModal />
            </Layout>
          }
        />
        {/* <Route
          path="/resetp"
          element={
            isLoggedIn ? (
              <Navigate to="/resetp" />
            ) : (
              <Layout>
                <ResetP />
              <CookieModal />
             
                </Layout>
            )
          }
        /> */}
        <Route
          path="/verify"
          element={
            !isLoggedIn ? (
              <Navigate to="/sign-in" />
            ) : (
              <Layout>
                <Verify />
                <CookieModal />
              </Layout>
            )
          }
        />
        <Route
          path="/investment"
          element={
            !isLoggedIn ? (
              <Navigate to="/sign-in" />
            ) : (
              <Layout>
                <SignUp />
                <CookieModal />
              </Layout>
            )
          }
        />

        <Route
          path="/invest"
          element={
            !isLoggedIn ? (
              <Navigate to="/sign-in" />
            ) : (
              <Layout>
                <Investment />
                <CookieModal />
              </Layout>
            )
          }
        />

        <Route
          path="/order"
          element={
            !isLoggedIn ? (
              <Navigate to="/sign-in" />
            ) : (
              <Layout>
                <Order />
                <CookieModal />
              </Layout>
            )
          }
        />
        <Route
          path="/market"
          element={
            <Layout>
              <CardList />
              <CookieModal />
            </Layout>
          }
        />

        <Route
          path="/carddetail"
          element={
            <Layout>
              <CardListDetail />
              <CookieModal />
            </Layout>
          }
        />

        <Route
          path="/details"
          element={
            !isLoggedIn ? (
              <Navigate to="/sign-in" />
            ) : (
              <Layout>
                <Details />
                <CookieModal />
              </Layout>
            )
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
