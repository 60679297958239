import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import rightarrow from "../../assets/img/Home/rightarrow.svg";
import newletter from "../../assets/img/Home/newsletter.jpg";
import mobile_newletter from "../../assets/img/Home/mobile_newletter.png";
import { useNavigate} from "react-router-dom";


  

const NewsletterComponent = () => {
  const navigate = useNavigate();

  const style = {
    '& label.Mui-focused': {
      color: 'white !important',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white !important',
    },
    '& .MuiInputLabel-root': {
      color: 'white !important',
    },
    '& .MuiOutlinedInput-input': {
      color: 'white !important',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white !important',
        border : '2px solid #fff !important',
        borderRadius : '10px !important'
      },
      '&:hover fieldset': {
        borderColor: 'white !important',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white !important',
      },
    },
  };
  return (
    <Box pb={{xs: "51px" , md: '100px'}}>
      <Box sx={{display : 'flex' , alignItems : 'stretch'}}>
        <Box
          sx={{
            backgroundColor: "#242424",
            backgroundImage: {xs: `url(${mobile_newletter})` , md: 'none'},
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            flex: '1',
            width : {xs: '100%' , md: '50%'},
            display : 'flex',
            alignItems : 'center',
            paddingTop: {xs: "80px" , md: '90px'},
            paddingBottom: {xs: "80px" , md: '90px'}
          }}
          className="Newletter_left_Section"
        >
          <Box
            sx={{
              margin: "auto",
              textAlign: "center",
              maxWidth: 500,
            }}
            className='newsletter_section'
          >
            <img src={rightarrow} alt="arrow"></img>
            <Typography
              sx={{
                fontSize: {xs: 18, md: 30},
                color: "#fff",
                fontWeight: 600,
                lineHeight: {xs: '24px' , md: "38px"},
                fontFamily: "Poppins, sans-serif",
                paddingTop: "24px",
                paddingBottom: { xs: "40px" , md: '60px'},
              }}
            >
              Subscribe to our newsletter to receive exclusive access to our
              pre-launch & stay up to date with our progress
            </Typography>
             <Button  sx={{
                fontSize : '12px',
                color: "#fff",
                fontWeight: 400,
                lineHeight: '18px',
                fontFamily: "Poppins, sans-serif",
                border : '1px solid #fff',
                borderRadius : '5px',
                padding : '5px 52px',
                display : {xs: 'block' , md: 'none'},
                margin : 'auto'
              }} onClick={() => navigate('/sign-up')}>Sign up</Button>
            <Box component="form" autoComplete="off" sx={{ display : {xs: 'none' , md: 'block'}}}>
              <Grid container>
                <Grid md={12}>
                  <TextField
                    id="outlined-basic"
                    label="Name"
                    variant="outlined"
                    sx={style}
                    required
                    className="inputfiled"
                    autoComplete="off"
                  />
                </Grid>
                <Grid md={12} sx={{ marginTop: "12px", marginBottom: "32px" }}>
                  <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    sx={style}
                    required
                    className="inputfiled"
                    autoComplete="off"
                  />
                </Grid>
                <Grid md={12}>
                 <Button sx={{   fontSize: 16,
                color: "#242424",
                fontWeight: 500,
                lineHeight: "24px",
                fontFamily: "Poppins, sans-serif",
                backgroundColor : '#fff',
                minWidth : '360px',
                padding : '9px 0px',
                border : '2px solid #0ae0dd',
                borderRadius : '6px !important' 
                }} className="submitbutton" type="submit">
                 Submit
                 </Button>
                 <Typography  sx={{
                fontSize: 10,
                color: "#a7aeb4",
                fontWeight: 400,
                lineHeight: "24px",
                fontFamily: "Poppins, sans-serif",
                paddingTop: "12px",
              }}>
                 We care about your data, please view our  <span style={{ color : '#0adcda' , cursor : 'pointer'}} onClick={() => navigate('/privacy-policy')} > privacy policy</span>
                 
                 </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>

        <Box
    sx={{
      flex: '1',
      position: 'relative',
      overflow: 'hidden',
      display: {xs: 'none' , md: 'flex'},
      alignItems: 'stretch'
    }}
  >
    <img
      src={newletter}
      style={{ width: "100%", height: "100%", position: "absolute", flex: '1' }}
      alt="letter"
    ></img>
  </Box>
      </Box>
    </Box>
  );
};

export default NewsletterComponent;
