import React from 'react'
import { Box, Button, Grid, Typography } from "@mui/material";
import card_detail_heart from "../../assets/img/cardlistdetail/card_detail_heart.svg"


const CardDetailComponant = () => {
    return (
        <Box>
            <Typography
                sx={{
                    fontSize: "9px",
                    color: "#6B767F",
                    fontWeight: 400,
                    lineHeight: "9px",
                    fontFamily: "Poppins, sans-serif",
                }}
            >
                COLLECTABLE SUPER CAR
            </Typography>
            <Typography
                sx={{
                    fontSize: "40px",
                    color: "#191B23",
                    fontWeight: 600,
                    lineHeight: "45px",
                    fontFamily: "Poppins, sans-serif",
                    marginTop: "12px",
                }}
            >
                Mercedes SLS AMG Black Series 2014 Model
            </Typography>
            <Typography
                sx={{
                    fontSize: "18px",
                    color: "#191B23",
                    fontWeight: 400,
                    lineHeight: "28px",
                    fontFamily: "Poppins, sans-serif",
                    marginTop: "16px",
                }}
            >
                Manufactured: 2013 - 2015
            </Typography>
            <Typography
                sx={{
                    fontSize: "12px",
                    color: "#191B23",
                    fontWeight: 400,
                    lineHeight: "17px",
                    fontFamily: "Poppins, sans-serif",
                    marginTop: "24px",
                }}
            >
                The 2014 Mercedes SLS AMG Black Series embodies the pinnacle of performance and luxury. With its aggressive stance, carbon fiber accents, and widened body, it exudes athleticism and exclusivity. Under the hood lies a handcrafted 6.2-liter V8 engine, producing a breathtaking 622 horsepower, delivering blistering acceleration and a spine-tingling exhaust note.... read more
            </Typography>
            <Box  sx={{display:"flex", gap:"8px", marginTop: "36px" }}>
              
                    <Button
                        style={{
                            backgroundColor: "#ef32d9",
                            borderRadius: "21px",
                            width: "100%",
                            padding: "12px 0px",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 14,
                                color: "#fff",
                                fontWeight: 400,
                                lineHeight: "14px",
                                fontFamily: "Poppins, sans-serif",
                            }}
                        >
                            Invest Now
                        </Typography>
                    </Button>
                    <Button
                        style={{
                            border: "1px solid #242424",
                            borderRadius: "21px",
                            width: "100%",
                            padding: "12px 0px",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 14,
                                color: "#242424",
                                fontWeight: 400,
                                lineHeight: "14px",
                                fontFamily: "Poppins, sans-serif",
                            }}
                        >
                            Learn More
                        </Typography>
                    </Button>
                
                    <img src={card_detail_heart} alt='heart' />
               
            </Box>
            <Box
                sx={{
                    // backgroundColor: "#242424",
                    borderRadius: "10px",
                    padding: "29px 9px 9px",
                    marginTop: "23px",
                    border: "1px solid #242424",
                }}
            >
                <Typography
                    sx={{
                        fontSize: "14px",
                        color: "#242424",
                        fontWeight: 600,
                        lineHeight: "14px",
                        fontFamily: "Poppins, sans-serif",
                    }}
                >
                    $2.34
                </Typography>
                <Typography
                    sx={{
                        fontSize: "8px",
                        color: "#242424",
                        fontWeight: 600,
                        fontFamily: "Inter, sans-serif",
                    }}
                >
                    Price per Share{" "}
                </Typography>
            </Box>
            <Grid container  spacing={"6px"} sx={{ marginTop: "4px" }}>
                <Grid item xs={2}>
                    <Box
                        sx={{
                            // backgroundColor: "#242424",
                            borderRadius: "10px",
                            padding: "29px 9px 9px",
                            border: "1px solid #242424",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "14px",
                                color: "#242424",
                                fontWeight: 600,
                                lineHeight: "14px",
                                fontFamily: "Poppins, sans-serif",
                            }}
                        >
                            $2.34
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "8px",
                                color: "#242424",
                                fontWeight: 600,
                                fontFamily: "Inter, sans-serif",
                            }}
                        >
                            Price per Share{" "}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box
                        sx={{
                            // backgroundColor: "#242424",
                            borderRadius: "10px",
                            padding: "29px 9px 9px",
                            border: "1px solid #242424",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "14px",
                                color: "#242424",
                                fontWeight: 600,
                                lineHeight: "14px",
                                fontFamily: "Poppins, sans-serif",
                            }}
                        >
                            $2.34
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "8px",
                                color: "#242424",
                                fontWeight: 600,
                                fontFamily: "Inter, sans-serif",
                            }}
                        >
                            Price per Share{" "}
                        </Typography>
                    </Box>
                </Grid>
                <Grid  item xs={8}>
                    <Box
                        sx={{
                            // backgroundColor: "#242424",
                            borderRadius: "10px",
                            padding: "29px 9px 9px",
                            border: "1px solid #242424",
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: "14px",
                                color: "#242424",
                                fontWeight: 600,
                                lineHeight: "14px",
                                fontFamily: "Poppins, sans-serif",
                            }}
                        >
                            $2.34
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "8px",
                                color: "#242424",
                                fontWeight: 600,
                                fontFamily: "Inter, sans-serif",
                            }}
                        >
                            Price per Share{" "}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default CardDetailComponant
