import React from "react";
import Router from "./Router";

//redux
import { store } from "./store";
import { Provider } from "react-redux";

//import css
import "./assets/styles/index.css";
import Snackbar from "./components/Snackbar";
import { APIProvider } from "./context/ApiContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Provider store={store}>
        <GoogleOAuthProvider clientId="518904429977-9qapfutn980ps291sgnc466u9g61kn9s.apps.googleusercontent.com">
          <APIProvider>
            <>
              <Snackbar />
              <Router />
            </>
          </APIProvider>
        </GoogleOAuthProvider>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
