import { Box, Button, Typography } from "@mui/material";
import hero from "../assets/img/img404.png";
import arrow404 from "../assets/img/arrow404.svg";
import { useNavigate } from "react-router-dom";

function Page404() {
  const navigate = useNavigate();
  return (
    <Box
      pt={{ xs: "41px", md: "145px" }}
      pb={{ xs: "71px", md: "145px" }}
      sx={{
        backgroundImage: `url(${hero})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        marginTop: { xs: "65px", sm: "100px" },
        marginBottom: "-30px",
      }}
    >
      <Typography
        sx={{
          fontSize: "12px",
          color: "#4F575E",
          fontWeight: 600,
          fontFamily: "Poppins, sans-serif",
          textAlign: "center",
          lineHeight: "15px",
          backgroundColor: "#fff",
          borderRadius: "36px",
          padding: "4px 12px",
          maxWidth: "120px",
          margin: { xs: "0px auto 21px", md: "0px auto 36px" },
        }}
      >
        Whoops!
      </Typography>
      <Typography
        sx={{
          fontSize: { xs: "24px", md: "72px" },
          color: "#fff",
          fontWeight: 600,
          fontFamily: "Poppins, sans-serif",
          textAlign: "center",
          lineHeight: { xs: "30px", md: "90px" },
        }}
      >
        404 Error
      </Typography>
      <Typography
        sx={{
          fontSize: { xs: "12px", md: "19px" },
          color: "#CBCFD2",
          fontWeight: 400,
          fontFamily: "Poppins, sans-serif",
          textAlign: "center",
          lineHeight: { xs: "18px", md: "28px" },
          paddingTop: { xs: "14px", md: "24px" },
          maxWidth: { xs: "320px", md: "678px" },
          margin: "auto",
        }}
      >
        The page you were looking for may have been moved, deleted, or doesn't
        exist. But don't worry, we'll help you get back on track.
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: { xs: "30px", md: "80px" },
        }}
      >
        <Button
          sx={{
            fontSize: "14px",
            color: "#fff",
            fontWeight: 400,
            fontFamily: "Poppins, sans-serif",
            lineHeight: "20px",
            padding: { xs: "10px 20px", md: "12px 32px" },
            border: "1px solid #fff",
            borderRadius: "12px",
            display: "flex",
            alignItems: " center",
            justifyContent: "center",
          }}
          onClick={() => navigate("/")}
        >
          <img src={arrow404} alt="arrow" style={{ marginRight: "16px" }}></img>
          Homepage
        </Button>
      </Box>
    </Box>
  );
}

export default Page404;
